import Rx from 'rxjs'

import buildAction from '../../helpers/buildAction'
import { ARTICLES_BEFORE_END_TO_AUTOLOAD, MAX_AUTOLOADED_ARTICLES } from '../../opoint/articles/index'
import { getActiveArticleIndex, getListLength } from '../../selectors/articlesSelectors'
import { getArticleListingStyle, getLoadMore } from '../../selectors/settingsSelectors'
import { isSearchInProgress } from '../../selectors/searchSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import { LISTING_STYLES } from '../../opoint/settings'
import { history } from '../../store'

const isMobileContext = () => history.location.pathname.startsWith('/mobile/')

const autoloadMoreEpic = (action$: any, { getState }: any) =>
  action$
    .filter(({ type }) => type === ActionTypes.SET_ACTIVE_ARTICLE || type === ActionTypes.NEXT_ACTIVE_ARTICLE)
    .switchMap(() => {
      const state = getState()
      const isMobile = isMobileContext()
      const loadMoreEnabled = getLoadMore(state)
      const articleCount = getListLength(state) as number
      const activeArticle = getActiveArticleIndex(state) as number
      const searchInProgress = isSearchInProgress(state)

      if (
        !isMobile && // if we're viewing the mobile view
        loadMoreEnabled && // and if loading is enabled
        // and if we're close to the end of the listing
        articleCount - activeArticle < ARTICLES_BEFORE_END_TO_AUTOLOAD &&
        !state.search.loadMoreSearchInProgress && // and we're not already fetching more
        articleCount < MAX_AUTOLOADED_ARTICLES && // and we have not loaded too much
        !searchInProgress // and we don't have search still in progress
      ) {
        return Rx.Observable.of(buildAction(ActionTypes.FETCH_MORE_ARTICLES))
      }
      return Rx.Observable.of()
    })

const scrollToTop = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SCROLL_TO_TOP).switchMap(() => {
    const state = getState()
    const articleListingStyle = getArticleListingStyle(state)
    const articlePreviewsRef = document.getElementById('article-previews')
    if (articlePreviewsRef) {
      articlePreviewsRef.scrollTop = 0
    }
    if (articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT || articleListingStyle === LISTING_STYLES.ARCHIVE_RIGHT) {
      return Rx.Observable.of()
    }
    const isMobile = isMobileContext()
    if (!isMobile) {
      return Rx.Observable.of(buildAction(ActionTypes.SET_ACTIVE_ARTICLE, { index: 0, source: 'scroll' }))
    } else {
      return Rx.Observable.of(buildAction(ActionTypes.SET_ACTIVE_ARTICLE, { index: null, source: 'scroll' }))
    }
  })

const hideBadBrowserPopup = (action$: any) =>
  action$
    .ofType(ActionTypes.SHOW_BAD_BROWSER_POPUP)
    .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.HIDE_BAD_BROWSER_POPUP)).delay(5000))

export default [autoloadMoreEpic, scrollToTop, hideBadBrowserPopup]
