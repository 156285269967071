import { ROUTER_LOCATION_CHANGE } from '../constants/actionTypes'
import { searchContextLogic } from '../new-components/hooks/useSearchContext'
import { history } from '../store'

/**
 * Converts URL query string (search) into object
 * @param search The query string to convert, typically found in `window.location.search`. E.g. `?some=value&other=thing`
 * @returns An object with the keys and values of the query string
 */
export function getSearchObj(search: string): { [key: string]: string } {
  return Object.fromEntries(new URLSearchParams(search))
}

/**
 * Converts the current URL query string (search) into object
 * @param search The query string to convert, typically found in `window.location.search`. E.g. `?some=value&other=thing`
 * @returns An object with the keys and values of the query string
 */
export function getCurrentSearchObj(): { [key: string]: string } {
  return Object.fromEntries(new URLSearchParams(history.location.search))
}

/**
 * @deprecated Use `useSearchContext` hook instead where possible
 */
export const searchContext = () => searchContextLogic(history.location.pathname)

export const getAlertId = () => {
  const data = history.location.pathname.split('/')
  const type = data[1]
  const id = data[2]
  return type === 'alerts' ? parseInt(id, 10) : undefined
}

let prevLocation = { pathname: '', search: '' }
/**
 * Holds previous location for use outside of redux
 * Note shoud be avoided
 * @returns Previous location
 */
export const getPrevLocation = () => prevLocation

/**
 * Listens to location changes and dispatches an action
 * @param store The redux store
 */
export const listen = (store) => {
  history.listen((nextLocation) => {
    console.log('History listener')
    console.log(`Location prev: ${prevLocation?.pathname}`)
    console.log(`Location next: ${nextLocation.pathname}`)

    store.dispatch({
      type: ROUTER_LOCATION_CHANGE,
      payload: { location: nextLocation, previousLocation: prevLocation },
    })

    prevLocation = nextLocation
  })
}
