import secretServerTime from '../auth/secret'

type Params = {
  headers: {
    accept: string
    'accept-language': string
    'content-type': string
    authorization?: string
  }
  responseType: 'json'
}

export const isDev = process.env.REACT_APP_API === 'development' || process.env.NODE_ENV === 'development'

export const LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT = 20

export const jwtAuthToken = () => window.localStorage.getItem('ls.OP-PORTAL-AUTH-TOKEN')

const urls = {
  api: { dev: 'https://api-dev.opoint.com', prod: 'https://api.opoint.com' },
  app: { dev: 'https://m360-beta.opoint.com', prod: 'https://m360.opoint.com' },
}

export const api = (path: string) =>
  !!process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}${path}`
    : `${isDev ? urls.api.dev : urls.api.prod}${path}`

export const app = (path: string) =>
  !!process.env.REACT_APP_APP_URL
    ? `${process.env.REACT_APP_APP_URL}${path}`
    : `${isDev ? urls.app.dev : urls.app.prod}${path}`

export const imageproxy = (path: string) =>
  !!process.env.REACT_APP_APP_URL
    ? `${process.env.REACT_APP_APP_URL}/imageproxy${path}`
    : `${isDev ? urls.app.dev : urls.app.prod}/imageproxy${path}`

const calculateSecret = (timestamp?: number) => {
  if (!timestamp) {
    return ''
  }
  const timeslot = Math.floor(timestamp / 300)
  const t = (((timeslot % 16147561) * (13 * timeslot + 31)) % 16147561) % 16147561
  /* eslint-disable-next-line no-bitwise */
  const secret = btoa(
    `${String.fromCharCode((t >> 16) % 256)}${String.fromCharCode((t >> 8) % 256)}${String.fromCharCode(t % 256)}`,
  )

  return secret
}

export const getRequestHeaders = (requireAuth: boolean = true) => {
  const params: Params = {
    headers: {
      accept: 'application/json',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
    },
    responseType: 'json',
  }
  if (requireAuth) {
    // @ts-ignore
    const secret = calculateSecret(secretServerTime.getTime())
    params.headers.authorization = `JWT ${jwtAuthToken()}${secret}`
  }

  return params
}

const config = {
  jwtAuthToken,
  request: {
    getRequestHeaders,
  },
  url: {
    api,
    imageproxy,
    app,
  },
}

export default config
