// @ts-nocheck
import Rx from 'rxjs'
import R from 'ramda'

import buildAction from '../helpers/buildAction'
import { getArticleTagById, getSelectedArticles } from '../selectors/articlesSelectors'
import { getGlobalTrashTag, getTrashTagById } from '../selectors/tagsSelectors'
import { getSelectedProfilesIds } from '../selectors/searchSelectors'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import {
  addTag,
  deleteTag,
  editTag,
  getDecrementedWeight,
  getIncrementedWeight,
  getTags,
  tagArticles,
  untagArticles,
  tagSingleArticle,
  untagSingleArticle,
  updateTagsOrder,
  isEveryArticleInGroupUntagged,
  isTheWholeGroupUntagged,
} from '../opoint/tags'
import { deleteArticles, getTrashTags, undeleteArticles } from '../opoint/tags/trash'
import * as ActionTypes from '../constants/actionTypes'

export const fetchEpic = (action$) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).switchMap(() =>
    Rx.Observable.from(getTags())
      .map((data) => buildAction(ActionTypes.TAGS_FETCH_SUCCESS, data))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.TAGS_FETCH_FAILURE))),
  )

export const addEpic = ($action) =>
  $action.ofType(ActionTypes.ADD_TAG).switchMap(({ payload: { tag } }) =>
    Rx.Observable.fromPromise(addTag(tag))
      .map((data) => buildAction(ActionTypes.ADD_TAG_SUCCESS, { tag: data }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ADD_TAG_FAILURE))),
  )

export const editEpic = ($action) =>
  $action.ofType(ActionTypes.EDIT_TAG).switchMap(({ payload: { tag, hasStoredSearchPermission } }) => {
    // If user don't have the rights to store search, edit tag without stored_search
    if (!hasStoredSearchPermission) {
      return Rx.Observable.fromPromise(editTag(R.omit(['stored_search'], tag)))
        .map((data) => buildAction(ActionTypes.EDIT_TAG_SUCCESS, { tag: data }))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.EDIT_TAG_FAILURE)))
    }

    // If user has permission to store search, save correct status according to backend
    // eslint-disable-next-line no-nested-ternary
    const storedSearch =
      tag.stored_search === null
        ? // If they didn't setup stored_search yet, don't do it either
          tag.stored_search
          ? { active: -1 }
          : null
        : // -1 to activate it, 0 to disable it
          { active: tag.stored_search ? -1 : 0 }

    const newTag = {
      ...tag,
      stored_search: storedSearch,
    }

    return Rx.Observable.fromPromise(editTag(newTag))
      .map((data) => buildAction(ActionTypes.EDIT_TAG_SUCCESS, { tag: data }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.EDIT_TAG_FAILURE)))
  })

export const deleteEpic = ($action) =>
  $action.ofType(ActionTypes.DELETE_TAG).switchMap(({ payload }) =>
    Rx.Observable.fromPromise(deleteTag(payload.tagId))
      .mergeMap(() =>
        Rx.Observable.of(
          buildAction(ActionTypes.DELETE_TAG_SUCCESS, { tagId: payload.tagId }),
          buildAction(ActionTypes.GO_TO_DEFAULT_PROFILE),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) =>
        Rx.Observable.of(buildAction(ActionTypes.DELETE_TAG_FAILURE, JSON.parse(e.originalEvent.target.response))),
      ),
  )

export const toggleEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.TOGGLE_TAG_ARTICLE).mergeMap(({ payload: { articles, tag } }) => {
    // Fixes some weird cornercase when no article is passed to this epic
    if (!articles) {
      return Rx.Observable.empty()
    }

    const articlesArray = Array.isArray(articles) ? articles : [articles]
    const articleTag = getArticleTagById(tag, articlesArray[0])(getState())

    const action = articleTag
      ? buildAction(ActionTypes.UNTAG_ARTICLES, { articles: articlesArray, tag })
      : buildAction(ActionTypes.TAG_ARTICLES, { articles: articlesArray, tag })

    return Rx.Observable.of(action)
  })

export const toggleIdenticalArticleEpic = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.TOGGLE_TAG_IDENTICAL_ARTICLE)
    .mergeMap(({ payload: { article, tag, originalArticle } }) => {
      // Fixes some weird cornercase when no article is passed to this epic
      if (!article) {
        return Rx.Observable.empty()
      }

      const isTagged = !!article.tags[tag.id]
      const action = isTagged
        ? buildAction(ActionTypes.UNTAG_SINGLE_ARTICLE, { article, tag, originalArticle })
        : buildAction(ActionTypes.TAG_SINGLE_ARTICLE, { article, tag, originalArticle })

      return Rx.Observable.of(action)
    })

export const tagEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.TAG_ARTICLES).mergeMap(({ payload: { articles, tag, weight } }) => {
    const articlesArray = Array.isArray(articles) ? articles : [articles]
    return Rx.Observable.from(tagArticles(articlesArray, tag, weight))
      .switchMap(() => Rx.Observable.of())
      .retry(3)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.TAG_ARTICLES_FAILURE)))
  })

export const untagEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.UNTAG_ARTICLES).mergeMap(({ payload, payload: { articles, tag } }) => {
    const articlesArray = Array.isArray(articles) ? articles : [articles]
    return Rx.Observable.from(untagArticles(articlesArray, tag))
      .switchMap(() => Rx.Observable.of())
      .retry(3)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.UNTAG_ARTICLES_FAILURE)))
  })

export const tagSingleArticleEpic = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.TAG_SINGLE_ARTICLE)
    .mergeMap(({ payload, payload: { article, tag, weight, originalArticle } }) => {
      let probablyTagGroup$ = Rx.Observable.of()
      if (isTheWholeGroupUntagged(originalArticle, tag)) {
        probablyTagGroup$ = Rx.Observable.of(
          buildAction(ActionTypes.TAG_ARTICLES, {
            articles: [
              {
                ...originalArticle,
                identical_documents: {
                  cnt: 1,
                  document: [article],
                },
              },
            ],
            tag,
            toTagOnlyMainArticle: true,
          }),
        )
      }

      const tagSingleArticle$ = Rx.Observable.from(tagSingleArticle(article, tag, weight))
        .switchMap(() => Rx.Observable.of())
        .retry(3)
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.TAG_ARTICLES_FAILURE)))

      return Rx.Observable.merge(tagSingleArticle$, probablyTagGroup$)
    })

export const untagSingleArticleEpic = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.UNTAG_SINGLE_ARTICLE)
    .mergeMap(({ payload, payload: { article, tag, originalArticle } }) => {
      if (isEveryArticleInGroupUntagged(originalArticle.identical_documents.document, tag, article)) {
        return Rx.Observable.of(
          buildAction(ActionTypes.UNTAG_ARTICLES, {
            articles: [originalArticle],
            tag,
          }),
        )
      }

      return Rx.Observable.from(untagSingleArticle(article, tag))
        .switchMap(() => Rx.Observable.of())
        .retry(3)
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.UNTAG_ARTICLES_FAILURE)))
    })

export const incrementEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.INCREMENT_TAG_WEIGHT_FOR_ARTICLE).map(({ payload: { articles, tag } }) => {
    // refactor naming to articles
    const articlesArray = Array.isArray(articles) ? articles : [articles]

    const articleTag = getArticleTagById(tag, articlesArray[0])(getState()) || {}
    const weight = getIncrementedWeight(articleTag, tag)

    return buildAction(ActionTypes.TAG_ARTICLES, {
      articles: articlesArray,
      tag,
      weight,
    })
  })

export const decrementEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.DECREMENT_TAG_WEIGHT_FOR_ARTICLE).map(({ payload: { articles, tag } }) => {
    const articlesArray = Array.isArray(articles) ? articles : [articles]

    const articleTag = getArticleTagById(tag, articlesArray[0])(getState()) || {}
    const weight = getDecrementedWeight(articleTag, tag)

    return buildAction(ActionTypes.TAG_ARTICLES, {
      articles: articlesArray,
      tag,
      weight,
    })
  })

export const incrementSingleArticleEpic = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.INCREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE)
    .map(({ payload: { article, tag, originalArticle } }) => {
      const articleTag = article.tags[tag.id]
      const weight = getIncrementedWeight(articleTag, tag)

      return buildAction(ActionTypes.TAG_SINGLE_ARTICLE, {
        article,
        tag,
        weight,
        originalArticle,
      })
    })

export const decrementSingleArticleEpic = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.DECREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE)
    .map(({ payload: { article, tag, originalArticle } }) => {
      const articleTag = article.tags[tag.id]
      const weight = getDecrementedWeight(articleTag, tag)

      return buildAction(ActionTypes.TAG_SINGLE_ARTICLE, {
        article,
        tag,
        weight,
        originalArticle,
      })
    })

export const fetchTrashEpic = (action$) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).switchMap(({ payload }) =>
    Rx.Observable.from(getTrashTags())
      .map((data) => buildAction(ActionTypes.TRASH_FETCH_SUCCESS, { trashTags: data }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of()),
  )

export const toggleTrashTagEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.TOGGLE_DELETE_ARTICLES).map(({ payload: { article, tag } }) => {
    const articleTag = getArticleTagById(tag, article)(getState())
    return articleTag
      ? buildAction(ActionTypes.UNDELETE_ARTICLES, { articles: article, tag })
      : buildAction(ActionTypes.DELETE_ARTICLES, { articles: article, tag })
  })

export const deleteArticlesEpic = (action$) =>
  action$.ofType(ActionTypes.DELETE_ARTICLES).switchMap(({ payload: { articles, tag } }) => {
    const articlesArray = Array.isArray(articles) ? articles : [articles]

    return Rx.Observable.from(deleteArticles(articlesArray, tag))
      .switchMap(() => Rx.Observable.of())
      .retry(3)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.DELETE_ARTICLES_FAILURE)))
  })

export const undeleteArticlesEpic = (action$) =>
  action$.ofType(ActionTypes.UNDELETE_ARTICLES).switchMap(({ payload: { articles, tag } }) => {
    const articlesArray = Array.isArray(articles) ? articles : [articles]

    return Rx.Observable.from(undeleteArticles(articlesArray, tag))
      .switchMap(() => Rx.Observable.of())
      .retry(3)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.UNDELETE_ARTICLES_FAILURE)))
  })

export const deleteArticleGloballyToolbarEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_ARTICLES_GLOBALLY_TOOLBAR).switchMap(() => {
    const payload = {
      articles: getSelectedArticles(getState()),
      tag: getGlobalTrashTag(getState()),
    }

    return Rx.Observable.concat(
      Rx.Observable.of(buildAction(ActionTypes.DELETE_ARTICLES, payload)),
      Rx.Observable.of(buildAction(ActionTypes.UNCHECK_ALL_ARTICLES)),
    )
  })

export const deleteArticleProfilesToolbarEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_ARTICLES_PROFILES_TOOLBAR).switchMap(() => {
    const trashTags = getSelectedProfilesIds(getState()).map((id) => getTrashTagById(id)(getState()))
    const articles = getSelectedArticles(getState())

    const deleteObservables$ = trashTags.map((tag) =>
      Rx.Observable.of(buildAction(ActionTypes.DELETE_ARTICLES, { articles, tag })),
    )

    return Rx.Observable.concat(...deleteObservables$, Rx.Observable.of(buildAction(ActionTypes.UNCHECK_ALL_ARTICLES)))
  })

export const reorderTagsEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.TAGS_REORDER).switchMap(({ payload }) => {
    const { id, newIndex } = payload
    return Rx.Observable.from(updateTagsOrder(newIndex, id))
      .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.TAGS_REORDER_SUCCESS)))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR)))
  })
export const removeTagsFromArticlesEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REMOVE_TAGS_FROM_ARTICLES).switchMap(({ payload }) => {
    const { tags } = payload
    const articles = getSelectedArticles(getState())

    const untagArticles = tags.map((tag) => buildAction(ActionTypes.UNTAG_ARTICLES, { articles, tag }))
    return Rx.Observable.of(...untagArticles, buildAction(ActionTypes.UNCHECK_ALL_ARTICLES))
  })

export default [
  addEpic,
  decrementEpic,
  decrementSingleArticleEpic,
  deleteArticleGloballyToolbarEpic,
  deleteArticleProfilesToolbarEpic,
  deleteArticlesEpic,
  deleteEpic,
  editEpic,
  fetchEpic,
  fetchTrashEpic,
  incrementEpic,
  incrementSingleArticleEpic,
  removeTagsFromArticlesEpic,
  reorderTagsEpic,
  tagEpic,
  tagSingleArticleEpic,
  toggleEpic,
  toggleIdenticalArticleEpic,
  toggleTrashTagEpic,
  undeleteArticlesEpic,
  untagEpic,
  untagSingleArticleEpic,
]
