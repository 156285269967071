// @ts-nocheck

import R from 'ramda'

import { articleId } from '../opoint/articles/index'
import { processAlertTags } from '../opoint/alerts'
import { toDisplayTimeFormat } from '../opoint/alerts/apiTime'
import * as Actions from '../constants/actionTypes'
import type { Alert, AlertDetailed, NextAlert, AlertRecipients, DayType } from '../opoint/flow'

type AlertsState = {
  details: Array<AlertDetailed>
  editedAlert: AlertDetailed
  // TODO: Proper flowtype AlertHistory @honza
  history: Array<Object>
  // TODO: Proper flowtype AlertHistory @honza
  historyItems: Array<Object>
  list: Array<Alert>
  next: { [key: number]: NextAlert }
  requestPending: boolean
  alertDeleteInProgress: boolean
  alertsRecipients: Array<AlertRecipients>
  currentHistoryItem: Object
}

export const initialState: AlertsState = {
  details: {},
  editedAlert: null,
  history: {},
  historyLoading: false,
  historyItems: {},
  list: [],
  next: {},
  nextPreview: {},
  requestPending: false,
  loading: false,
  articlesList: [],
  trashedArticles: {},
  tags: [],
  deleteInProgress: false,
  alertsRecipients: [],
  currentHistoryItem: null,
}

const alertsReducer = (state: AlertsState = initialState, { type, payload }): AlertsState => {
  switch (type) {
    case Actions.ALERTS_FETCH_SUCCESS: {
      const alertList: Array<Alert> = payload

      return R.evolve({
        list: R.always(R.reverse(alertList)),
        tags: R.always(processAlertTags(R.filter((a) => a.editable, alertList))),
      })(state)
    }

    case Actions.ALERTS_FETCH_RECIPIENTS_SUCCESS: {
      const { alertsRecipients } = payload

      return R.evolve({
        alertsRecipients: R.always(alertsRecipients),
      })(state)
    }

    case Actions.ALERT_FETCH: {
      return R.assoc('requestPending', true, state)
    }

    case Actions.ALERT_FETCH_SUCCESS: {
      const { alert, toBeEdited } = payload
      // alert: Alert
      const displayFormatTimeConf = toDisplayTimeFormat(alert.schedule.timeConfiguration)
      // TODO: Make this more concise @honza
      return R.compose(
        (s) =>
          toBeEdited
            ? R.assocPath(
                ['editedAlert', 'schedule'],
                {
                  ...alert.schedule,
                  timeConfiguration: displayFormatTimeConf,
                },
                s,
              )
            : s,
        R.assocPath(['details', alert.id], alert),
      )(state)
    }

    case Actions.ALERT_FETCH_NEXT_SUCCESS: {
      const { id, nextAlert } = payload
      // id: number, nextAlert: NextAlert

      return R.assocPath(['next', id], nextAlert, state)
    }

    case Actions.ALERT_TEMPLATE_FETCH_SUCCESS: {
      const { preview } = payload
      return R.assoc('template', preview, state)
    }

    case Actions.ALERT_CLEAR_TEMPLATE: {
      return R.dissoc('template', state)
    }

    case Actions.ALERT_FETCH_NEXT_PREVIEW_SUCCESS: {
      const { id, data, inProgress } = payload
      // id: number, data: Object

      return R.compose(R.assocPath(['nextPreview', id], data), R.assoc('requestPending', inProgress))(state)
    }

    case Actions.ALERT_PREVIEW_CLEAR: {
      const { id } = payload
      return R.dissocPath(['nextPreview', `${id}`], state)
    }

    case Actions.ALERT_FETCH_HISTORY_SUCCESS: {
      const { id, data } = payload
      // id: number, data: Object

      return R.assocPath(['history', id], data, state)
    }
    case Actions.ALERT_FETCH_MORE_HISTORY: {
      return R.assoc('historyLoading', true, state)
    }

    case Actions.ALERT_FETCH_MORE_HISTORY_SUCCESS: {
      const { id, data } = payload
      // id: number, data: Object

      return R.compose(
        R.assocPath(['history', id], {
          ...state.history[id],
          next: data.next,
          results: [...state.history[id].results, ...data.results],
        }),
        R.assoc('historyLoading', false),
      )(state)
    }

    case Actions.ALERT_FETCH_HISTORY_ITEM: {
      return R.assoc('requestPending', true, state)
    }

    case Actions.GO_TO_ALERT: {
      return R.assoc('currentHistoryItem', null)(state)
    }

    case Actions.ALERT_FETCH_HISTORY_ITEM_SUCCESS: {
      const { alertId, historyId, historyTimestamp, data } = payload
      // alertId: number, historyId: string, historyTimestamp: string, data: Object

      return R.compose(
        R.assoc('requestPending', false),
        R.assoc('currentHistoryItem', { alertId, historyId, historyTimestamp }),
        R.assocPath(['historyItems', alertId, historyId, historyTimestamp], data),
      )(state)
    }

    case Actions.ALERT_FETCH_HISTORY_ITEM_FAILURE: {
      const { alertId, historyId, historyTimestamp, data } = payload
      // alertId: number, historyId: string, historyTimestamp: string, data: number

      return R.assocPath(['historyItems', alertId, historyId, historyTimestamp], data, state)
    }

    case Actions.INIT_SCHEDULE_ENTRY: {
      const scheduleEntry: DayType = payload

      return R.assocPath(['editedAlert', 'schedule', 'timeConfiguration', scheduleEntry], [], state)
    }

    case Actions.DELETE_SCHEDULE_ENTRY: {
      const scheduleDeletion: DayType = payload

      return R.dissocPath(['editedAlert', 'schedule', 'timeConfiguration', scheduleDeletion], state)
    }

    case Actions.SET_SCHEDULE_ENTRY: {
      const intervals: Object = payload

      return R.compose(
        R.assocPath(['editedAlert', 'schedule', 'timeConfiguration', intervals.day], intervals.intervalData),
      )(state)
    }

    case Actions.UPDATE_SCHEDULE_INTERVAL: {
      const newIntervals: Object = payload

      return R.assocPath(
        ['editedAlert', 'schedule', 'timeConfiguration', newIntervals.day],
        newIntervals.intervalData,
        state,
      )
    }

    case Actions.INIT_FORM: {
      const searchLine = payload
      return !state.wasInitialized
        ? R.compose(
            R.assoc('wasInitialized', true),
            R.assocPath(['editedAlert', 'schedule', 'timeConfiguration'], {}),
            R.assoc('editedAlert', {}),
            R.assoc(
              'activeAlert',
              R.compose(R.assoc('searchterm', ''), R.merge({ mifluzIdLists: searchLine || [] }))({}),
            ),
          )(state)
        : state
    }

    case Actions.CLEAR_FORM:
      return R.compose(R.assoc('editedAlert', null), R.assoc('wasInitialized', false))(state)

    case Actions.ALERT_ADD_FILTER:
      return R.evolve({
        activeAlert: {
          mifluzIdLists: R.append(payload.id),
          searchterm: R.always(''),
        },
      })(state)

    case Actions.ALERT_TOGGLE_FILTER: {
      const toggleOff = R.contains(payload.id, R.path(['activeAlert', 'mifluzIdLists'], state))

      return R.evolve({
        activeAlert: {
          mifluzIdLists: toggleOff ? R.without([payload.id]) : R.append(payload.id),
          searchterm: toggleOff ? /* eslint-disable-next-line no-underscore-dangle */ R.identity(R.__) : R.always(''),
        },
      })(state)
    }

    case Actions.ALERT_REMOVE_FILTER: {
      const alert = payload

      return R.evolve({
        activeAlert: { mifluzIdLists: R.without([alert.id]) },
      })(state)
    }

    case Actions.ALERT_SEARCHTERM_CHANGED: {
      const searchterm = payload

      return R.assocPath(['activeAlert', 'searchterm'], searchterm)(state)
    }

    case Actions.ALERT_CLEAR_ALL:
      return R.evolve({
        activeAlert: {
          mifluzIdLists: R.always([]),
          searchterm: R.always(''),
        },
      })(state)

    case Actions.ALERT_REMOVE_ACTIVE:
      return R.assoc('activeAlert', null, state)

    case Actions.ALERT_INIT_MIFLUZ:
      return R.compose(
        R.assocPath(['activeAlert', 'searchterm'], ''),
        R.assocPath(['activeAlert', 'mifluzIdLists'], payload),
      )(state)

    // Reducers for removal of articles from alerts
    case Actions.ALERT_TRASH_ARTICLE: {
      const { article } = payload
      return R.assocPath(['trashedArticles', articleId(article)], article, state)
    }

    case Actions.ALERT_UNTRASH_ARTICLE: {
      const { article } = payload
      return R.dissocPath(['trashedArticles', `${articleId(article)}`], state)
    }

    case Actions.CLEAR_TRASHED_ARTICLES: {
      return R.assoc('trashedArticles', {}, state)
    }

    case Actions.ALERT_FETCH_ARTICLES: {
      return R.assoc('loading', true, state)
    }

    case Actions.ALERT_DESTROY_ARTICLES: {
      return R.assoc('articlesList', [], state)
    }

    case Actions.ALERT_FETCH_ARTICLES_SUCCESS: {
      const { articles } = payload
      return R.evolve({
        loading: R.always(false),
        /* eslint-disable-next-line no-underscore-dangle */
        articlesList: R.concat(R.__, articles),
      })(state)
    }

    case Actions.ALERT_FETCH_ARTICLES_FAILURE: {
      return R.assoc('loading', false, state)
    }

    case Actions.ALERT_TAG_EXPAND: {
      const id = payload
      const idx = R.findIndex(R.propEq('id', id), state.tags)

      return R.over(R.lensPath(['tags', idx, 'expanded']), R.not, state)
    }

    case Actions.DELETE_ALERT:
      return R.assoc('deleteInProgress', true, state)

    case Actions.ALERT_DELETE_SUCCESS:
    case Actions.ALERT_DELETE_FAILURE:
    case Actions.ALERT_DELETE_CANCEL:
      return R.assoc('deleteInProgress', false, state)

    case Actions.ASK_SAVE_EDITED_ALERT_OPEN: {
      const { action } = payload
      return R.assoc('pendingAction', action, state)
    }

    case Actions.ASK_SAVE_EDITED_ALERT_CLOSE:
      return R.dissoc('pendingAction', state)

    case Actions.SAVE_ALERT_SUCCESS: {
      const {
        alert: { id },
      } = payload
      return R.assoc('justSavedAlertId', id, state)
    }

    default:
      return state
  }
}

export default alertsReducer
