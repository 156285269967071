// @ts-nocheck
import React, { Suspense, lazy } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import Modal from 'react-modal'

import UseTranslationProvider from './new-components/hooks/useTranslation'
import config from './opoint/common/config'
// import * as registerServiceWorker from './registerServiceWorker'
import i18next from './i18nextInit'
import * as ActionTypes from './constants/actionTypes'
import buildAction from './helpers/buildAction'
import Toasts from './new-components/common/Toasts'
import { decodeToken, getServerTime, setServerTime } from './opoint/auth/index'
import { checkM360Version } from './opoint/version/index'
import requireAuthentication from './new-components/auth/RequireAuthentication'
import store, { history } from './store'
import './new-components/old-styles.css'
import './new-components/new-styles.scss'
import './ga'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/nn'
import 'dayjs/locale/nb'
import 'dayjs/locale/da'
import 'dayjs/locale/sv'
import 'dayjs/locale/et'
import 'dayjs/locale/fi'
import ErrorBoundary from './new-components/common/ErrorBoundary'
import { ReactComponent as SvgIcons } from './new-components/common/Icon/sprites/symbol-defs.svg'
import { listen } from './helpers/location'

// Pages
const Login = lazy(() => import('./new-components/auth/Login'))
const App = lazy(() => import('./pages/app/App'))
const ArticleView = lazy(() => import('./pages/article/ArticleView'))
const MobileView = lazy(() => import('./pages/mobile/MobileView'))

Modal.setAppElement('#root')

listen(store)

store.dispatch(buildAction(ActionTypes.USERS_LOGIN_INFO))

async function initialGetServertime() {
  const serverTime = await getServerTime()
  await setServerTime(serverTime)
  store.dispatch(buildAction(ActionTypes.GET_SERVER_TIME))
}

const oReq = new XMLHttpRequest()
oReq.addEventListener('load', async () => {
  await initialGetServertime()

  let response
  try {
    response = JSON.parse(this.responseText)
  } catch (e) {
    response = { isoCode: 'en-GB' }
  }
  store.dispatch(buildAction(ActionTypes.COUNTRY_CODE_FETCH_SUCCESS, response))

  bootstrapApp()
})
oReq.addEventListener('error', getServerTimeAndBootstrap)
oReq.addEventListener('timeout', getServerTimeAndBootstrap)
oReq.open('GET', config.url.api('/users/location/?format=json'))
oReq.timeout = 400
oReq.send()

async function getServerTimeAndBootstrap() {
  await initialGetServertime()
  bootstrapApp()
}

window.addEventListener('storage', (event) => {
  const { key } = event

  if (key === 'ls.OP-PORTAL-AUTH-TOKEN') {
    const { newValue, oldValue } = event
    let newUser = null
    let oldUser = null

    try {
      newUser = newValue === null ? null : decodeToken(newValue)
    } catch (ex) {
      console.log(ex)
    }
    try {
      oldUser = oldValue === null ? null : decodeToken(oldValue)
    } catch (ex) {
      console.log(ex)
    }

    // when changing this, please test IE11 properly
    store.dispatch(buildAction(ActionTypes.USER_TOKEN_CHANGED, { newUser, oldUser }))
  }
})

const NoMatchView = () => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href = '/'
    }
  }, [])
  return null
}

function bootstrapApp() {
  checkM360Version(() => store.dispatch(buildAction(ActionTypes.SHOW_NEW_VERSION_NOTIFICATION)))

  // Check IE - should be removed
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') !== -1 // IE 10 or older
  const trident = ua.indexOf('Trident/') !== -1 //IE 11

  if (msie || trident) {
    const now = new Date().getTime()

    if (parseInt(window.localStorage.IE_LAST_CHECK ?? 0) + 86400 < now) {
      store.dispatch(buildAction(ActionTypes.SHOW_IE_WARNING))
      window.localStorage.IE_LAST_CHECK = now
    }
  }

  render(
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <UseTranslationProvider>
          <React.StrictMode>
            <ErrorBoundary reloadButton={true} errorImg={true}>
              <SvgIcons />
              <Router history={history}>
                <Suspense fallback={null}>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/article" component={requireAuthentication(ArticleView)} />
                    <Route exact path="/mobile" component={requireAuthentication(MobileView)} />
                    <Route path="/" component={requireAuthentication(App)} />
                    <Route path="*" component={NoMatchView} />
                  </Switch>
                </Suspense>
              </Router>
            </ErrorBoundary>
          </React.StrictMode>
        </UseTranslationProvider>
      </I18nextProvider>
    </Provider>,
    document.getElementById('root'),
  )

  store.dispatch(buildAction(ActionTypes.BOOTSTRAP))

  render(<Toasts />, document.getElementById('alert'))
}

// registerServiceWorker.register()
