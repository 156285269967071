import { createSelector } from 'reselect'

import { getUI as getState } from './rootSelectors'

export const getFiltersPanelOpen = createSelector(getState, (ui) => ui.filtersPanelOpen)

export const getEntityFilter = createSelector(getState, (uiState) => uiState.entityFilter)

export const getFilterDetailModalOpen = createSelector(getState, (ui) => ui.filterDetailModalOpen)

export const isEditorModalOpen = createSelector(getState, (ui) => ui.isEditorModalOpen)

export const isEditorFormModalOpen = createSelector(getState, (ui) => ui.isEditorFormModalOpen)

export const isShareArticleModalOpen = createSelector(getState, (ui) => ui.shareArticleModalOpen)

export const getClickedArticle = createSelector(getState, (ui) => ui.clickedArticle)

export const isSortableModuleModalOpen = createSelector(getState, (ui) => ui.sortableModuleModalOpen)

export const isReportModalOpen = (state) => state.ui.reportsModalOpen // Chech if we can improve this interesting one @dmytro

export const getUserLoggedOut = createSelector(getState, (uiState) => uiState.userLoggedOut)

export const isAlertPreviewOpen = createSelector(getState, (ui) => ui.alertPreviewOpen)

export const getSaveAsProfilePopperHide = createSelector(getState, (ui) => ui.saveAsProfilePopperHide)

export const isHelpModalOpen = createSelector(getState, (ui) => ui.helpModalOpen)

export const isDatepickerModalOpen = createSelector(getState, (ui) => ui.datepickerModalOpen)

export const isLeaveEditAlertModalOpen = createSelector(getState, (ui) => ui.leaveEditAlertModalOpen)

export const isEditArticleModalOpen = createSelector(getState, (ui) => ui.editArticleModalOpen)

export const isAlertRemoveArticleOpen = createSelector(getState, (ui) => ui.alertRemoveArticleOpen)

export const isNewVersionNotificationShown = createSelector(getState, (ui) => ui.newVersionNotification)

export const isIEWarningOpen = createSelector(getState, (ui) => ui.isIEWarningOpen)

export const getDeleteProfileConfirmationModalOpen = createSelector(getState, (ui) => ui.deleteProfileConfirmationOpen)

export const getAlertChangeRecipientsOpen = createSelector(getState, (ui) => ui.alertChangeRecipientsOpen)

export const getManageProfilesModalOpen = createSelector(getState, (ui) => ui.manageProfilesModalOpen)

export const getDeleteProfilesModeEnabled = createSelector(getState, (ui) => ui.deleteProfilesModeEnabled)

export const getReleaseNotesModalOpen = createSelector(getState, (ui) => ui.releaseNotesModalOpen)

export const getInfoModalOpen = createSelector(getState, (ui) => ui.infoModalOpen)

export const getInfoCalcModalOpen = createSelector(getState, (ui) => ui.infoCalcModalOpen)

export const getSupportIssueModalOpen = createSelector(getState, (ui) => ui.supportIssueModalOpen)

export const getSupportRequestModalOpen = createSelector(getState, (ui) => ui.supportRequestModalOpen)

export const getIsStatisticsSidebarOpen = createSelector(getState, (ui) => ui.statisticsSidebar.isOpen)

export const getAddArticleModalOpen = createSelector(getState, (ui) => ui.addArticleModalOpen)

export const getStatisticsSidebarContextType = createSelector(getState, (ui) => ui.statisticsSidebar.contextType)

export const getStatisticsSidebarChartType = createSelector(getState, (ui) => ui.statisticsSidebar.chartType)

export const getStatisticsSidebarGraph = createSelector(getState, (ui) => ui.statisticsSidebar.graph)

export const getStatisticsSidebarMenuList = createSelector(getState, (ui) => ui.statisticsSidebar.menuList)

export const getStatisticsSidebarCount = createSelector(getState, (ui) => ui.statisticsSidebar.count)

export const getStatisticsFilteredPills = createSelector(getState, (ui) => ui.filteredPills)

export const getCorrectStatsArticleNumbers = createSelector(getState, (ui) => ui.correctStatsArticleNumbers)

export const getManageProfilesGroupNumber = createSelector(getState, (ui) => ui.manageProfilesGroupNumber)

export const getStatsInfoModalOpen = createSelector(getState, (ui) => ui.statsInfoModalOpen)
