import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { UnregisterCallback } from 'history'

import Modal from '../../common/Modal'
import { history } from '../../../store'
import { useTranslation } from '../../hooks/useTranslation'

type Props = {
  dismiss: () => void
  accept: () => void
  message?: string
}

const UserConfirmationModal = (props: Props) => {
  const { i18n } = useTranslation()

  return (
    <Modal
      accept={{ action: props.accept, text: i18n.t('Leave') }}
      dismiss={{ action: props.dismiss, text: i18n.t('Stay on page') }}
      isOpen
      onHide={props.dismiss}
      title={i18n.t('Leave this page?')}
      type="dialog"
    >
      <span>{!!props.message ? props.message : i18n.t('Do you want to leave without saving your changes')}</span>
    </Modal>
  )
}

export default function getUserConfirmation(message, callback) {
  const modal = document.createElement('div')
  document.body.appendChild(modal)

  const withCleanup = (answer: boolean) => {
    ReactDOM.unmountComponentAtNode(modal)
    document.body.removeChild(modal)
    callback(answer)
  }

  ReactDOM.render(
    <UserConfirmationModal dismiss={() => withCleanup(false)} accept={() => withCleanup(true)} message={message} />,
    modal,
  )
}

export function useBlockingConfirmation(blockNavigation: boolean, message: string = '') {
  const unblock = useRef<UnregisterCallback>()

  useEffect(() => {
    if (blockNavigation) {
      unblock.current = history.block(message)
    }

    return () => {
      unblock.current?.()
    }
  }, [blockNavigation])
}
