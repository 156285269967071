import R from 'ramda'

import * as Actions from '../constants/actionTypes'
import { FilterType } from '../opoint/flow'

export const DEFAULT_FILTERS_ORDER: Array<FilterType> = [
  'timeperiod',
  'geo',
  'lang',
  'media',
  'content',
  'site',
  'rank',
  'cov',
  'profiles',
  'tags',
  'ent',
]

export const initialState = {
  list: {
    APP_DEFAULT_HOME: {},
    APP_UI_SETTINGS: {
      SIDEBAR_SHOWN: true,
      PROFILES_CATEGORY: true,
      ALERTS_CATEGORY: true,
      FILTERS_ORDER: DEFAULT_FILTERS_ORDER.join(','),
      TRASH_TAGS_VISIBLE: [],
      AUTO_EXPAND_PROFILE_EDITOR_SEARCH_LINES: false,
    },
    TRANSLATE_TYPE: 0,
    TRANSLATE_CFG: '-',
    TRANSLATE_TO: 'en',
    MAX_GT_ARTICLE_LENGTH: 2000,
    IDENTICAL: 0,
    ARTICLE_AGE_LIMIT: {},
  },
  choices: {},
  settingsFetched: false,
  isoCode: null, // fetch from the backend
}

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.SETTINGS_FETCH_SUCCESS:
      return R.evolve(
        {
          /* eslint-disable-next-line no-underscore-dangle */
          list: R.merge(
            R.__,
            R.reduce(
              (obj, { name, value }) => {
                switch (name) {
                  case 'APP_UI_SETTINGS':
                    return { ...obj, [name]: R.merge(state.list.APP_UI_SETTINGS, value) }
                  case 'TRANSLATE_CFG':
                    return { ...obj, [name]: value || '-' } // "" -> "-" (translate from all by default)
                  case 'TRANSLATE_TO':
                    return { ...obj, [name]: value || 'en' } // "" -> "en" (translate to english by default)
                  default:
                    return { ...obj, [name]: value }
                }
              },
              {},
              payload,
            ),
          ),
          settingsFetched: R.always(true),
          choices: R.always(
            payload.reduce((acc, setting) => {
              if (setting.choices && setting.choices.length) {
                acc[setting.name] = setting.choices
                return acc
              }
              return acc
            }, {}),
          ),
        },
        state,
      )

    case Actions.SETTINGS_SAVE_SUCCESS: {
      const { settings } = payload
      return R.evolve(
        {
          list: R.merge(
            /* eslint-disable-next-line no-underscore-dangle */
            R.__,
            R.reduce((obj, { name, value }) => ({ ...obj, [name]: value }), {}, settings),
          ),
        },
        state,
      )
    }

    case Actions.TOGGLE_UI_SETTING: {
      const { setting, hasReachedThreshold } = payload
      if (hasReachedThreshold && /_CATEGORY$/.test(setting)) {
        const appSettings = JSON.parse(JSON.stringify(state.list.APP_UI_SETTINGS))
        const setToFalseAllCategoriesExceptOne = (val, key) =>
          /_CATEGORY$/.test(key) ? (setting == key ? !val : false) : val
        return R.assocPath(
          ['list', 'APP_UI_SETTINGS'],
          R.mapObjIndexed(setToFalseAllCategoriesExceptOne, appSettings),
        )(state)
      }
      return R.assocPath(['list', 'APP_UI_SETTINGS', setting], !state.list.APP_UI_SETTINGS[setting])(state)
    }

    case Actions.COLLAPSE_ALL_CATEGORIES:
      const appSettings = JSON.parse(JSON.stringify(state.list.APP_UI_SETTINGS))
      const setToFalseAllCategories = (val, key, obj) => (/_CATEGORY$/.test(key) ? false : val)

      return R.assocPath(['list', 'APP_UI_SETTINGS'], R.mapObjIndexed(setToFalseAllCategories, appSettings))(state)

    case Actions.SET_GLOBAL_TAG_VISIBILITY:
      return R.assocPath(['list', 'APP_UI_SETTINGS', 'HIDE_ALL_TAGS'], payload)(state)

    case Actions.TRASH_TOGGLE_VISIBILITY: {
      const { trashId } = payload
      const lens = R.lensPath(['list', 'APP_UI_SETTINGS', 'TRASH_TAGS_VISIBLE'])

      return R.over(lens, R.ifElse(R.contains(trashId), R.without([trashId]), R.append(trashId)))(state)
    }

    case Actions.COUNTRY_CODE_FETCH_SUCCESS: {
      const { isoCode } = payload
      return R.assoc('isoCode', isoCode, state)
    }

    case Actions.FILTERS_REORDER: {
      const { newOrder } = payload
      return R.assocPath(['list', 'APP_UI_SETTINGS', 'FILTERS_ORDER'], newOrder.join(), state)
    }

    default:
      return state
  }
}

export default settingsReducer
