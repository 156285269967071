import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { isAuthenticated, loginRoute } from '../../../opoint/auth/index'
import * as ActionTypes from '../../../constants/actionTypes'
import buildAction from '../../../helpers/buildAction'
import { isMobileDevice } from '../../helpers/ui'
import { getUser } from '../../../selectors/authSelectors'
import { getSettingsFetched } from '../../../selectors/settingsSelectors'
import { getSearchObj } from '../../../helpers/location'

export default function requireAuthentication(ComposedComponent) {
  const AuthenticatedComponent = (props: any) => {
    useEffect(() => {
      checkAuth()
    })

    const checkAuth = () => {
      const {
        dispatch,
        location: { search },
      } = props

      const { h, t, id, profile } = getSearchObj(search)

      if (h) {
        dispatch(buildAction(ActionTypes.LOG_IN_WITH_TOKEN, { token: h }))
        return
      }

      if (t) {
        dispatch(buildAction(ActionTypes.LOG_IN_WITH_AUTH_TOKEN, { token: t, id, profile }))
        return
      }

      if (!isAuthenticated()) {
        props.history.push(loginRoute(`${props.location.pathname}${search}`))
      }
    }

    const { user, settingsFetched, location, dispatch } = props

    if (!user || !settingsFetched) {
      return null
    }

    const isMobilePath = location.pathname.startsWith('/mobile')

    if (
      (isMobilePath && location.search.length === 0) ||
      (!isMobilePath && document.documentElement.clientWidth <= 1100 && isMobileDevice())
    ) {
      dispatch({ type: ActionTypes.GO_TO_DEFAULT_PROFILE_MOBILE })
    } else if (location.pathname === '/') {
      dispatch({ type: ActionTypes.GO_TO_DEFAULT_PROFILE })
    } else if (location.pathname.startsWith('/frontpages')) {
      // TODO
      // no-op
    } else {
      dispatch({ type: ActionTypes.SEARCH_DATA_INIT })
    }

    return <ComposedComponent {...props} />
  }

  return connect((state) => ({
    user: getUser(state),
    settingsFetched: getSettingsFetched(state),
  }))(AuthenticatedComponent)
}
