import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

let useTranslationContext
let { Provider } = (useTranslationContext = React.createContext(null))

export const useTranslation = (): { i18n: any } => {
  const context: { i18n: any } = React.useContext(useTranslationContext)

  if (!context) {
    // Workaround for Storybook
    return {
      i18n: {
        t: (text: string) => text,
      },
    }
  }

  return context
}

const UseTranslationProvider = ({ children }, context) => {
  const { i18n } = context
  return <Provider value={{ i18n }}>{children}</Provider>
}

UseTranslationProvider.contextTypes = { i18n: PropTypes.object }

export default translate()(UseTranslationProvider)
