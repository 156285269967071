import Rx from 'rx-dom'
import R from 'ramda'

import config from '../common/config'
import type { SearchItem } from '../flow'
import type { Article } from '../../new-components/types/article'
import { handleErrors } from '../common'

export const REPORT_STATUS = {
  ERROR: -2,
  IN_PROGRESS: -1,
  FINISHED: 1,
}

export const REPORT_STEP_SELECT_CONTENT = 0
export const REPORT_STEP_SET_TEMPLATE = 1
export const REPORT_STEP_SORT_CONTENT = 2
export const REPORT_STEP_CREATE_REPORT = 3
export const REPORT_STEP_DOWNLOAD_SHARE = 4

export const reportsActionStep = {
  ACTION_SELECTION: 1,
  SHARE_ACTION: 2,
  SHARING_REPORT: 3,
  SHARE_REPORT_SUCCESS: 4,
  SHARE_REPORT_FAILURE: 5,
}

export const groupedArticlesLength = (groupedArticles: { [key: string]: Article[] }) =>
  // @ts-ignore
  R.compose(R.sum, R.values, R.map(R.length))(groupedArticles)

export const takeGroupedArticles = (groupedArticles: { [key: string]: Array<Article> }, number: number): any => {
  let count = number

  // @ts-ignore
  const selectedGroupedArticles = R.map((articleGroup) => {
    const selectedArticles = R.take(count, articleGroup)
    count -= selectedArticles.length
    return selectedArticles
  }, groupedArticles)

  return R.reject(R.isEmpty, selectedGroupedArticles)
}

export function getReportsTagHistory(id: number) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${id}/reports/`),
    method: 'GET',
  })

  return Rx.DOM.ajax(requestHeaders).toPromise()
}

export function createReport(params: {
  articles?: Array<{ id_site: number; id_article: number }>
  expressions?: Array<SearchItem>
  newest?: number
  oldest?: number
  sortedArticles?: Array<{ id_site: number; id_article: number }>
  templateId: number
  timezone: string
  title: string
  locale: string
  excludearticles: Array<{ id_site: number; id_article: number }>
}) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/reports/'),
    method: 'POST',
    body: JSON.stringify({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...params }),
  })

  requestHeaders.headers['accept-language'] = params.locale

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const shareReport = (params: {
  id?: Array<number>
  message?: string
  recipients?: string
  shareAttachment?: boolean
}): Promise<Object> => {
  const { headers } = config.request.getRequestHeaders()

  const requestHeaders = R.merge(
    { headers },
    {
      url: config.url.api('/reports/share/'),
      method: 'POST',
      body: JSON.stringify({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...params }),
    },
  )
  return Rx.DOM.ajax(requestHeaders).toPromise()
}

export const deleteReportNotification = (params: { id?: Array<number> }): Promise<Object> =>
  fetch(config.url.api(`/notifications/${params.id}/`), {
    ...config.request.getRequestHeaders(),
    method: 'DELETE',
  }).then(handleErrors)

export const deleteReport = (params: { id?: Array<number> }): Promise<Object> =>
  fetch(config.url.api(`/reports/${params.id}/`), {
    ...config.request.getRequestHeaders(),
    method: 'DELETE',
  }).then(handleErrors)
