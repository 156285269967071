import * as R from 'ramda'

import * as ActionTypes from '../constants/actionTypes'
import type { WatchQuery, Action } from '../opoint/flow'

type WatchIndexState = {
  searchWatchId?: number | null
  fetchArticlesWithWatchIdInProgress: boolean
  watchQueries: { [watchId: string]: WatchQuery }
  profilesToWatchIds: { [key: number]: string }
}

export const initialState: WatchIndexState = {
  searchWatchId: null,
  fetchArticlesWithWatchIdInProgress: false,
  watchQueries: {},
  profilesToWatchIds: {},
}

/**
 * This reducer contains logic of watch indexes. Watch index system is a system that controls
 * counts of new articles coming from a server after a search was sent.
 */
const watchIndexReducer = (state: WatchIndexState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionTypes.FETCH_ARTICLES: {
      return R.assoc('searchWatchId', null, state)
    }
    case ActionTypes.UPDATE_CURRENT_WATCH_ID: {
      const { watchId } = payload
      return R.assoc('searchWatchId', watchId, state)
    }
    case ActionTypes.UPDATE_WATCH_INDEXES_MAPPING: {
      const { watchId, profileId } = payload
      return R.assocPath(['profilesToWatchIds', profileId], watchId, state)
    }

    case ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID: {
      return R.assoc('fetchArticlesWithWatchIdInProgress', true, state)
    }

    case ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID_FAILURE: {
      return R.assoc('fetchArticlesWithWatchIdInProgress', false, state)
    }

    case ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID_CANCEL: {
      const { watchId } = payload
      return R.evolve({
        fetchArticlesWithWatchIdInProgress: R.always(false),
        watchQueries: {
          [watchId]: {
            cnt: R.always(0),
          },
        },
      })(state)
    }

    case ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS: {
      const {
        searchresult: { watch_id },
      } = payload
      return R.evolve({
        fetchArticlesWithWatchIdInProgress: R.always(false),
        watchQueries: {
          [watch_id]: {
            cnt: R.always(0),
          },
        },
      })(state)
    }

    case ActionTypes.UPDATE_WATCH_INDEXES_SUCCESS: {
      const { watchQueries } = payload
      const watchQueriesIndexed = R.indexBy(R.prop('id'), watchQueries)
      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        watchQueries: R.merge(R.__, watchQueriesIndexed),
      })(state)
    }

    case ActionTypes.RESET_WATCH_INDEX_FOR_PROFILE: {
      const { watchId } = payload
      return R.evolve({
        watchQueries: {
          [watchId]: R.always({}),
        },
      })(state)
    }

    default:
      return state
  }
}

export default watchIndexReducer
