//@ts-nocheck
import F from 'ramda/src/F'
import always from 'ramda/src/always'
import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import compose from 'ramda/src/compose'
import curry from 'ramda/src/curry'
import dissoc from 'ramda/src/dissoc'
import evolve from 'ramda/src/evolve'
import filter from 'ramda/src/filter'
import ifElse from 'ramda/src/ifElse'
import indexBy from 'ramda/src/indexBy'
import mergeAll from 'ramda/src/mergeAll'
import or from 'ramda/src/or'
import prop from 'ramda/src/prop'
import unless from 'ramda/src/unless'
import dayjs from 'dayjs'

import * as Actions from '../constants/actionTypes'
import {
  addOneOfKind,
  filterId,
  filterIsProfile,
  filterIsTag,
  filterIsTrashTag,
  getUnmatchedString,
  invertFilter,
  checkIfSuggestedFilterInverted,
} from '../opoint/search/index'
import type { Action, Filter, MultipleSuggestions, Suggestion } from '../opoint/flow'

type SearchState = {
  searchFilters: { [filterId: string]: Filter }
  trashTagIds: Array<number>
  profileTagIds: Array<number>
  selectedTagIds: Array<number>
  suggestions: Array<Suggestion>
  suggestionsMultiple: MultipleSuggestions
  searchInProgress: boolean
  searchIsTakingTooLong: boolean
  loadMoreSearchInProgress: boolean
  meta: {
    foundDocumentsCount: number
    rangeStart?: number
    rangeEnd?: number
    context?: string
    lastTimestamp: number | typeof undefined
    receivedDocumentsCount: number | typeof undefined
  }
  searchDatepicker?: {
    startDate: string
    endDate: string
  }
  searchterm: string
  wikinames: {
    [key in number]: string
  }
  filtersShowMore: any
  wikidescriptions: {
    [key in number]: string
  }
  nofreesearch: boolean
  compareStatisticsFetched: boolean
}

export const initialState: SearchState = {
  searchFilters: {},
  filtersShowMore: [],
  // We store trash-tags on two places so that we don't need to re-render articles
  // each time filter is added. This is a performance optimisation.
  // PLEASE BE CAREFUL WHEN PLAYING AROUND WITH FILTERS AND ALWAYS CHECK A PERFORMANCE OF THE APP
  trashTagIds: [],
  profileTagIds: [],
  selectedTagIds: [],
  // END
  suggestions: [],
  suggestionsMultiple: {},
  searchInProgress: false,
  searchIsTakingTooLong: false,
  loadMoreSearchInProgress: false,
  meta: {
    foundDocumentsCount: 0,
    rangeStart: null,
    rangeEnd: null,
    context: null,
    lastTimestamp: undefined,
    receivedDocumentsCount: undefined,
  },
  searchDatepicker: null,
  searchterm: '',
  wikinames: {},
  wikidescriptions: {},
  nofreesearch: false,
  compareStatisticsFetched: false,
}

let normalStatsProcessedFirst = false

/**
 * This reducer controls how we retrieve suggestions from Opoint's backend.
 * It takes care of toggling filters, inverting them, setting search terms etc.
 *
 * NOTE: It doesn't contain information about complex handling of filters in a profile editor.
 * @see profileReducer for more information how profile editor actions are handled.
 */
const searchReducer = (state: SearchState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    /**
     * In case a location changed, we want to have the search field load the latest data.
     */
    case Actions.ROUTER_SEARCH_DATA_CHANGE: {
      const { parsedFilters, expression } = payload

      return compose(
        assoc('searchterm', or(expression, '')),
        assoc('searchFilters', indexBy(filterId, parsedFilters)),
        assoc(
          'trashTagIds',
          filter(filterIsTrashTag, parsedFilters).map((x) => parseInt(x.id, 10)),
        ),
        assoc(
          'profileTagIds',
          filter(filterIsProfile, parsedFilters).map((x) => parseInt(x.id, 10)),
        ),
        assoc(
          'selectedTagIds',
          filter(filterIsTag, parsedFilters).map((x) => parseInt(x.id, 10)),
        ),
      )(state)
    }
    case Actions.CLEAR_FORM:
    case Actions.FEED_REMOVE_ACTIVE:
      return assoc('abandonedSearchLine', [], state)

    /**
     * Clears the global search terms.
     */
    case Actions.SEARCHDATA_CLEAR:
      return assoc('searchFilters', [], state)

    /**
     * Invert filter given in an payload.
     */
    case Actions.INVERT_FILTER: {
      const { filter } = payload
      return evolve({
        searchFilters: compose(dissoc(filterId(filter)), assoc(filterId(invertFilter(filter)), invertFilter(filter))),
      })(state)
    }
    case Actions.UPDATE_SEARCHTERM_SUCCESS: {
      const { searchterm } = payload
      return assoc('searchterm', searchterm, state)
    }

    /**
     * Assoc. filters once they are successfully retrieved.
     */
    case Actions.FILTERS_FETCH_SUCCESS:
      return assoc('suggestions', payload, state)

    /**
     * Assoc. filters in a multiple mode.
     */
    case Actions.FILTERS_FETCH_MULTIPLE_SUCCESS:
      return assoc('suggestionsMultiple', payload, state)

    /**
     * Filters - show more of filter type
     */
    case Actions.FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS:
      return assoc('filtersShowMore', payload, state)

    case Actions.FILTERS_POP:
      return assoc('filtersShowMore', state.filtersShowMore.slice(0, state.filtersShowMore.length - 1), state)

    /**
     * Remove filter received in an payload
     */
    case Actions.SEARCHFILTER_REMOVED: {
      const filterToBeRemoved: Filter = payload
      return evolve(
        {
          searchFilters: dissoc(filterId(filterToBeRemoved)),
        },
        state,
      )
    }

    /**
     * Once a search filter is added to a search.
     */
    case Actions.SEARCHFILTER_ADDED: {
      const filterToBeAdded: Filter = payload
      return evolve(
        {
          searchFilters: unless(
            prop(filterId(filterToBeAdded)),
            curry(addOneOfKind)(filterId(filterToBeAdded), filterToBeAdded),
          ),
          searchterm: getUnmatchedString(filterToBeAdded),
        },
        state,
      )
    }

    /**
     * Add or remove given filter based on current filters state.
     */
    case Actions.SEARCHFILTER_TOGGLED: {
      // return state
      const filterToBeToggled: Filter = payload
      const idFilter: string = checkIfSuggestedFilterInverted(filterId(filterToBeToggled), state.searchFilters)
      return evolve(
        {
          searchFilters: ifElse(prop(idFilter), dissoc(idFilter), curry(addOneOfKind)(idFilter, filterToBeToggled)),
          searchterm: getUnmatchedString(payload),
        },
        state,
      )
    }

    case Actions.FETCH_MORE_ARTICLES: {
      return assoc('loadMoreSearchInProgress', true, state)
    }

    /**
     * Shrink left side of date range to the point based on currently fetched documents
     */
    case Actions.SEARCH_CHANGE_DATERANGE_TO_CURRENT: {
      return evolve({
        meta: {
          rangeStart: always(state.meta.lastTimestamp),
          foundDocumentsCount: always(state.meta.receivedDocumentsCount),
        },
      })(state)
    }

    /**
     * Functions controlling the state of article's promise.
     */
    case Actions.FETCH_ARTICLES: {
      return compose(assoc('searchInProgress', true), assoc('meta', initialState.meta))(state)
    }

    case Actions.SEARCH_IS_EMPTY: {
      return evolve({
        searchInProgress: F,
        meta: {
          context: always(''),
        },
      })(state)
    }

    case Actions.PROFILE_EDITOR_PREVIEW: {
      return assoc('searchInProgress', true, state)
    }

    case Actions.PROFILE_EDITOR_PREVIEW_FAILURE: {
      return assoc('searchInProgress', false, state)
    }

    case Actions.PROFILE_EDITOR_PREVIEW_SUCCESS: {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        debug,
      } = payload.searchresult

      return evolve({
        searchInProgress: always(false),
        searchIsTakingTooLong: always(false),
        loadMoreSearchInProgress: always(false),
        meta: always({
          receivedDocumentsCount,
          foundDocumentsCount, // (range count)
          // might be equal to number of documents, in which case all
          // requested documents were delivered
          // or it might be bigger number which means not all requested
          // were delivered and time range selector should be shown
          rangeStart, // requested range start
          rangeEnd, // requested range end - might be 0 which means now
          lastTimestamp, // oldest - delivered range start
          firstTimestamp, // newest
          context,
        }),
        searchterm: always(debug && debug.lines ? debug.lines[0].query : state.searchterm),
        wikinames: always(wikinames),
        wikidescriptions: always(wikidescriptions),
      })(state)
    }

    case Actions.FETCH_MORE_ARTICLES_SUCCESS:
    case Actions.FETCH_MORE_PREVIEW_ARTICLES_SUCCESS:
    case Actions.FETCH_ARTICLES_SUCCESS:
    case Actions.FETCH_STATISTICS_SUCCESS: {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        debug,
        nofreesearch,
        count,
      } = payload.response.searchresult

      const { compareMode } = payload

      if (compareMode) {
        normalStatsProcessedFirst = true
      }

      // Merge new wiki values with the ones already in the state when fetching more
      const shouldMergeWikis =
        type === Actions.FETCH_MORE_ARTICLES_SUCCESS || type === Actions.FETCH_MORE_PREVIEW_ARTICLES_SUCCESS

      const allWikinames = shouldMergeWikis && state.wikinames ? mergeAll([state.wikinames, wikinames]) : wikinames

      const allWikidescriptions =
        shouldMergeWikis && state.wikidescriptions
          ? mergeAll([state.wikidescriptions, wikidescriptions])
          : wikidescriptions

      // TODO: Remove this temporary fix, when fixed in the backend.
      let newRangeStart: number
      const now = +new Date()
      // In rare cases where no or just a single article have been fetched, the backend returns this Unix as rangeStart: "3791368940" and sometimes "2147483647"
      // If this is the case, then set the date to now.
      newRangeStart = rangeStart > now ? now : rangeStart

      return evolve({
        searchInProgress: always(false),
        searchIsTakingTooLong: always(compareMode),
        loadMoreSearchInProgress: always(false),
        meta: always({
          receivedDocumentsCount,
          foundDocumentsCount, // (range count)
          // might be equal to number of documents, in which case all
          // requested documents were delivered
          // or it might be bigger number which means not all requested
          // were delivered and time range selector should be shown
          rangeStart: newRangeStart, // requested range start
          rangeEnd, // requested range end - might be 0 which means now
          lastTimestamp, // oldest - delivered range start
          firstTimestamp, // newest
          context,
          count,
        }),
        searchterm: always(debug && debug.lines ? debug.lines[0].query : state.searchterm),
        wikinames: always(allWikinames),
        wikidescriptions: always(allWikidescriptions),
        nofreesearch: always(nofreesearch),
      })(state)
    }
    case Actions.FETCH_MORE_ARTICLES_FAILURE:
    case Actions.FETCH_STATISTICS_FAILURE:
    case Actions.FETCH_ARTICLES_FAILURE:
      return evolve({
        loadMoreSearchInProgress: always(false),
        searchIsTakingTooLong: always(false),
        searchInProgress: always(false),
      })(state)

    case Actions.FETCH_STATISTICS_COMPARE_SUCCESS: {
      const shouldKeepShowing = normalStatsProcessedFirst ? false : true
      // Reset after use
      normalStatsProcessedFirst = false

      return evolve({
        searchIsTakingTooLong: always(shouldKeepShowing),
        compareStatisticsFetched: always(true),
      })(state)
    }

    case Actions.DATEPICKER_MODAL_OPEN: {
      const {
        meta: { rangeEnd, rangeStart },
      } = state
      // Unix timestamp must be converted to iso string since OpointDatepicker
      // is returning iso string
      // When ranges are not set dont change state otherwise dayjs will set
      // start/end date as 'Invalid date'
      return rangeEnd && rangeStart
        ? compose(
            assocPath(['searchDatepicker', 'endDate'], dayjs(rangeEnd, 'x').toISOString()),
            assocPath(['searchDatepicker', 'startDate'], dayjs(rangeStart, 'x').toISOString()),
          )(state)
        : state
    }

    // case Actions.DATEPICKER_MODAL_CLOSE:
    //   return R.assoc('searchDatepicker', null, state)

    case Actions.DATEPICKER_MODAL_SET_START_DATE:
      return assocPath(['searchDatepicker', 'startDate'], payload, state)

    case Actions.DATEPICKER_MODAL_SET_END_DATE:
      return assocPath(['searchDatepicker', 'endDate'], payload, state)

    case Actions.SEARCH_IS_TAKING_TOO_LONG:
      return assoc('searchIsTakingTooLong', true, state)

    case Actions.CANCEL_SEARCH:
      return evolve({
        searchInProgress: always(false),
        searchIsTakingTooLong: always(false),
      })(state)

    case Actions.STORE_CURRENT_SEARCHLINE: {
      const { searchLine } = payload
      return assoc('abandonedSearchLine', searchLine, state)
    }

    default:
      return state
  }
}

export default searchReducer
