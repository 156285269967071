// @ts-nocheck
import R from 'ramda'

import { ALL_DAYS, WORKING_DAYS, isWeekendDay, isWorkingDay } from './index'
import { DAY_CONST } from './weekdays'

export function toAPITimeFormat(data) {
  return internalDataToAPITimeFormat(toInternalData(data))
}

export function internalDataToAPITimeFormat(data) {
  return R.mapObjIndexed((interval, key) => {
    const dayId = DAY_CONST[key].key
    return calculateAPITime(interval, dayId)
  }, data)
}

function calculateAPITime(dayIntervals, dayId) {
  return dayIntervals.map((day) => {
    const ret = {}
    if (day.start === undefined) {
      day.start = 0
    }
    if (day.end === undefined) {
      day.end = 0
    }

    ret.start = day.interval[0] * 60 * 60 + 24 * 60 * 60 * dayId + day.start * 60
    if (day.interval.length > 1) {
      ret.end = day.interval[day.interval.length - 1] * 60 * 60 + 24 * 60 * 60 * dayId + day.end * 60
      ret.interval = 0
    }
    if (day.sendInterval) {
      ret.interval = parseInt(day.sendInterval, 10)
    }
    return ret
  })
}

export function toInternalData(data) {
  const getInternals = (x) => {
    // Day -> Array<Day>
    const getFallbackDays = (day) =>
      R.cond([
        [isWorkingDay, R.always([day, 'weekdays', 'alldays'])],
        [isWeekendDay, R.always([day, 'weekends', 'alldays'])],
        [R.T, R.always([day, 'alldays'])],
      ])(day)

    // Day -> Number
    const getComputedValue = R.compose(
      R.find((x) => x !== undefined) /* eslint-disable-line no-shadow */,
      R.props(R.__, x) /* eslint-disable-line no-underscore-dangle */,
      getFallbackDays,
    )

    return R.filter((x) => x !== undefined, R.fromPairs(ALL_DAYS.map((y) => [y, getComputedValue(y)])))
  }
  return getInternals(data)
}

export function toTimeConfigInternal(data) {
  return R.mapObjIndexed((interval, key) => {
    const dayId = DAY_CONST[key].key
    return toTimeConfigTransform(interval, dayId)
  }, data)
}

export function toTimeConfigTransform(intervals, day) {
  return intervals.map((interval) => {
    const ret = {}
    ret.interval = []
    const startInterval = Math.floor(interval.start / (60 * 60) - day * 24)

    ret.start = Math.round((interval.start / (60 * 60) - day * 24 - startInterval) * 60)

    if (interval.end) {
      const endInterval = Math.floor(interval.end / (60 * 60) - day * 24)
      ret.end = Math.round((interval.end / (60 * 60) - day * 24 - endInterval) * 60)
      ret.interval = R.range(startInterval, endInterval + 1)
    } else {
      ret.interval.push(startInterval)
    }
    if (interval.interval) {
      ret.sendInterval = interval.interval
    }
    if (ret.interval.length > 24) {
      ret.interval = ret.interval.slice(0, 25)
    }

    return ret
  })
}

export function toDisplayTimeFormat(data) {
  return unifySameTimes(toTimeConfigInternal(data))
}

export function unifySameTimes(data) {
  // const allDaysAreWeekendDays = R.all(isWeekendDay)

  // TODO do without arrow function
  const setsEq = R.curry((x, y) => R.compose(R.isEmpty, R.symmetricDifference(x))(y))
  return R.compose(
    R.map(JSON.parse),

    R.reduce((acc, [data, days]) => {
      days.map((day) => {
        acc[day] = data
        return true
      })
      return acc
    }, {}),
    R.toPairs,
    R.map(R.when(setsEq(ALL_DAYS), R.always(['alldays']))),
    R.map(R.when(setsEq(WORKING_DAYS), R.always(['weekdays']))),
    // R.map(R.when(allDaysAreWeekendDays, R.always(['weekends']))),
    R.invert,
    R.map(JSON.stringify),
  )(data)
}
