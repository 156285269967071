// @ts-nocheck

import R from 'ramda'
import { createSelector } from 'reselect'

import { getProfiles as getState, getTags, getForm } from './rootSelectors'
import { getChildrenForProfile, getAllNestedChildrenForProfile } from '../opoint/profiles/index'
import type { Searchline, ProfileDetail } from '../opoint/flow'
import { searchIsFinished } from './searchSelectors'

export const getProfiles = createSelector(getState, (profilesState) => profilesState.list)

export const getProfilesWithType = createSelector(getProfiles, (list) => R.map(R.assoc('type', 'profile'), list))

export const getEditedProfileActive = createSelector(
  getState,
  (profilesState) => profilesState.editedProfile && profilesState.editedProfile.id,
)
export const getProfileEditorDebug = createSelector(getState, (profilesState) => profilesState.profileEditorDebug)

export const getOpenProfile = createSelector(getState, (profilesState) => profilesState.editedProfile)

export const getRootProfiles = createSelector(getProfiles, (profilesList) =>
  profilesList.filter((profile) => profile.parent === 0),
)

export const getProfileById = (profileId: number) =>
  createSelector(getProfiles, (profiles) => profiles.find(({ id }) => profileId === id))

const memoizeChildren = R.memoizeWith(
  (obj) => obj.profileId,
  (obj) => getChildrenForProfile(obj.profileId)(obj.profileList),
)

export const getChildrenForProfileSelector = (profileId: number) =>
  createSelector(getProfiles, (profileList) => {
    const profileObject = {
      profileId,
      profileList,
    }
    return memoizeChildren(profileObject)
  })

export const getAllNestedChildrenForProfileSelector = (profileId: number) =>
  createSelector(getProfiles, getAllNestedChildrenForProfile(profileId))

export const getEditedProfile = createSelector(getState, getForm, (profilesState, profileForm): ProfileDetail => {
  if (!profilesState.editedProfile) {
    return null
  }

  const { profileEditor: { values: { profileName, profileParent, storedSearch, sm_rate } = {} } = {} } = profileForm
  // If user don't have the rights to store search, just return normal object to him
  if (profilesState.editedProfile.stored_search === undefined) {
    return (
      profilesState.editedProfile &&
      R.merge(profilesState.editedProfile, {
        name: profileName,
        parent: profileParent,
        sm_rate,
      })
    )
  }
  const newStoredSearch =
    profilesState.editedProfile.stored_search === null
      ? storedSearch
        ? { active: -1 }
        : null // If they didn't setup stored_search yet, don't
      : { active: storedSearch ? -1 : 0 } // -1 to activate it, 0 to disable it
  return (
    profilesState.editedProfile &&
    R.merge(profilesState.editedProfile, {
      name: profileName,
      parent: profileParent,
      stored_search: newStoredSearch,
    })
  )
})

export const isProfileEditorFiltersPanelOpen = createSelector(getState, (state) => state.profileEditorFiltersOpen)

export const getSearchDataForSearchline = (lineNumber: number) =>
  createSelector(
    getEditedProfile,
    (editedProfile): Searchline => editedProfile && editedProfile.items[lineNumber]?.searchline,
  )

export const getProfilesToDelete = createSelector(getState, (profiles) => profiles.profilesToDelete)

export const getPreviewArticles = createSelector(getState, (profilesState) => profilesState.profileEditorArticles)

export const getPreviewActiveArticle = createSelector(
  getState,
  (profilesState) => profilesState.profileEditorActiveArticle,
)

export const noPreviewArticlesFound = createSelector(
  getPreviewArticles,
  searchIsFinished,
  (articles, isFinished) => articles.length === 0 && isFinished,
)

export const getPreviewIdentical = createSelector(
  getState,
  (profilesState) => profilesState.profileEditorArticlesIdentical,
)

export const getSaveAsProfile = createSelector(getForm, R.path(['profile', 'saveAs', 'values']))

export const getActiveProfileEditorLine = createSelector(getState, (profiles) => profiles.activeProfileEditorLine)

export const getClickedFilterName = createSelector(getState, (profiles) => profiles.clickedFilterName)

export const getClickedFilterType = createSelector(getState, (profiles) => profiles.clickedFilterType)

export const getFilterMetadata = createSelector(getState, (profiles) => profiles.filterMetadata)

export const getFilterMetadataFetchStatus = createSelector(getState, (profiles) => profiles.filterMetadataFetchStatus)

export const isFilterMetadataFetchInProgress = createSelector(
  getState,
  (profiles) => profiles.filterMetadataFetchInProgress,
)

export const isProfileHistoryExpanded = createSelector(getState, (profiles) => profiles.profileHistoryExpanded)

export const isDeletedProfilesExpanded = createSelector(getState, (profiles) => profiles.deletedProfilesExpanded)

/**
 * Provide profile history without searchline for component render
 */
export const getProfileHistory = createSelector(getState, (profiles) => {
  const history = R.path(['editedProfile', 'history'], profiles)
  if (history && !history.results) {
    return { results: [] }
  }
  return history && R.assoc('results', R.map(R.pick(['name', 'timestamp']), history.results), history)
})

export const getProfileTree = createSelector(getState, (profiles) => profiles.tree)

export const getShowMoreHistorySegment = createSelector(getState, (profiles) => profiles.showMoreHistorySegment)

export const getAllHistoryResultsLength = createSelector(
  getState,
  (profiles) =>
    profiles.editedProfile &&
    profiles.editedProfile.allHistoryResults &&
    profiles.editedProfile.allHistoryResults.length,
)

export const isNewProfile = createSelector(
  getState,
  (profiles) => profiles.editedProfile && profiles.editedProfile.id === null,
)

export const getInlineSuggestions = (id: number) =>
  createSelector(getState, (profiles) => profiles.profileEditorInlineSuggestions[id])

export const isPreviewOpened = createSelector(getState, (profiles) => profiles.isProfilePreviewOpened)

export const hasPreviewArticles = createSelector(getState, (profiles) => profiles.profileEditorArticles.length > 0)

export const getIsProfileEditorShownInPreview = createSelector(
  getState,
  (profiles) => profiles.isProfileEditorShownInPreview,
)

export const hasEditedProfileSoMeFilters = createSelector(
  getState,
  (profiles) =>
    profiles.editedProfile &&
    profiles.editedProfile.items &&
    profiles.editedProfile.items.some((item) => {
      const { linemode, searchline } = item
      return (
        linemode === 'R' &&
        searchline.filters.some(({ name }) => {
          return ['Facebook', 'Twitter', 'Instagram'].includes(name)
        })
      )
    }),
)

export const hasNumberOfProfilesAndTagsReachedThreshold = createSelector(getState, getTags, (profiles, tags) => {
  const threshold = 100
  const profilesAndTagsCount = profiles.list.length + tags.list.length
  return profilesAndTagsCount > threshold
})

// Profile Groups
export const getSortedProfileGroups = createSelector(getState, (profiles) => {
  const filteredGroups = profiles.profileGroups
  const sortedGroups = filteredGroups.sort((a, b) => b.sorting - a.sorting)
  return sortedGroups
})

export const getAllProfileGroups = createSelector(getState, (profiles) => profiles.profileGroups)

export const getGroupsTree = createSelector(getState, (profiles) => profiles.groupsTree)
