// @ts-nocheck

import R from 'ramda'

import * as Actions from '../constants/actionTypes'

const initialState = {
  addArticleModalOpen: false,
  alertPreviewOpen: false,
  contactModalOpen: false,
  deleteProfilesModeEnabled: false,
  editArticleModalOpen: false,
  entityFilter: '',
  filterDetailModalOpen: false,
  filtersPanelOpen: false,
  isEditorFormModalOpen: false,
  isEditorModalOpen: false,
  isIEWarningOpen: false,
  isSortableModuleModalOpen: false,
  leaveEditAlertModalOpen: false,
  manageProfilesModalOpen: false,
  manageProfilesGroupNumber: null,
  notificationDownloadModalOpen: false,
  saveAsProfilePopperHide: false,
  settingsModalOpen: false,
  settingsModalDefaultSection: '',
  shareArticleModalOpen: false,
  showBadBrowserPopup: false,
  userLoggedOut: false,
  statisticsExportPdfModal: false,
  newVersionNotification: false,
  deleteProfileConfirmationOpen: false,
  releaseNotesModalOpen: false,
  infoModalOpen: false,
  infoCalcModalOpen: false,
  supportIssueModalOpen: false,
  supportRequestModalOpen: false,
  datepickerModalOpen: false,
  statisticsSidebar: {
    isOpen: false,
    chartType: '',
    contextType: 'edit_widgets',
    graph: null,
    menuList: [],
    count: '',
  },
  filteredPills: {
    filters: {},
    aspects: [],
  },
  correctStatsArticleNumbers: null,
  statsInfoModalOpen: false,
}

const supportClose = {
  id_site: 0,
  id_article: 0,
  isOpen: false,
  header: '',
  countryname: '',
  sitename: '',
  unix_timestamp: 0,
  translated_header: {
    text: '',
    language: '',
    matches: false,
  },
  type: '',
  manual_summary: undefined,
  pdf_file: undefined,
}

const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // case Actions.SEARCH:
    //   return R.assoc('filtersPanelOpen', false, state)

    case Actions.SHOW_NEW_VERSION_NOTIFICATION:
      return R.assoc('newVersionNotification', true, state)

    case Actions.HIDE_NEW_VERSION_NOTIFICATION:
      return R.assoc('newVersionNotification', false, state)

    case Actions.SHOW_IE_WARNING:
      return R.assoc('isIEWarningOpen', true, state)

    case Actions.HIDE_IE_WARNING:
      return R.assoc('isIEWarningOpen', false, state)

    case Actions.FILTER_METAS_SHOW_DETAIL:
      return R.assoc('filterDetailModalOpen', true, state)

    case Actions.FILTER_METAS_HIDE_DETAIL:
      return R.assoc('filterDetailModalOpen', false, state)

    case Actions.SETTINGS_MODAL_OPEN:
      return R.compose(R.assoc('settingsModalOpen', true), R.assoc('settingsModalDefaultSection', payload))(state)

    case Actions.SETTINGS_MODAL_CLOSE:
      return R.compose(R.assoc('settingsModalOpen', false), R.assoc('settingsModalDefaultSection', ''))(state)

    case Actions.MANAGE_PROFILES_MODAL_OPEN:
      return R.compose(R.assoc('manageProfilesModalOpen', true), R.assoc('manageProfilesGroupNumber', payload))(state)

    case Actions.MANAGE_PROFILES_MODAL_CLOSE:
      return R.assoc('manageProfilesModalOpen', false, state)

    case Actions.REPORTS_HISTORY_OPEN:
      return R.assoc('reportsHistoryOpen', true, state)

    case Actions.REPORTS_HISTORY_CLOSE:
      return R.assoc('reportsHistoryOpen', false, state)

    case Actions.SOURCE_LIST_OPEN:
      return R.assoc('sourceListModalOpen', true, state)

    case Actions.SOURCE_LIST_CLOSE:
      return R.assoc('sourceListModalOpen', false, state)

    case Actions.EDIT_ARTICLE_MODAL_OPEN:
      return R.assoc('editArticleModalOpen', true, state)

    case Actions.EDIT_ARTICLE_MODAL_CLOSE:
      return R.assoc('editArticleModalOpen', false, state)

    case Actions.USER_LOGGED_OUT_IN_ANOTHER_TAB:
      return R.assoc('userLoggedOut', true, state)

    case Actions.SHARE_ARTICLE_MODAL_OPEN: {
      const article = payload
      return R.compose(R.assoc('shareArticleModalOpen', true), R.assoc('clickedArticle', article))(state)
    }

    case Actions.SHARE_ARTICLE_MODAL_CLOSE:
      return R.compose(R.assoc('shareArticleModalOpen', false), R.dissoc('clickedArticle'))(state)

    case Actions.CONTACT_MODAL_OPEN:
      return R.assoc('contactModalOpen', true, state)

    case Actions.CONTACT_MODAL_CLOSE:
      return R.compose(R.assoc('contactModalOpen', false), R.assoc('contactDeleteWarning', null))(state)

    case Actions.TEMPLATE_EDITOR_MODAL_OPEN:
      return R.assoc('isEditorModalOpen', true, state)

    case Actions.TEMPLATE_EDITOR_MODAL_CLOSE:
      return R.assoc('isEditorModalOpen', false, state)

    case Actions.TEMPLATE_EDITOR_FORM_MODAL_OPEN:
      return R.assoc('isEditorFormModalOpen', true, state)

    case Actions.TEMPLATE_EDITOR_FORM_MODAL_CLOSE:
      return R.assoc('isEditorFormModalOpen', false, state)

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN:
      return R.assoc('sortableModuleModalOpen', true, state)

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE:
      return R.assoc('sortableModuleModalOpen', false, state)

    case Actions.ALERT_REMOVE_ARTICLE_OPEN:
      return R.assoc('alertRemoveArticleOpen', true, state)

    case Actions.ALERT_REMOVE_ARTICLE_CLOSE:
      return R.assoc('alertRemoveArticleOpen', false, state)

    case Actions.ALERT_PREVIEW_OPEN:
      return R.assoc('alertPreviewOpen', true, state)

    case Actions.ALERT_PREVIEW_CLOSE:
      return R.assoc('alertPreviewOpen', false, state)

    case Actions.ALERT_CHANGE_RECIPIENTS_OPEN:
      return R.assoc('alertChangeRecipientsOpen', true, state)

    case Actions.ALERT_CHANGE_RECIPIENTS_CLOSE:
    case Actions.ALERT_HISTORY_SEND_AGAIN_SUCCESS:
      return R.assoc('alertChangeRecipientsOpen', false, state)

    case Actions.ASK_SAVE_EDITED_ALERT_OPEN:
      return R.assoc('leaveEditAlertModalOpen', true, state)

    case Actions.ASK_SAVE_EDITED_ALERT_CLOSE:
      return R.assoc('leaveEditAlertModalOpen', false, state)

    case Actions.HELP_MODAL_OPEN:
      return R.assoc('helpModalOpen', true, state)

    case Actions.HELP_MODAL_CLOSE:
      return R.assoc('helpModalOpen', false, state)

    case Actions.FILTERS_PANEL_OPEN:
      return R.assoc('filtersPanelOpen', true, state)

    case Actions.FILTERS_PANEL_CLOSE:
      return R.assoc('filtersPanelOpen', false, state)

    case Actions.FILTERS_PANEL_TOGGLE:
      return R.assoc('filtersPanelOpen', !state.filtersPanelOpen, state)

    case Actions.DELETE_PROFILES_MODE_ENABLE:
      return R.assoc('deleteProfilesModeEnabled', true, state)

    case Actions.DELETE_PROFILES_MODE_DISABLE:
      return R.evolve(
        {
          deleteProfilesModeEnabled: R.always(false),
        },
        state,
      )

    case Actions.DELETE_PROFILES_MODE_TOGGLE:
      return R.evolve(
        {
          deleteProfilesModeEnabled: R.always(!state.deleteProfilesModeEnabled),
        },
        state,
      )

    case Actions.ENTITY_FILTER_CHANGED:
      return R.assoc('entityFilter', payload, state)

    case Actions.ADD_ARTICLE_MODAL_OPEN:
      return R.assoc('addArticleModalOpen', true, state)

    case Actions.ADD_ARTICLE_SUCCESS:
    case Actions.ADD_ARTICLE_MODAL_CLOSE:
      return R.assoc('addArticleModalOpen', false, state)

    case Actions.NOTIFICATION_DOWNLOAD_MODAL_OPEN: {
      const { report, notificationId } = payload
      return R.compose(
        R.assoc('notificationDownloadModalOpen', true),
        R.assoc('reportOpened', report),
        R.assoc('notificationId', notificationId),
      )(state)
    }

    case Actions.NOTIFICATION_DOWNLOAD_MODAL_CLOSE:
      return R.compose(R.dissoc('reportOpened'), R.assoc('notificationDownloadModalOpen', false))(state)

    case Actions.REPORTS_MODAL_CLOSE:
      return R.assoc('reportsModalOpen', false, state)

    case Actions.REPORTS_MODAL_OPEN:
      return R.assoc('reportsModalOpen', true, state)

    case Actions.DATEPICKER_MODAL_OPEN:
      return R.assoc('datepickerModalOpen', true, state)

    case Actions.DATEPICKER_MODAL_CLOSE:
    case Actions.DATEPICKER_MODAL_DISMISS:
      return R.assoc('datepickerModalOpen', false, state)

    case Actions.SAVE_AS_PROFILE_INIT_POPPER:
      return R.assoc('saveAsProfilePopperHide', false, state)

    case Actions.SAVE_AS_PROFILE_SUCCESS:
      return R.assoc('saveAsProfilePopperHide', true, state)

    case Actions.PROFILE_DELETE_CONFIRMATION_OPEN:
      return R.assoc('deleteProfileConfirmationOpen', 'manage', state)

    case Actions.PROFILE_EDITOR_DELETE_CONFIRMATION_OPEN:
      return R.assoc('deleteProfileConfirmationOpen', 'editor', state)

    case Actions.PROFILE_DELETE_CONFIRMATION_CLOSE:
      return R.assoc('deleteProfileConfirmationOpen', false, state)

    case Actions.SHOW_BAD_BROWSER_POPUP:
      return R.assoc('showBadBrowserPopup', true, state)

    case Actions.HIDE_BAD_BROWSER_POPUP:
      return R.assoc('showBadBrowserPopup', false, state)

    case Actions.STATISTICS_VIEW_EXPORT_PDF_OPEN_MODAL:
      return R.assoc('statisticsExportPdfModal', true, state)

    case Actions.STATISTICS_VIEW_EXPORT_PDF_CLOSE_MODAL:
      return R.assoc('statisticsExportPdfModal', false, state)

    case Actions.STATISTICS_VIEW_EXPORT_PDF_SUCCESS:
      return R.assoc('statisticsExportPdfModal', false, state)

    case Actions.RELEASE_NOTES_MODAL_OPEN:
      return R.assoc('releaseNotesModalOpen', true, state)

    case Actions.RELEASE_NOTES_MODAL_HIDE:
      return R.assoc('releaseNotesModalOpen', false, state)

    case Actions.INFO_MODAL_OPEN:
      return R.assoc('infoModalOpen', true, state)

    case Actions.INFO_MODAL_HIDE:
      return R.assoc('infoModalOpen', false, state)

    case Actions.INFO_CALC_MODAL_OPEN:
      return R.assoc('infoCalcModalOpen', true, state)

    case Actions.INFO_CALC_MODAL_CLOSE:
      return R.assoc('infoCalcModalOpen', false, state)

    case Actions.SUPPORT_REPORT_MODAL_OPEN:
      return R.assoc('supportIssueModalOpen', payload, state)

    case Actions.SUPPORT_REPORT_MODAL_CLOSE:
      return R.assoc('supportIssueModalOpen', supportClose, state)

    case Actions.SUPPORT_REQUEST_MODAL_OPEN:
      return R.assoc('supportRequestModalOpen', payload, state)

    case Actions.SUPPORT_REQUEST_MODAL_CLOSE:
      return R.assoc('supportRequestModalOpen', supportClose, state)

    case Actions.OPEN_STATISTICS_SIDEBAR:
      return R.assocPath(['statisticsSidebar', 'isOpen'], true, state)

    case Actions.CLOSE_STATISTICS_SIDEBAR:
      return R.assocPath(['statisticsSidebar', 'isOpen'], false, state)

    case Actions.OPEN_EDIT_WIDGETS_STATISTICS_SIDEBAR:
      const openEditWidgets = {
        isOpen: true,
        chartType: '',
        contextType: 'edit_widgets',
        graph: null,
        menuList: [],
        count: '',
      }
      return R.assoc('statisticsSidebar', openEditWidgets)(state)

    case Actions.OPEN_WIDGET_SETTINGS_STATISTICS_SIDEBAR:
      const { chartType, graph, menuList, count } = payload

      const setWidgetSettings = {
        isOpen: true,
        contextType: 'widget_settings',
        graph: graph,
        chartType: chartType,
        menuList: menuList,
        count: count,
      }

      return R.assoc('statisticsSidebar', setWidgetSettings)(state)

    case Actions.STATISTICS_FILTERED_PILLS:
      const { filters, aspects } = payload
      const filteredPills = {
        filters: filters,
        aspects: aspects,
      }
      return R.assoc('filteredPills', filteredPills)(state)

    case Actions.STATISTICS_CORRECT_ARTICLE_NUMBERS:
      const correctStatsArticleNumbers = payload
      return R.assoc('correctStatsArticleNumbers', correctStatsArticleNumbers)(state)

    case Actions.STATS_INFO_MODAL_OPEN:
      return R.assoc('statsInfoModalOpen', true, state)

    case Actions.STATS_INFO_MODAL_CLOSE:
      return R.assoc('statsInfoModalOpen', false, state)

    default:
      return state
  }
}

export default uiReducer
