import React, { ReactNode } from 'react'
import cn from 'clsx'
import { default as ReactModal } from 'react-modal'

import { useTranslation } from '../../hooks/useTranslation'
import Translate from '../../../Translate'
import styles from './index.module.scss'
import Icon from '../Icon'
import Button from '../Button'

type DialogButton = {
  action: () => void
  text: string
}

export interface Props {
  accept?: DialogButton
  children?: ReactNode
  className?: string
  containerOverflow?: boolean
  dismiss?: DialogButton
  footer?: ReactNode
  isOpen: boolean
  mode?: 'normal' | 'small' | 'big'
  noPadding?: boolean
  onAfterClose?: () => void
  onHide?: (e?: any) => void
  overlayStopPropagation?: boolean
  title: string
  subtitle?: string | ReactNode
  type?: 'modal' | 'dialog'
  center?: boolean
}

const Modal = (props: Props) => {
  const {
    accept,
    children,
    className,
    containerOverflow = true,
    dismiss,
    footer,
    isOpen,
    mode,
    noPadding,
    onAfterClose,
    onHide,
    overlayStopPropagation = true,
    title,
    type,
    center,
    subtitle,
  } = props
  const { i18n } = useTranslation()

  return (
    <ReactModal
      onAfterClose={onAfterClose || (() => {})}
      isOpen={isOpen}
      onRequestClose={onHide || (() => {})}
      contentLabel={title}
      className={cn(
        styles.modal,
        {
          [styles.small]: mode === 'small',
          [styles.big]: mode === 'big',
          [styles.dialog]: type === 'dialog',
          [styles.nofooter]: !footer,
          [styles.overflow]: containerOverflow,
        },
        className,
      )}
      overlayClassName={cn(styles.overlay, { [styles.center]: !!center })}
      closeTimeoutMS={100}
      overlayElement={(props, contentElement) => (
        <div onClick={(e) => overlayStopPropagation && e.stopPropagation()}>
          <div {...props}>{contentElement}</div>
        </div>
      )}
      shouldReturnFocusAfterClose={false}
    >
      <div className={cn(styles.header, { [styles.hasSubtitle]: subtitle })}>
        <div>
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
        </div>
        {type !== 'dialog' && (
          <button type="button" aria-label={i18n.t('Close')} onClick={onHide}>
            <Icon name="close" />
          </button>
        )}
      </div>

      <div className={cn(styles.content, { [styles.noPadding]: noPadding })}>
        {type !== 'dialog' ? (
          children
        ) : (
          <>
            {children}
            {!!accept && (
              <Button name="solid" onClick={accept.action}>
                {accept.text}
              </Button>
            )}
            {!!dismiss && (
              <Button name="default" onClick={dismiss.action}>
                {dismiss.text}
              </Button>
            )}
          </>
        )}
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </ReactModal>
  )
}

ReactModal.setAppElement(document.getElementById('root'))

export default Modal
