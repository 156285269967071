import Rx from 'rx-dom'
import R from 'ramda'

import config from '../common/config'
import type { ProfileTag, Feed } from '../flow'

/**
 * API Functions
 */
export function getFeeds() {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/feeds/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function updateFeed(feedId: string, updatedData: Feed) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/feeds/${feedId}/`),
    method: 'put',
    body: JSON.stringify(updatedData),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function createFeed(newFeed: Feed) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/feeds/'),
    method: 'post',
    body: JSON.stringify(newFeed),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function deleteFeed(feedId: string) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/feeds/${feedId}/`),
    method: 'delete',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getActiveProfileTagList(profileTags: Array<ProfileTag>, activeIds: Array<number>) {
  if (!profileTags || !profileTags.length || !activeIds || !activeIds.length) {
    return []
  }

  // @ts-ignore
  return R.filter(({ id }) => R.contains(id, activeIds))(profileTags)
}
