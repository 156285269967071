import React, { ButtonHTMLAttributes } from 'react'
import cn from 'clsx'

import Icon from '../Icon'
import type { IconName } from '../../types/theme'
import styles from './index.module.scss'

export type ButtonProps = {
  children?: React.ReactNode
  className?: string
  icon?: IconName
  label?: string
  name: 'default' | 'solid' | 'text' | 'link'
  onClick?: (e?: any) => void
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, icon, label, name, onClick, ...rest }, ref) => (
    <button
      aria-label={!children && label}
      ref={ref}
      className={cn(
        styles.button,
        styles[name],
        { [styles.iconOnly]: icon && !children, [styles.iconAndChildren]: icon && children },
        className,
      )}
      onClick={onClick || (() => {})}
      type="button"
      {...rest}
    >
      {icon && <Icon name={icon} isDecorative={true} />}
      {children}
    </button>
  ),
)

export default Button
