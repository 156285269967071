import { useRef, useEffect } from 'react'

import { KeyValue } from '../types/settings'
import { IMAGE_URL_BASE } from '../constants/settings'
import { ITEM_SIZE } from '../../new-components/mainMenu/common/constants'
import { useTranslation } from '../hooks/useTranslation'
import { ProfileGroup } from '../../opoint/flow'
import i18n from '../../i18nextInit'
import { IconName } from '../types/theme'

export const executeIfFunction = (fn) => {
  typeof fn === 'function' && fn()
}

// Transforms string of format 'key=val,key2=val2,...' to array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...]
export const keyValueParse = (str?: string, sep: string = ','): KeyValue[] => {
  if (!str) {
    return []
  }

  return str.split(sep).map((item) => {
    const [key, value] = item.split('=')
    return {
      key,
      value,
    }
  })
}

// Transforms array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...] to string of format 'key=val,key2=val2,...'
export const keyValueSerialize = (obj?: KeyValue[], sep: string = ','): string => {
  if (!Array.isArray(obj) || obj.length === 0) {
    return ''
  }

  return obj.map(({ key, value }) => `${key}=${value}`).join(sep)
}

export const changeImageUrlBase = (str: string): string => {
  return str.replace(IMAGE_URL_BASE.old, IMAGE_URL_BASE.new)
}

// Custom hook for keeping previous props/state. Useful for comparing states in useEffect hook.
export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current as T
}

export const swapSignInString = (str: string): string => str.replace(/^(\+|-)/, (sign) => (sign === '-' ? '+' : '-'))

// Check whether a number or string is numeric
export const isNumber = (n: number | string) => {
  return !isNaN(Number(n))
}

// Abbreviate a number to millions (M) or thousands (K)
export const abbreviateNumber = (n: number) => {
  const abbreviate = (cap: number, letter: string) => Math.round((n / cap) * 10) / 10 + letter

  return n >= 1000000 ? abbreviate(1000000, 'M') : n >= 1000 ? abbreviate(1000, 'K') : n
}

/**
 * Sets the height of the scrollwindow.
 * @param list Array
 * @param availableHeight
 * @param showTen How many tags, profiles etc.. should be shown in low resolution.
 * @returns {number} Returns the height of the scrollWindow.
 */
export const getListHeight = (list: any[], availableHeight: number, showTen?: boolean) => {
  const listHeightByItemCount = list.length * ITEM_SIZE
  const listHeight = listHeightByItemCount > availableHeight ? availableHeight : listHeightByItemCount
  const lowResShow = showTen ? 10 : 5

  if (availableHeight < ITEM_SIZE * lowResShow && list.length > lowResShow) {
    return ITEM_SIZE * lowResShow
  } else if (list.length <= lowResShow) {
    return listHeightByItemCount
  } else {
    return listHeight
  }
}

export const alterSoMeSummary = (summary, type, retweeted_user?) => {
  const lastIndex = summary?.lastIndexOf(' ') ?? -1
  const { i18n } = useTranslation()

  switch (true) {
    case type === 'link':
      return summary.substring(0, lastIndex)

    case type === 'retweet':
      let newSummary = summary
      const RTIncluded = summary.includes('RT')

      const removedRT = summary.split(':')
      removedRT.shift() // Removing first element by using shift

      if (RTIncluded) {
        newSummary = removedRT.join(' ')
      }

      return i18n.t(`Retweeted @${retweeted_user}: ${newSummary}`)

    case type === 'reply':
      return i18n.t(`Replying to ${summary}`)

    default:
      return summary
  }
}

export const getGroupNameAndIcons = (group: ProfileGroup, hasNonDefaultGroups: boolean) => {
  const groupAndIconNames: {
    typeName: [string, string, string]
    typeIcons: [IconName, IconName, IconName]
    categoryIcons: [IconName, IconName, IconName, IconName, IconName]
  } = {
    typeName: [
      hasNonDefaultGroups ? i18n.t('My Briefcase') : i18n.t('Profiles'),
      hasNonDefaultGroups ? i18n.t('My Tags') : i18n.t('Tags'),
      hasNonDefaultGroups ? i18n.t('My Sentiment Tags') : i18n.t('Sentiment Tags'),
    ],
    typeIcons: [hasNonDefaultGroups ? 'briefcase' : 'star', 'tag_alt', 'sort'],
    categoryIcons: ['star', 'curated_profiles', 'non_curated_profiles', 'speech_bubble', 'front_pages'],
  }

  const name = group.group === 0 ? groupAndIconNames.typeName[group.type] : group.name

  // TODO: use group traits when available
  const icon =
    group.group === 0 ? groupAndIconNames.typeIcons[group.type] : groupAndIconNames.categoryIcons[group.category]

  return { name, icon }
}
