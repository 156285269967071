// @ts-nocheck

import * as R from 'ramda'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'

import { getContacts as getState, getForm } from './rootSelectors'
import {
  createEmptyContactWithGroups,
  addCheckedGroupsToContact,
  sortByProp,
  isAlertPauseTimeStampValid,
} from '../opoint/contacts'
import { Contact } from '../opoint/flow'

export const getContacts = createSelector(getState, (contactsState) => contactsState.list)

export const getContactsSortedByName: (state: any) => Contact[] = createSelector(getContacts, sortByProp('firstName'))

export const getEditedGroup = createSelector(getState, (contactsState) => contactsState.activeGroup)

export const getContactFormData = createSelector(
  getForm,
  (contactsState) => contactsState.contactEditor && contactsState.contactEditor.values,
)

export const getGroups = createSelector(getState, (contactsState) => contactsState.listGroups)

export const getContactsAndGroups = createSelector(getGroups, getContacts, (groups, contacts) => {
  const contactFilters = contacts.map((contact) => ({ type: 'person', entity: contact }))
  const groupFilters = groups.map((group) => ({ type: 'group', entity: group }))
  return contactFilters.concat(groupFilters)
})

export const getContactFilter = createSelector(getState, (contactsState) => contactsState.contactFilter)

export const getGroupFormData = createSelector(getForm, (data) => data.groupEditor.values)

export const getAlertEmail = createSelector(getState, (contactsState) => contactsState.editedAlertEmail)

export const getAlertSms = createSelector(getState, (contactsState) => contactsState.editedAlertSms)

export const getRecipientsCount = createSelector(
  getState,
  ({ editedAlertEmail, editedAlertSms }) => R.uniq([...editedAlertEmail, ...editedAlertSms]).length,
)

export const getReportContacts = createSelector(getState, (contactsState) => contactsState.shareReportContacts)

export const getContactDeleteWarning = createSelector(getState, (contactsState) => contactsState.contactDeleteWarning)

export const getActiveContactId = createSelector(getState, (contactsState) => contactsState.activeContactId)

export const getContactDetails = createSelector(getState, (contactsState) => contactsState.details)

export const getShareArticlesContacts = createSelector(getState, (contactsState) => contactsState.shareArticlesContacts)

export const getPauseValue = createSelector(
  getActiveContactId,
  getContactDetails,
  (activeContactId, contactDetails) => {
    const pause = R.path([activeContactId, 'pause'], contactDetails)
    return isAlertPauseTimeStampValid(pause) && dayjs.unix(pause) // time in local timezone format
  },
)

function contactIsNotAvailable(details, id) {
  return R.reduce(R.or, false, [R.isNil(id), R.isNil(details), R.not(R.has(id, details))])
}

export const getActiveContactData = createSelector(
  getContactDetails,
  getActiveContactId,
  getGroups,
  (details, activeId, groups) =>
    contactIsNotAvailable(details, activeId)
      ? createEmptyContactWithGroups(groups)
      : addCheckedGroupsToContact(R.prop(activeId, details), groups),
)

export const getExpandedContacts = createSelector(getState, (contactsState) => contactsState.expandContacts)

export const getExpandedGroups = createSelector(getState, (contactsState) => contactsState.expandGroups)
