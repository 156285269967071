// @ts-nocheck
import R from 'ramda'

import * as ActionTypes from '../constants/actionTypes'

export const statisticsExportPdf = (state, { type, payload }) => {
  const valuesLens = R.lensPath(['values'])

  switch (type) {
    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS: {
      const { activeStatisticsViewName } = payload

      return R.compose(
        R.over(valuesLens, R.assoc('fileName', '')),
        R.over(valuesLens, R.assoc('title', activeStatisticsViewName || '')),
        R.over(valuesLens, R.assoc('description', '')),
      )(state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_UPDATE_FILENAME: {
      const { updatedFilenameValue } = payload

      return R.over(valuesLens, R.assoc('fileName', updatedFilenameValue), state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_UPDATE_TITLE: {
      const { updatedTitleValue } = payload
      return R.over(valuesLens, R.assoc('title', updatedTitleValue), state)
    }

    default:
      return state
  }
}
