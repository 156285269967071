import Rx from 'rxjs'
import R from 'ramda'

import buildAction from '../helpers/buildAction'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import * as ActionTypes from '../constants/actionTypes'
import { getProfileGroups } from '../opoint/profiles/groups'
import { getTags } from '../selectors/tagsSelectors'
import { getProfiles, getSortedProfileGroups } from '../selectors/profilesSelectors'
import { ProfileGroup } from '../opoint/flow'

const groupsTree = (groups: Array<ProfileGroup>, profiles, tags) => {
  const groupsWithChildren = groups.map((group) => {
    const items = group.type === 0 ? profiles : tags
    const filteredItems = items.filter((item) => item.group === group.group && item.type === group.type)

    const parentTree = R.groupBy(R.prop('parent'), filteredItems)
    const rootProfiles = parentTree[0]
    const setChildrenForProfile = (profile) =>
      R.assoc('children', (parentTree[profile.id] || []).map(setChildrenForProfile), profile)

    return {
      ...group,
      children: rootProfiles ? R.map(setChildrenForProfile)(rootProfiles) : filteredItems,
    }
  })
  return groupsWithChildren
}

const fetchProfileGroupsOnLogIn = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).mapTo(buildAction(ActionTypes.PROFILE_GROUPS_FETCH))

const profileGroupsFetchEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.PROFILE_GROUPS_FETCH)
    .switchMap(() =>
      Rx.Observable.fromPromise(getProfileGroups()).map((profileGroups) =>
        buildAction(ActionTypes.PROFILE_GROUPS_FETCH_SUCCESS, profileGroups),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.PROFILE_GROUPS_FETCH_FAILURE)))

const createGroupsTreeEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.TAGS_FETCH_SUCCESS)
    .delay(1000)
    .switchMap(() => {
      const state = getState()
      const tagsList = getTags(state)
      const profilesList = getProfiles(state)
      const groupsList = getSortedProfileGroups(state)

      const groupsTreeWithChildren = groupsTree(groupsList, profilesList, tagsList)

      return Rx.Observable.of(buildAction(ActionTypes.CREATE_GROUPS_TREE_SUCCESS, groupsTreeWithChildren))
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)

export default [fetchProfileGroupsOnLogIn, profileGroupsFetchEpic, createGroupsTreeEpic]
