import { createSelector } from 'reselect'

import { getAuth as getState } from './rootSelectors'

export const getUser = createSelector(getState, (authState) => authState.user)

export const isUserAdmin = createSelector(
  getState,
  (authState) => authState.user.privileges_index && authState.user.privileges_index === 2,
)

export const getUserID = createSelector(getUser, (user) => user.user_id)

export const haveServerTime = createSelector(getState, (authState) => authState.gotServerTime)
