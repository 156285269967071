import Rx from 'rxjs'
import R from 'ramda'

import buildAction from '../helpers/buildAction'
import { scrollElementToTop } from '../helpers/common'
import { firstToUpperCase } from '../opoint/common/index'
import { getSelectedProfilesAndTagsIds } from '../selectors/searchSelectors'
import { getProfileById } from '../selectors/profilesSelectors'
import { getTagById } from '../selectors/tagsSelectors'
import { getAlertId } from '../helpers/location'
import { getAlertEmail, getAlertSms } from '../selectors/contactSelectors'
import {
  deleteAlert,
  getAlert,
  getAlertHistory,
  getAlertHistoryItem,
  getAlertNext,
  getAlertNextPreview,
  getAlerts,
  getAlertsRecipients,
  getArticlesToRemove,
  removeArticlesFromAlert,
  saveAlert,
  sendNow,
  sendAgain,
} from '../opoint/alerts/index'
import {
  getEditFormData,
  getEditedAlert,
  getNewAlert,
  getTrashedArticles,
  getCurrentAlertHistoryItem,
  getAlertHistoryById,
} from '../selectors/alertsSelectors'
import * as ActionTypes from '../constants/actionTypes'
import { pushLocation, logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import { history } from '../store'

export const fetchAlerts = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).switchMap(() =>
    Rx.Observable.from(getAlerts())
      .map((alerts) => buildAction(ActionTypes.ALERTS_FETCH_SUCCESS, alerts))
      .catch((err) =>
        err.status === 401
          ? Rx.Observable.of(buildAction(ActionTypes.LOGOUT))
          : Rx.Observable.of(buildAction(ActionTypes.ALERTS_FETCH_FAILURE)),
      ),
  )

export const fetchEpic = (action$) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.ALERT_FETCH),
    action$.ofType(ActionTypes.CONTACTS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.GROUPS_FETCH_SUCCESS).take(1),
  ).switchMap(
    ([
      {
        // @ts-ignore
        payload: { alertId, toBeEdited },
      },
      contactFetchAction,
      // @ts-ignore
      groupsFetchAction,
    ]) => {
      const alertFetchSuccess = (data) =>
        buildAction(ActionTypes.ALERT_FETCH_SUCCESS, {
          alert: data,
          toBeEdited,
        })
      const initMifluz = (data) =>
        buildAction(
          ActionTypes.ALERT_INIT_MIFLUZ,
          data.profiles.map((profile) => profile.id),
        )
      const initRecipients = (data) =>
        buildAction(ActionTypes.ALERT_INIT_RECIPIENTS, {
          recipients: data.recipients,
          smsRecipients: data.smsRecipients,
        })
      return Rx.Observable.fromPromise(getAlert(alertId))
        .switchMap((data) => Rx.Observable.of(alertFetchSuccess(data), initMifluz(data), initRecipients(data)))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH_FAILURE)))
    },
  )

export const fetchNextEpic = (action$) =>
  action$.ofType(ActionTypes.ALERT_FETCH).switchMap(({ payload: { alertId } }) =>
    Rx.Observable.fromPromise(getAlertNext(alertId))
      .map((data) =>
        buildAction(ActionTypes.ALERT_FETCH_NEXT_SUCCESS, {
          id: alertId,
          nextAlert: data,
        }),
      )
      .catch(() => Rx.Observable.of()),
  )

export const fetchNextPreviewEpic = (action$) =>
  action$.ofType(ActionTypes.ALERT_FETCH).switchMap(({ payload: { alertId } }) =>
    Rx.Observable.fromPromise(getAlertNextPreview(alertId))
      .map((data) =>
        buildAction(ActionTypes.ALERT_FETCH_NEXT_PREVIEW_SUCCESS, {
          id: alertId,
          data,
          inProgress: false,
        }),
      )
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH_NEXT_PREVIEW_FAILURE))),
  )

export const fetchHistoryEpic = (action$) =>
  action$.ofType(ActionTypes.ALERT_FETCH).switchMap(({ payload: { alertId } }) =>
    Rx.Observable.fromPromise(getAlertHistory(alertId))
      .map((data) =>
        buildAction(ActionTypes.ALERT_FETCH_HISTORY_SUCCESS, {
          id: alertId,
          data,
        }),
      )
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH_HISTORY_FAILURE))),
  )

export const fetchMoreHistoryEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.ALERT_FETCH_MORE_HISTORY).switchMap(({ payload: { alertId } }) => {
    const state = getState()
    const { next } = getAlertHistoryById(alertId)(state)
    let page = undefined
    if (next !== null) {
      const tmp = next.split('?page=')
      if (tmp.length === 2) {
        page = tmp[1]
      }
    }
    return Rx.Observable.fromPromise(getAlertHistory(alertId, page))
      .map((data) =>
        buildAction(ActionTypes.ALERT_FETCH_MORE_HISTORY_SUCCESS, {
          id: alertId,
          data,
        }),
      )
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH_HISTORY_FAILURE)))
  })

export const fetchHistoryItemEpic = (action$) =>
  action$
    .ofType(ActionTypes.ALERT_FETCH_HISTORY_ITEM)
    .switchMap(({ payload: { alertId, historyId, historyTimestamp } }) =>
      Rx.Observable.fromPromise(getAlertHistoryItem(alertId, historyId, historyTimestamp))
        .map((data) =>
          buildAction(
            // TODO: Temp @honza
            data === -1 ? ActionTypes.ALERT_FETCH_HISTORY_ITEM_FAILURE : ActionTypes.ALERT_FETCH_HISTORY_ITEM_SUCCESS,
            {
              alertId,
              historyId,
              historyTimestamp,
              data,
            },
          ),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH_HISTORY_ITEM_FAILURE))),
    )

export const formSaveAlertEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SAVE_ALERT).switchMap(({ payload }) => {
    const state = getState()
    // Profiles data transformation to correct format
    const { mifluzIdLists } = getEditedAlert(state)
    const formData = getNewAlert(state)

    // Verification - checks whether subject was entered
    // (kind of, #TODO @oliver @honza do it better )
    if (!formData.subject || !formData.subject.length) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_ALERT_VALID_FAILURE, { error: 'You have to enter subject of the alert' }),
      )
    }

    if (R.isEmpty(formData.schedule.timeConfiguration)) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_ALERT_VALID_FAILURE, { error: 'You need to set when to dispatch this alert' }),
      )
    }
    const baskets = {
      baskets: mifluzIdLists
        .map((id) => {
          const item = getProfileById(id)(state) || getTagById(id)(state)
          if (!item) {
            return null
          }
          return { id: formData.id, name: item.name }
        })
        .filter((n) => n),
    }
    const profiles = {
      profiles: mifluzIdLists
        .map((id) => {
          const item = getProfileById(id)(state) || getTagById(id)(state)
          const type = getProfileById(id)(state) ? 'profile' : 'tag'
          if (!item) {
            return null
          }
          return { id: item.id, name: item.name, type }
        })
        .filter((n) => n),
    }
    // Emails / sms transformation to correct format
    const emails = getAlertEmail(state)
    const sms = getAlertSms(state)
    const recipients = {
      recipients: R.map((email) => {
        const label = firstToUpperCase(email.type)
        return email.type === 'person'
          ? {
              type: email.type,
              label,
              name: `${email.entity.firstName} ${email.entity.lastName}`,
              ...email.entity,
            }
          : {
              type: email.type,
              label,
              ...email.entity,
            }
      }, emails),
    }
    const smsRecipients = {
      smsRecipients: R.map((s) => {
        const label = firstToUpperCase(s.type)
        return s.type === 'person'
          ? {
              type: s.type,
              label,
              name: `${s.entity.firstName} ${s.entity.lastName}`,
              ...s.entity,
            }
          : {
              type: s.type,
              label,
              ...s.entity,
            }
      }, sms),
    }

    // Checks whether user chose atleast one recipient
    if (!recipients.recipients.length && !smsRecipients.smsRecipients.length) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_ALERT_VALID_FAILURE, { error: 'You have to enter at least one valid recipient' }),
      )
    }

    // Checks whether user chose atleast one profile / tag
    if (!mifluzIdLists.length) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_ALERT_VALID_FAILURE, { error: 'You have to enter at least one profile or tag' }),
      )
    }

    // Convert bools to ints
    formData.active = formData.active ? 1 : 0
    formData.editable = formData.editable ? 1 : 0
    formData.translate = formData.translate ? 1 : 0

    const editedAlert = R.mergeAll([formData, baskets, profiles, recipients, smsRecipients])

    const fetchAlerts$ = Rx.Observable.defer(getAlerts)
      .map((alerts) => buildAction(ActionTypes.ALERTS_FETCH_SUCCESS, alerts))
      .catch(() => Rx.Observable.of())

    // @ts-ignore
    const saveAlert$ = Rx.Observable.fromPromise(saveAlert(editedAlert))
      .map((alert) => buildAction(ActionTypes.SAVE_ALERT_SUCCESS, { alert }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.SAVE_ALERT_FAILURE)))

    return saveAlert$.switchMap((saveAlertAction) => {
      if (saveAlertAction.type === ActionTypes.SAVE_ALERT_SUCCESS) {
        const {
          payload: {
            alert: { id },
          },
        } = saveAlertAction
        return Rx.Observable.concat(
          Rx.Observable.of(saveAlertAction),
          fetchAlerts$,
          payload && payload.doNotShowPreview ? Rx.Observable.of() : pushLocation(`/alerts/${id}`),
        )
      }
      return Rx.Observable.of(
        buildAction(ActionTypes.SAVE_ALERT_VALID_FAILURE, { error: 'Unknown error, please contact support.' }),
      )
    })
  })

export const fetchAlertsRecipients = (action$: any) =>
  action$.ofType(ActionTypes.ALERTS_FETCH_RECIPIENTS).switchMap(() =>
    Rx.Observable.defer(getAlertsRecipients)
      .map((alertsRecipients) => buildAction(ActionTypes.ALERTS_FETCH_RECIPIENTS_SUCCESS, { alertsRecipients }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERTS_FETCH_RECIPIENTS_FAILURE))),
  )

export const needFetchRecipientsAgain = (action$: any) =>
  action$
    .ofType(ActionTypes.SAVE_ALERT_SUCCESS, ActionTypes.CONTACT_SAVE_SUCCESS)
    .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.ALERTS_FETCH_RECIPIENTS)))

export const scrollTopOnAlertCreated = (action$: any) =>
  action$.ofType(ActionTypes.SAVE_ALERT_SUCCESS).switchMap(() => {
    scrollElementToTop('.mod-full-height')
    return Rx.Observable.empty()
  })

export const deleteAlertEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_ALERT_CONFIRM).switchMap(() => {
    const deletedAlert = getEditFormData(getState())
    const deletedAlertId = deletedAlert ? deletedAlert.values.id : undefined
    const fetchAlerts$ = Rx.Observable.defer(getAlerts)
      .map((alerts) => buildAction(ActionTypes.ALERTS_FETCH_SUCCESS, alerts))
      .catch(() => Rx.Observable.of())

    const deleteAlert$ = Rx.Observable.fromPromise(deleteAlert(deletedAlertId))
      .map(() => buildAction(ActionTypes.ALERT_DELETE_SUCCESS, deletedAlertId))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_DELETE_FAILURE)))

    return deleteAlert$.switchMap((deleteAlertAction) =>
      Rx.Observable.concat(
        Rx.Observable.of(deleteAlertAction),
        fetchAlerts$,
        Rx.Observable.of(buildAction(ActionTypes.ALERT_ADD_CANCEL)),
      ),
    )
  })

export const goToEditAlertEpic = (action$: any) =>
  action$.ofType(ActionTypes.EDIT_ALERT).switchMap(() => {
    const path = history.location.pathname
    return pushLocation(`${path}/edit`)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.ROUTE_CHANGE_FAILURE)))
  })

export const goToCreateAlertEpic = (action$: any) =>
  action$.ofType(ActionTypes.CREATE_ALERT).switchMap(() =>
    Rx.Observable.of(
      pushLocation('/alerts/new'), // It's important to push it first
      buildAction(
        // Need this to be able compare init and edited values
        ActionTypes.ALERT_FORM_STORE_INIT_VALUES,
        {
          recipients: {
            recipients: [],
            smsRecipients: [],
          },
          mifluzIdLists: [],
        },
      ),
    )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.CREATE_ALERT_FAILURE))),
  )

export const goToCreateAlertFromActionLine = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.CREATE_ALERT_ACTIONLINE).switchMap(() => {
    const state = getState()
    const searchLine = getSelectedProfilesAndTagsIds(state)
    return Rx.Observable.of(buildAction(ActionTypes.INIT_FORM, searchLine), buildAction(ActionTypes.CREATE_ALERT))
  })

export const sendAlertNowEpic = (action$: any) =>
  action$.ofType(ActionTypes.SEND_ALERT_NOW).switchMap(() => {
    const id = getAlertId()
    // @ts-ignore
    const sendAlert$ = Rx.Observable.fromPromise(sendNow(id))
      .map(() => buildAction(ActionTypes.ALERT_SEND_SUCCESS, id))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.ALERT_SEND_FAILURE)))
    return sendAlert$.switchMap((sendAlertAction) => Rx.Observable.of(sendAlertAction))
  })

export const sendAlertHistoryItemEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEND_ALERT_HISTORY_ITEM_AGAIN).switchMap(() => {
    const state = getState()
    const currentAlertHistory = getCurrentAlertHistoryItem(getState())
    const recipients = getAlertEmail(state)

    const sendAlert$ = Rx.Observable.fromPromise(sendAgain({ alertHistoryItem: currentAlertHistory, recipients }))
      .map(() => buildAction(ActionTypes.ALERT_HISTORY_SEND_AGAIN_SUCCESS))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.ALERT_SEND_FAILURE)))
    return sendAlert$.switchMap((sendAlertAction) => Rx.Observable.of(sendAlertAction))
  })

export const fetchArticlesToRemoveEpic = (action$: any) =>
  action$.ofType(ActionTypes.ALERT_FETCH_ARTICLES).switchMap(({ payload: { alertId, pageId } }) =>
    Rx.Observable.fromPromise(getArticlesToRemove(alertId, pageId))
      .map((data) => {
        // @ts-ignore
        const articles = JSON.parse(data).results
        return buildAction(ActionTypes.ALERT_FETCH_ARTICLES_SUCCESS, { articles })
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH_ARTICLES_FAILURE))),
  )

export const removeArticesFromAlertEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES).switchMap(() => {
    const trashedArticles = getTrashedArticles(getState())
    const alertId = getAlertId()
    const fetchAlert$ = Rx.Observable.of(buildAction(ActionTypes.ALERT_FETCH, { alertId, toBeEdited: false }))

    // @ts-ignore
    const removeArticles$ = Rx.Observable.fromPromise(removeArticlesFromAlert(alertId, trashedArticles))
      .map(() => buildAction(ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_FAILURE)))

    return removeArticles$.switchMap((deleteAlertAction) =>
      Rx.Observable.concat(Rx.Observable.of(deleteAlertAction), fetchAlert$),
    )
  })

export const storeAlertFormInit = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.ALERT_INIT_MIFLUZ),
    action$.ofType(ActionTypes.ALERT_INIT_RECIPIENTS),
  )
    .distinctUntilChanged()
    .switchMap((payload) => {
      const [mifluz, recipients] = payload
      return Rx.Observable.of(
        buildAction(ActionTypes.ALERT_FORM_STORE_INIT_VALUES, {
          // @ts-ignore
          recipients: recipients.payload,
          // @ts-ignore
          mifluzIdLists: mifluz.payload,
        }),
      )
    })

export const discardAlertChangesContinue = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DISCARD_ALERTS_CHANGES_CONTINUE).switchMap(() => {
    const {
      alerts: {
        pendingAction: { type, payload },
      },
    } = getState()
    return Rx.Observable.concat(
      Rx.Observable.of(buildAction(type, payload)),
      Rx.Observable.of(buildAction(ActionTypes.ASK_SAVE_EDITED_ALERT_CLOSE)),
    )
  })

export default [
  deleteAlertEpic,
  discardAlertChangesContinue,
  fetchAlerts,
  fetchAlertsRecipients,
  fetchArticlesToRemoveEpic,
  fetchEpic,
  fetchHistoryEpic,
  fetchHistoryItemEpic,
  fetchNextEpic,
  fetchNextPreviewEpic,
  formSaveAlertEpic,
  goToCreateAlertEpic,
  goToCreateAlertFromActionLine,
  goToEditAlertEpic,
  needFetchRecipientsAgain,
  removeArticesFromAlertEpic,
  sendAlertHistoryItemEpic,
  sendAlertNowEpic,
  storeAlertFormInit,
  fetchMoreHistoryEpic,
  scrollTopOnAlertCreated,
]
