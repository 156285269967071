import React from 'react'
import { connect } from 'react-redux'

import Button from '../Button'
import i18n from '../../../i18nextInit'
import styles from './index.module.scss'
import { IMAGES } from '../../../constants'
import * as ActionTypes from '../../../constants/actionTypes'
import buildActionCreators from '../../../helpers/buildActionCreators'

class ErrorBoundary extends React.Component<MapState & MapDispatch & OwnProps> {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    }
  }

  onClickHandler = () => {
    window.location.reload()
  }

  openHelpModalHandler = () => {
    this.props.helpModalOpen()
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      if (this.props.statistics) {
        return (
          <div className={styles['stats-error']}>
            <span className={styles.text}>{i18n.t('Oh no! Something went wrong.')}</span>
            {this.props.reloadButton && (
              <Button name="solid" type="button" onClick={this.onClickHandler}>
                {i18n.t('Reload page')}
              </Button>
            )}
          </div>
        )
      } else {
        return (
          <div
            style={{
              background: this.props.errorImg && 'url(' + IMAGES.errorImg + ')' + 'bottom right no-repeat',
              minHeight: this.props.smallPicture ? '240px' : '448px',
              backgroundSize: this.props.smallPicture && '300px',
              justifyContent: this.props.smallPicture && 'center',
            }}
            className={styles['error-wrapper']}
          >
            <div className={styles.container}>
              <div>
                <h2 className={styles.text}>{i18n.t('Oh no! Something went wrong.')}</h2>
                {this.props.reloadButton && (
                  <Button name="solid" type="button" onClick={this.onClickHandler}>
                    {i18n.t('Reload page')}
                  </Button>
                )}
                {this.props.contactSupport && (
                  <span onClick={() => this.openHelpModalHandler()} className={styles['support-button']}>
                    {i18n.t('Contact support')}
                  </span>
                )}
              </div>
            </div>
          </div>
        )
      }
    }
    return this.props.children
  }
}

const mapState = null
const mapDispatch = buildActionCreators({
  helpModalOpen: ActionTypes.HELP_MODAL_OPEN,
})

type MapState = typeof mapState
type MapDispatch = typeof mapDispatch
type OwnProps = {
  children: React.ReactNode
  reloadButton: boolean
  contactSupport?: boolean
  errorImg?: boolean
  smallPicture?: boolean
  statistics?: boolean
}

export default connect<MapState, MapDispatch, OwnProps>(null, mapDispatch)(ErrorBoundary)
