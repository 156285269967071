import * as R from 'ramda'

import i18nextInit from '../../i18nextInit'

export const locales = {
  'en-GB': {
    name: 'English',
    englishName: 'English',
    suggestionLocale: 'en_GB_1',
    alertsLocale: 'gb_EN',
    country: 'United Kingdom',
    countryCode: 'GB_EN',
    timezone: 'Europe/London',
    browserLocale: 'en',
    sourceListLocale: 'en',
    holidaysLocale: 'GB_EN',
    momentLocale: 'en',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: i18nextInit.t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: i18nextInit.t('Sweden'), number: '(+46) 020-150 050' },
        { label: i18nextInit.t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'MMM Do, Y',
      short: 'MMM Do',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'nb-NO': {
    name: 'Norsk (Bokmål)',
    englishName: 'Norwegian (Bokmål)',
    suggestionLocale: 'nb_NO_1',
    alertsLocale: 'nb_NO',
    country: 'Norway',
    countryCode: 'NO',
    timezone: 'Europe/Oslo',
    browserLocale: 'no',
    sourceListLocale: 'nb',
    holidaysLocale: 'NO',
    momentLocale: 'nb',
    data: {
      email: 'support@infomedia.no',
      phone: [{ label: '', number: '(+47) 21 56 97 50' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'nn-NO': {
    name: 'Norsk (Nynorsk)',
    englishName: 'Norwegian (Nynorsk)',
    suggestionLocale: 'nn_NO_1',
    alertsLocale: 'nn_NO',
    country: 'Norway',
    countryCode: 'NO',
    timezone: 'Europe/Oslo',
    browserLocale: 'nn',
    sourceListLocale: 'nn',
    holidaysLocale: 'NO',
    momentLocale: 'nn',
    data: {
      email: 'support@infomedia.no',
      phone: [{ label: '', number: '(+47) 21 56 97 50' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'sv-SE': {
    name: 'Svenska',
    englishName: 'Swedish',
    suggestionLocale: 'sv_SE_1',
    alertsLocale: 'sv_SE',
    country: 'Sweden',
    countryCode: 'SE',
    timezone: 'Europe/Stockholm',
    browserLocale: 'sv',
    sourceListLocale: 'sv',
    holidaysLocale: 'SE',
    momentLocale: 'sv',
    data: {
      email: 'support@infomedia.se',
      phone: [{ label: '', number: '(+46) 020-150 050' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'da-DK': {
    name: 'Dansk',
    englishName: 'Danish',
    suggestionLocale: 'da_DK_1',
    alertsLocale: 'da_DK',
    country: 'Denmark',
    countryCode: 'DK',
    timezone: 'Europe/Copenhagen',
    browserLocale: 'da',
    sourceListLocale: 'da',
    holidaysLocale: 'DK',
    momentLocale: 'da',
    data: {
      email: 'support@infomedia.dk',
      phone: [{ label: '', number: '(+45) 88 77 33 00' }],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'et-EE': {
    name: 'Eesti',
    englishName: 'Estonian',
    suggestionLocale: 'et_EE_1',
    alertsLocale: 'et_EE',
    country: 'Estonia',
    countryCode: 'EE',
    timezone: 'Europe/Tallinn',
    browserLocale: 'et',
    sourceListLocale: 'et',
    holidaysLocale: 'EE',
    momentLocale: 'et',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: i18nextInit.t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: i18nextInit.t('Sweden'), number: '(+46) 020-150 050' },
        { label: i18nextInit.t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'fi-FI': {
    name: 'Finnish',
    englishName: 'Finnish',
    suggestionLocale: 'fi_FI_1',
    alertsLocale: 'fi_FI',
    country: 'Finland',
    countryCode: 'FI_FI',
    timezone: 'Europe/Helsinki',
    browserLocale: 'fi',
    sourceListLocale: 'fi',
    momentLocale: 'fi',
    holidaysLocale: 'FI',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: i18nextInit.t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: i18nextInit.t('Sweden'), number: '(+46) 020-150 050' },
        { label: i18nextInit.t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'zh-CN': {
    name: 'Chinese',
    englishName: 'Chinese',
    suggestionLocale: 'zh_CN_1',
    alertsLocale: 'zh_CN',
    country: 'China',
    countryCode: 'CN',
    timezone: 'Asia/Beijing',
    browserLocale: 'zh',
    sourceListLocale: 'zh',
    momentLocale: 'zh',
    holidaysLocale: 'CN',
    data: {
      email: 'support@infomedia.no',
      phone: [
        { label: i18nextInit.t('Denmark'), number: '(+45) 88 77 33 00' },
        { label: i18nextInit.t('Sweden'), number: '(+46) 020-150 050' },
        { label: i18nextInit.t('Norway'), number: '(+47) 21 56 97 50' },
      ],
    },
    dFormat: {
      long: 'MMMM Do YYYY',
      short: 'MMM Do YY',
      days: 'dddd',
      dayTime: 'dddd, h:mm',
    },
  },
}

export const SUPPORTED_LOCALES = Object.keys(locales)

// @ts-ignore
export const getCountries = () => R.compose(R.uniq(), R.values(), R.map(R.prop('country')))(locales)

// @ts-ignore
export const getLanguages = () => R.compose(R.values(), R.map(R.prop('englishName')))(locales)

// @ts-ignore
export const getTimezones = () => R.compose(R.uniq(), R.values(), R.map(R.prop('timezone')))(locales)

export const getDefaultCountry = (locale) => locales[locale].country
export const getDefaultTimezone = (locale) => locales[locale].timezone
export const getDefaultLanguage = (locale) => locales[locale].englishName
export const javaLocaleToBaseLocaleId = (locale) => locale.split('-').join('_')

export function browserLocaleToOpointLocale(browserLocaleToFind) {
  const localeEntry = Object.entries(locales).find(
    ([opointLocale, { browserLocale }]) => browserLocale === browserLocaleToFind,
  )
  return localeEntry ? localeEntry[0] : 'en-GB'
}

/* auto translations */

export const TRANSLATE_ARTICLE = 1
export const TRANSLATION_LINK = 2

// ISO 639-1 codes supported by opoint's auto translate
export const autoTranslateLanguageCodes = [
  'en',
  'no',
  'sv',
  'de',
  'zh',
  'es',
  'it',
  'ar',
  'fr',
  'ru',
  'tr',
  'pl',
  'nl',
  'vi',
  'ja',
  'pt',
  'ko',
  'el',
  'id',
  'ro',
  'bs',
  'da',
  'fi',
  'hi',
  'fa',
  'hr',
  'th',
  'sq',
  'hu',
  'cs',
  'sl',
  'mk',
  'bg',
  'sk',
  'bn',
  'lt',
  'ca',
  'uk',
  'lv',
  'ms',
  'pa',
  'ml',
  'ta',
  'et',
  'sr',
  'az',
  'gu',
  'gl',
  'kn',
  'am',
  'ka',
  'mr',
  'ur',
  'hy',
  'te',
  'is',
  'eu',
  'kk',
  'mt',
  'cy',
  'my',
  'si',
  'af',
  'km',
  'so',
  'ga',
  'sw',
  'be',
  'ht',
  'mi',
]

// all languages supported by google translate
// (https://cloud.google.com/translate/docs/languages) - note: zh-CN + zh-TW => zn; ma => pa
const mainLanguages = {
  cs: 'Czech',
  da: 'Danish',
  en: 'English',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  no: 'Norwegian',
  pl: 'Polish',
  es: 'Spanish',
  sv: 'Swedish',
  zh: 'Chinese',
}

const allLanguages = {
  af: i18nextInit.t('Afrikaans'),
  sq: i18nextInit.t('Albanian'),
  am: i18nextInit.t('Amharic'),
  ar: i18nextInit.t('Arabic'),
  hy: i18nextInit.t('Armenian'),
  az: i18nextInit.t('Azeerbaijani'),
  eu: i18nextInit.t('Basque'),
  be: i18nextInit.t('Belarusian'),
  bn: i18nextInit.t('Bengali'),
  bs: i18nextInit.t('Bosnian'),
  bg: i18nextInit.t('Bulgarian'),
  ca: i18nextInit.t('Catalan'),
  ceb: i18nextInit.t('Cebuano'),
  ny: i18nextInit.t('Chichewa'),
  zh: i18nextInit.t('Chinese'),
  co: i18nextInit.t('Corsican'),
  hr: i18nextInit.t('Croatian'),
  cs: i18nextInit.t('Czech'),
  da: i18nextInit.t('Danish'),
  nl: i18nextInit.t('Dutch'),
  en: i18nextInit.t('English'),
  eo: i18nextInit.t('Esperanto'),
  et: i18nextInit.t('Estonian'),
  tl: i18nextInit.t('Filipino'),
  fi: i18nextInit.t('Finnish'),
  fr: i18nextInit.t('French'),
  fy: i18nextInit.t('Frisian'),
  gl: i18nextInit.t('Galician'),
  ka: i18nextInit.t('Georgian'),
  de: i18nextInit.t('German'),
  el: i18nextInit.t('Greek'),
  gu: i18nextInit.t('Gujarati'),
  ht: i18nextInit.t('Haitian'),
  ha: i18nextInit.t('Hausa'),
  haw: i18nextInit.t('Hawaiian'),
  iw: i18nextInit.t('Hebrew'),
  hi: i18nextInit.t('Hindi'),
  hmn: i18nextInit.t('Hmong'),
  hu: i18nextInit.t('Hungarian'),
  is: i18nextInit.t('Icelandic'),
  ig: i18nextInit.t('Igbo'),
  id: i18nextInit.t('Indonesian'),
  ga: i18nextInit.t('Irish'),
  it: i18nextInit.t('Italian'),
  ja: i18nextInit.t('Japanese'),
  jw: i18nextInit.t('Javanese'),
  kn: i18nextInit.t('Kannada'),
  kk: i18nextInit.t('Kazakh'),
  km: i18nextInit.t('Khmer'),
  ko: i18nextInit.t('Korean'),
  ku: i18nextInit.t('Kurdish'),
  ky: i18nextInit.t('Kyrgyz'),
  lo: i18nextInit.t('Lao'),
  la: i18nextInit.t('Latin'),
  lv: i18nextInit.t('Latvian'),
  lt: i18nextInit.t('Lithuanian'),
  lb: i18nextInit.t('Luxembourgish'),
  mk: i18nextInit.t('Macedonian'),
  mg: i18nextInit.t('Malagasy'),
  ms: i18nextInit.t('Malay'),
  ml: i18nextInit.t('Malayalam'),
  mt: i18nextInit.t('Maltese'),
  mi: i18nextInit.t('Maori'),
  mr: i18nextInit.t('Marathi'),
  mn: i18nextInit.t('Mongolian'),
  my: i18nextInit.t('Burmese'),
  ne: i18nextInit.t('Nepali'),
  no: i18nextInit.t('Norwegian'),
  ps: i18nextInit.t('Pashto'),
  fa: i18nextInit.t('Persian'),
  pl: i18nextInit.t('Polish'),
  pt: i18nextInit.t('Portuguese'),
  pa: i18nextInit.t('Punjabi'),
  ro: i18nextInit.t('Romanian'),
  ru: i18nextInit.t('Russian'),
  sm: i18nextInit.t('Samoan'),
  gd: i18nextInit.t('Scots'),
  sr: i18nextInit.t('Serbian'),
  st: i18nextInit.t('Sesotho'),
  sn: i18nextInit.t('Shona'),
  sd: i18nextInit.t('Sindhi'),
  si: i18nextInit.t('Sinhala'),
  sk: i18nextInit.t('Slovak'),
  sl: i18nextInit.t('Slovenian'),
  so: i18nextInit.t('Somali'),
  es: i18nextInit.t('Spanish'),
  su: i18nextInit.t('Sundanese'),
  sw: i18nextInit.t('Swahili'),
  sv: i18nextInit.t('Swedish'),
  tg: i18nextInit.t('Tajik'),
  ta: i18nextInit.t('Tamil'),
  te: i18nextInit.t('Telugu'),
  th: i18nextInit.t('Thai'),
  tr: i18nextInit.t('Turkish'),
  uk: i18nextInit.t('Ukrainian'),
  ur: i18nextInit.t('Urdu'),
  uz: i18nextInit.t('Uzbek'),
  vi: i18nextInit.t('Vietnamese'),
  cy: i18nextInit.t('Welsh'),
  xh: i18nextInit.t('Xhosa'),
  yi: i18nextInit.t('Yiddish'),
  yo: i18nextInit.t('Yoruba'),
  zu: i18nextInit.t('Zulu'),
}

// for example taken 'en' or 'en:cy' returns ['English']
export const getFullnameLanguage = (codes: Array<string>) => R.props(R.split(':', codes[0]))(allLanguages) || 'Unknown'

// pick only those languages whose codes are in autoTranslateLanguageCodes
export const languages: Object = R.pick(autoTranslateLanguageCodes, mainLanguages)

// We need this incase we translate to main languages
// from language that has not support for translating to
export const translatedFromLanguages: Object = R.pick(autoTranslateLanguageCodes, allLanguages)

// overlaps modes represented by signs
export const EXACT_MODE = -1
export const FUZZY_MODE = +1

export const COUNTRIES_CODES = [
  1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018,
  1019, 1020, 1021, 1022, 1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034, 1035, 1036, 1037,
  1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046, 1047, 1049, 1050, 1051, 1052, 1053, 1054, 1073, 1074, 1088,
  1089, 1090, 1091, 1092, 1094, 1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1105, 1106, 1119, 1120, 1121,
  1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130, 1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140,
  1141, 1142, 1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154, 1155, 1156, 1157, 1158, 1159,
  1160, 1161, 1162, 1163, 1164, 1165, 1166, 1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
  1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190, 1191, 1192, 1193, 1194, 1195, 1196, 1197,
  1198, 1199, 1200, 1201, 1202, 1203, 1221, 1222, 1224, 1225, 1226, 1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234,
  1235, 1236, 1237, 1238, 1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1269, 1270, 1271, 1272,
  1273, 1274, 1275, 1276, 1277, 1278, 1279, 1281, 1282, 1283, 1284, 1285, 1286, 1287, 1288, 1289, 1290, 1291, 1292,
  1293, 1295, 1296, 1297, 1298, 1299, 1300, 1301, 1304, 1354, 1355, 1356, 1357, 1358, 1359, 1360, 1361, 1362, 1363,
  1364, 1365, 1366, 1368, 1369, 1371, 1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390,
]

export const BROADCAST_MEDIA_TYPES = ['TV', 'WEB_TV', 'RADIO', 'WEB_RADIO']

export const SCREENSHOT_TYPES = {
  FULLSIZE: 2,
  THUMBNAIL: 4,
}

export const SOCIAL_SOURCES = {
  1860266: {
    icon: 'facebook',
    name: 'Facebook',
  },
  1091698: {
    icon: 'instagram',
    name: 'Instagram',
  },
  1077116: {
    icon: 'twitter',
    name: 'Twitter',
  },
}

export const FACEBOOK_URL = 'https://www.facebook.com/'
export const FACEBOOK_HASHTAG_URL = 'https://www.facebook.com/hashtag/'

export const INSTAGRAM_URL = 'https://www.instagram.com/'
export const INSTAGRAM_HASHTAG_URL = 'https://www.instagram.com/explore/tags/'

export const TWITTER_URL = 'https://twitter.com/'
export const TWITTER_HASHTAG_URL = 'https://twitter.com/hashtag/'

export const SOCIAL_URLS = {
  Facebook: {
    handle: FACEBOOK_URL,
    hashtag: FACEBOOK_HASHTAG_URL,
  },
  Instagram: {
    handle: INSTAGRAM_URL,
    hashtag: INSTAGRAM_HASHTAG_URL,
  },
  Twitter: {
    handle: TWITTER_URL,
    hashtag: TWITTER_HASHTAG_URL,
  },
}
