import React from 'react'
import classNames from 'clsx'

import { IconName } from '../../types/theme'
import styles from './Icon.module.scss'

export interface Props {
  id?: string
  isDecorative?: boolean
  name: IconName
  title?: string
  verticalCenter?: boolean
}

export default function Icon({ id, isDecorative, name, title, verticalCenter = false }: Props) {
  return (
    <span
      aria-hidden={isDecorative}
      className={classNames(styles.wrapper, { [styles.verticalCenter]: verticalCenter })}
      id={id}
      title={title}
    >
      <svg className={styles.icon} pointerEvents="none">
        <use xlinkHref={`#icon-${name}`} />
      </svg>
    </span>
  )
}
