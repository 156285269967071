import Rx from 'rx-dom'
import R from 'ramda'

import config from '../common/config'
import { verifyProfile } from '../search/index'
import { getDefaultTimezone } from '../common/constants'
import type {
  Profile,
  ProfileDetail,
  Searchline,
  SearchResult,
  TBList,
  CommonFilterMetaDetails,
  SiteFilterMetaDetails,
  ProfileHistoryFromApi,
  ProfileHistoryResult,
} from '../flow'

// @ts-ignore
export const isEmptySearchline: (searchline: Searchline) => boolean = R.compose(
  R.allPass([R.propSatisfies(R.compose(R.isEmpty, R.trim), 'searchterm'), R.propSatisfies(R.isEmpty, 'filters')]),
  // @ts-ignore
  R.prop('searchline'),
)

/**
 * Join parametrs to validate string for api call
 */
export const pagesParametrs = (page: number, perPage: number) =>
  R.compose(
    R.join(';'),
    R.without([undefined, null]),
    R.append(perPage && `per_page=${perPage}`),
    R.append(page && `page=${page}`),
  )

/**
 * Strips empty searchlines from a profile
 */
// @ts-ignore
export const stripEmptySearchlines: (profile: ProfileDetail) => ProfileDetail = R.evolve({
  items: R.reject(isEmptySearchline),
})

export const profileHistorySegmentation = (
  results: Array<ProfileHistoryResult>,
): Array<Array<ProfileHistoryResult>> => {
  const splitedResults = R.splitEvery(7, results)
  const newResults = []

  for (let i = 1; i <= splitedResults.length; i *= 2) {
    newResults.push(R.slice(i - 1, i * 2 - 1, splitedResults))
  }
  return R.map(R.flatten, newResults)
}

/**
 * Retrieves profiles from API and transforms them into a Promise.
 * @returns {*}
 */
export function getProfiles(): Promise<Array<Profile>> {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/profiles/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function saveProfile(profile: ProfileDetail): Promise<ProfileDetail> {
  // We must dissociate id of each filter in this profile to avoid discrepancy
  // Backend will create new id for each filter.
  const profileWoItemsId = R.evolve({
    /* eslint-disable-line no-param-reassign */ items: R.map(R.dissoc('id')),
  })(profile)

  const isNewProfile = profileWoItemsId.id === null
  const method = isNewProfile ? 'POST' : 'PUT'
  const url = isNewProfile ? '/profiles/' : `/profiles/${profileWoItemsId.id}/`
  const body = R.compose(
    R.dissoc('allHistoryResults'),
    R.dissoc('history'),
    // @ts-ignore
  )(stripEmptySearchlines(isNewProfile ? R.dissoc('id', profileWoItemsId) : profileWoItemsId))
  const { items } = profileWoItemsId
  // @ts-ignore
  const expressions = items.map(({ searchline, linemode }) => ({ searchline, linemode }))

  // @ts-ignore
  return verifyProfile(expressions).then(({ searchresult: { debug } }: { searchresult: SearchResult }) => {
    if (debug) {
      return { debug }
    }
    const requestHeaders = R.merge(config.request.getRequestHeaders(), {
      method,
      url: config.url.api(url),
      body: JSON.stringify(body),
    })

    return Rx.DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response }) => response)
  })
}

/**
 *  Get profile details from API
 */
export function getProfileDetail(profileId: number, locale: string): Promise<ProfileDetail> {
  const requestHeaders = R.compose(
    R.assocPath(['headers', 'accept-language'], locale),
    R.merge({ url: config.url.api(`/profiles/${profileId}/`) }),
  )(config.request.getRequestHeaders())
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response: profileDetail }) => {
      if (profileDetail.items.some(({ linemode }) => linemode === 'R')) {
        return R.evolve({
          items: R.sortBy(
            ({ linemode }) =>
              ({
                R: 0,
                O: 1,
                E: 2,
              }[linemode]),
          ),
        })(profileDetail)
      }
      return R.over(
        // @ts-ignore
        R.lensProp('items'),
        R.prepend({
          searchline: {
            searchterm: '',
            filters: [],
          },
          linemode: 'R',
        }),
      )(profileDetail)
    })
}

export function profileListToProfileTree(list: Array<Profile>): Array<Profile> {
  // @ts-ignore
  const parentTree = R.groupBy(R.prop('parent'), list)
  const rootProfiles = parentTree[0]
  const setChildrenForProfile = (profile) =>
    R.assoc('children', (parentTree[profile.id] || []).map(setChildrenForProfile), profile)

  return rootProfiles ? R.map(setChildrenForProfile)(rootProfiles) : []
}

export function profileTreeToProfileList(tree: Array<Profile>): Array<Profile> {
  return tree.reduce(
    // @ts-ignore
    (acc, profile) => acc.concat([R.omit('children', profile)], profileTreeToProfileList(profile.children)),
    [],
  )
}

/**
 *  Get profile details from API
 */
export function getFilterMetasDetail(
  filterId: number,
  type: string,
  locale: string,
): Promise<TBList | SiteFilterMetaDetails | CommonFilterMetaDetails> {
  const needLocale = type === 'list' || type === 'tblist' ? '' : getDefaultTimezone(locale)
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/filters/${type}/${filterId}/${needLocale}/`),
  })
  const requestHeadersWithLanguage = R.assocPath(['headers', 'accept-language'], locale, requestHeaders)
  return Rx.DOM.get(requestHeadersWithLanguage)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Given a flat array of profiles, it returns all direct children for a given profile.
 * It's curried so that we can generate multiple functions for different profiles.
 * @param profileId
 * @param profiles
 */
export const getChildrenForProfile = R.curry(
  (profileId: number, profiles: Array<Profile>): Array<Profile> =>
    // @ts-ignore
    R.compose(R.prop(profileId), R.groupBy(R.prop('parent')))(profiles),
)

/**
 * Given a flat array of profiles, it returns all nested children for a given profile.
 * Profiles are identified using profileId reference in Opoint's specification.
 * It's curried so that we can generate multiple functions for different profiles.
 * @param profileId
 * @param profiles
 */
export const getAllNestedChildrenForProfile = R.curry((profileId: number, profiles: Array<Profile>): Array<Profile> => {
  // let's map parent ids to children once
  // @ts-ignore
  const parentMap = R.groupBy(R.prop('parent'))(profiles)

  const getAllNestedChildren = (profileId) => {
    if (parentMap[profileId]) {
      return [].concat(parentMap[profileId], ...parentMap[profileId].map((x) => getAllNestedChildren(x.id)))
    }
    return []
  }

  return getAllNestedChildren(profileId)
})

/**
 * Delete profiles on backend
 */
export function deleteProfile({
  id,
  locale = 'en-GB',
  force = false,
}: {
  id: number
  locale: string
  force: boolean
}): Promise<Array<Profile>> {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/profiles/${id}/${force ? '?force=1' : ''}`),
    // url: config.getUrl(`/profiles/${id}/`),
    method: 'DELETE',
    responseType: 'text',
  })
  const requestHeadersWithLanguage = R.assocPath(['headers', 'accept-language'], locale, requestHeaders)
  return Rx.DOM.ajax(requestHeadersWithLanguage)
    .toPromise()
    .then(({ response }) => response)
}

export function updateProfilesOrder(position: number, profileId: number, parent: number): void {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/profiles/sort/'),
    method: 'POST',
    responseType: 'text',
    body: JSON.stringify({ profileId, position, parent }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getProfileHistory(profileId: number): Promise<ProfileHistoryFromApi> {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/profiles/${profileId}/history/`),
  })
  return Rx.DOM.get(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getDeletedProfiles(): Promise<ProfileHistoryFromApi> {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/profiles/deleted/'),
  })
  return Rx.DOM.get(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getProfileDependencies(id: number): any {
  return (
    fetch(config.url.api(`/profiles/${id}/dependencies/`), {
      ...config.request.getRequestHeaders(),
    })
      // .then(handleErrors)
      .then((response) => response.json())
  )
}

export function findParents(node, id) {
  // If current node name matches the search id, return
  // empty array which is the beginning of our parent result
  if (node.id === id) {
    return []
  }
  // Otherwise, if this node has a tree field/value, recursively
  // process the nodes in this tree array
  if (Array.isArray(node.children)) {
    for (var child of node.children) {
      // Recursively process child. If an array result is
      // returned, then add the child.id to that result
      // and return recursively
      const childResult = findParents(child, id)

      if (Array.isArray(childResult)) {
        return [child.id].concat(childResult)
      }
    }
  }
}

export const WATCH_INDEX_LIMIT = 99

export const HISTORY_TYPE = {
  DELETED_PROFILES: 'deletedProfiles',
  PROFILE_HISTORY: 'profileHistory',
}
