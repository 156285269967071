import io from 'socket.io-client'
import { Observable } from 'rxjs'

import config from '../common/config'

export function notificationSocket() {
  const query = `token=${config.jwtAuthToken()}`

  const ioNotification = io.connect(config.url.api('/notifications'), {
    query,
    transports: ['websocket', 'polling', 'flashsocket'],
  })

  return Observable.create((observer) => {
    ioNotification.on('updated', (notification) => {
      observer.next(notification)
    })
  })
}
