import { combineEpics } from 'redux-observable'

import alertsEpics from './alertsEpics'
import analyticsEpics from './analyticsEpics'
import articlesEpics from './articlesEpics'
import authEpics from './authEpics'
import contactsEpics from './contactsEpics'
import feedsEpics from './feedsEpics'
import notificationsEpics from './notificationsEpics'
import profileGroupsEpic from './profileGroupsEpic'
import profilesEpics from './profilesEpics'
import reportsEpics from './reportsEpics'
import reportsHistoryEpics from './reportsHistoryEpics'
import searchEpics from './searchEpics'
import settingsEpics from './settingsEpics'
import statisticsEpics from './statisticsEpics'
import tagsEpics from './tagsEpics'
import templatesEpics from './templatesEpics'
import watchIndexEpics from './watchIndexEpics'

const epics = [
  ...alertsEpics,
  ...analyticsEpics,
  ...articlesEpics,
  ...authEpics,
  ...contactsEpics,
  ...feedsEpics,
  ...notificationsEpics,
  ...profilesEpics,
  ...reportsEpics,
  ...reportsHistoryEpics,
  ...searchEpics,
  ...settingsEpics,
  ...statisticsEpics,
  ...tagsEpics,
  ...templatesEpics,
  ...watchIndexEpics,
  ...profileGroupsEpic,
]

const configureEpics =
  (deps: Object, platformEpics: Array<any>) =>
  (action$, { getState }) =>
    // @ts-ignore
    combineEpics(...epics, ...platformEpics)(action$, { ...deps, getState })

export default configureEpics
