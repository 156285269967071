import dayjs from 'dayjs'
import { createEpicMiddleware } from 'redux-observable'

import configureEpics from './epics/configureEpics'
import errorEpics from './epics/web/errorEpics'
import i18next from './i18nextInit'
import navigationEpics from './epics/web/navigationEpics'
import uiEpics from './epics/web/uiEpics'
import { locales } from './opoint/common/constants'
import OpointDate from './opoint/common/time'
import { CLEAR_ARTICLES, ROUTER_LOCATION_CHANGE } from './constants/actionTypes'
import { getOpointLocale } from './selectors/settingsSelectors'

const webEpics = [...errorEpics, ...navigationEpics, ...uiEpics]

const rootEpic = configureEpics(
  {
    // ... platform deps in a future can go here
    i18n: i18next,
  },
  webEpics,
)

export const epicMiddleware = createEpicMiddleware(rootEpic)

// This is a performance optimization, when we nagigate from one search
// to another, we clear article listing so that articles are not re-rendered
// before route change
export const routeChangedMiddleware = (store) => (next) => (action) => {
  if (!!action._isScalar) {
    action = action.value
    console.warn('-> action was scalar')
  }

  if (action.type === ROUTER_LOCATION_CHANGE) {
    // @ts-ignore
    window.Intercom?.('update')
  }

  if (action.type === ROUTER_LOCATION_CHANGE && action.payload.location && action.payload.location.pathname) {
    const { search: newSearch, pathname: newPathname } = action.payload.location
    const { search, pathname } = action.payload.previousLocation

    const isNewLocation =
      newSearch !== search || (!newPathname.startsWith(pathname) && !pathname.startsWith(newPathname))

    if (isNewLocation) {
      store.dispatch({ type: CLEAR_ARTICLES })
    }
  }
  return next(action)
}

export const changeLanguageMiddleware = (store) => (next) => (action) => {
  const result = next(action)
  const opointLang = getOpointLocale(store.getState())
  if (opointLang && opointLang !== i18next.language) {
    const language = locales[opointLang]
    if (language) {
      dayjs.locale(language.momentLocale)
      OpointDate.setLanguage(language.momentLocale)
    }
    i18next.changeLanguage(opointLang)
  }
  return result
}
