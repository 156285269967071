import Rx from 'rx-dom'
import R from 'ramda'

import config from '../common/config'

export function getTags() {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/analysis-tags/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
