import XHR from 'i18next-xhr-backend'
import i18next from 'i18next'

export default i18next.use(XHR).init(
  {
    lngs: ['en-GB', 'nb-NO', 'da-DK', 'nn-NO', 'sv-SE', 'da-DK', 'ee-ET', 'fi-FI'],
    fallbackLng: 'en-GB',
    keySeparator: '::',
    nsSeparator: ':::',
  },
  (err) => {
    /* eslint-disable-next-line no-console */
    console.error(err)
  },
)
