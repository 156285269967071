import version from '../../version.json'

export function setReleaseNotesViewed(): void {
  const { fullCurrentM360Version } = getCurrentM360Version()
  window.localStorage.setItem('M360_VERSION_CHECKED', fullCurrentM360Version)
}

export function getReleaseNotesViewed(): string {
  return window.localStorage.getItem('M360_VERSION_CHECKED')
}

export function getCurrentM360Version() {
  const { patch, releaseDate } = version

  return {
    releaseDate,
    fullCurrentM360Version: `${releaseDate}_${patch}`,
  }
}

export function checkM360Version(onNewVersion: Function): void {
  const storedCheckedM360Version = getReleaseNotesViewed()
  const { fullCurrentM360Version } = getCurrentM360Version()

  if (storedCheckedM360Version !== fullCurrentM360Version) {
    onNewVersion()
  }
}
