import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'
import type { Notification } from '../flow'

/**
 * method parameter is used to reset unread notifications count
 * get method pulls unread count from server
 * post method reset unread count to 0 and server returns updated count to 0
 */
export function getNotificationsCount(method: string = 'get'): Promise<number> {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/notifications/alert-count/'),
    method,
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getNotifications(page: number): Promise<Notification> {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/notifications/?page=${page}`),
    method: 'get',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
