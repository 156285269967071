// @ts-nocheck
import Rx from 'rxjs'

import buildAction from '../helpers/buildAction'
import { getDefaultHome } from '../selectors/settingsSelectors'
import {
  SERVER_TIME_TIMER,
  authenticate,
  authenticateByIp,
  usersLoginInfo,
  authenticateWithToken,
  getUserInfo,
  isAuthenticated,
  refreshToken,
  removeToken,
  setToken,
  getServerTime,
  setServerTime,
  loginRoute,
} from '../opoint/auth/index'
import * as ActionTypes from '../constants/actionTypes'
import { pushLocation } from './epicsHelper'

export const checkIfLoggedInOnBootstrap = (action$: any) =>
  action$.ofType(ActionTypes.BOOTSTRAP).switchMap(() => {
    if (isAuthenticated()) {
      return Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, getUserInfo()))
    }

    return Rx.Observable.of()
  })

export const redirectToDefaultPageAfterLoginEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.GO_TO_DEFAULT_PROFILE),
  ).switchMap(() => {
    const defaultHome = getDefaultHome(getState())
    if (defaultHome && defaultHome.type === 'search') {
      return pushLocation('/search/')
    }
    if (defaultHome && defaultHome.type && defaultHome.id) {
      return pushLocation(
        `/search/?filters=${defaultHome.type}:${defaultHome.id}&timestamp=${Math.floor(new Date().getTime() / 1000)}`,
      )
    }
    return Rx.Observable.of(buildAction(ActionTypes.NO_DEFAULT_HOME_PAGE))
  })

export const redirectToDefaultMobilePageAfterLoginEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.GO_TO_DEFAULT_PROFILE_MOBILE),
  ).switchMap(() => {
    const defaultHome = getDefaultHome(getState())
    if (defaultHome && defaultHome.type === 'search') {
      return pushLocation('/mobile/')
    }
    if (defaultHome && defaultHome.type && defaultHome.id) {
      return pushLocation(`/mobile/?filters=${defaultHome.type}:${defaultHome.id}`)
    }
    return Rx.Observable.of(buildAction(ActionTypes.NO_DEFAULT_HOME_PAGE))
  })

export const redirectToFirstProfileIfNoDefaultHomePage = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.NO_DEFAULT_HOME_PAGE),
  )
    .take(1)
    .switchMap(([{ payload: profiles }]) => {
      if (profiles && profiles.length) {
        const firstProfile = profiles[0]
        return pushLocation(`/search/?filters=profile:${firstProfile.id}`)
      }
      return pushLocation(`/search`)
    })
// TODO - add take until sth like DEFAULT_HOME_PAGE_FOUND

export const loginEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN).switchMap(({ payload: { username, password } }) =>
    Rx.Observable.fromPromise(authenticate(username, password))
      .switchMap(({ token }) => {
        setToken(token)
        const userInfo = getUserInfo()
        return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, userInfo)))
      })
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOG_IN_ERROR))),
  )

export const loginByIpEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_BY_IP).switchMap(({ payload: { username } }) =>
    Rx.Observable.fromPromise(authenticateByIp(username))
      .switchMap(({ token }) => {
        setToken(token)
        const userInfo = getUserInfo()
        return Rx.Observable.concat(Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, userInfo)))
      })
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOG_IN_ERROR))),
  )

export const clearLoginError = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_ERROR).delay(5000).mapTo(buildAction(ActionTypes.LOG_IN_ERROR_TIMEOUT))

export const logoutEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOGOUT).switchMap(() => {
    window.addEventListener('beforeunload', removeToken)
    window.addEventListener('pagehide', removeToken) // For iOS compat
    window.localStorage.setItem('not_auto_login', 'true')
    window.location = loginRoute()
    return Rx.Observable.of()
  })

export const usersLoginInfoEpic = (action$: any) =>
  action$.ofType(ActionTypes.USERS_LOGIN_INFO).switchMap(() =>
    Rx.Observable.fromPromise(usersLoginInfo())
      .switchMap((payload) => Rx.Observable.of(buildAction(ActionTypes.USERS_LOGIN_INFO_SUCCESS, payload)))
      .catch(() => Rx.Observable.of()),
  )

export const refreshTokenEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).switchMap(() => {
    window.localStorage.removeItem('not_auto_login')
    return Rx.Observable.interval(1000 * 60 * 10)
      .switchMap(() =>
        Rx.Observable.fromPromise(refreshToken())
          .map(({ token }) => {
            setToken(token)
            return buildAction(ActionTypes.REFRESH_TOKEN_SUCCESS)
          })
          .catch(() => Rx.Observable.of(buildAction(ActionTypes.REFRESH_TOKEN_FAILURE))),
      )
      .takeUntil(action$.ofType(ActionTypes.LOGOUT))
  })

export const fetchServerTimeOnce = (action$: any) =>
  action$.ofType(ActionTypes.GET_SERVER_TIME_ONCE).switchMap((timeout: number) =>
    Rx.Observable.fromPromise(getServerTime()).switchMap((payload) => {
      if (!payload || (payload && payload.status && (payload.status === 0 || payload.status >= 500))) {
        setTimeout(() => Rx.Observable.of(buildAction(ActionTypes.GET_SERVER_TIME_ONCE)), SERVER_TIME_TIMER / 2)
      } else {
        const timestamp = payload
        setServerTime(timestamp)
      }

      return Rx.Observable.of()
    }),
  )

export const updateServerTimeEpic = (action$: any) =>
  action$.ofType(ActionTypes.GET_SERVER_TIME).switchMap(() =>
    Rx.Observable.timer(SERVER_TIME_TIMER, SERVER_TIME_TIMER)
      .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.GET_SERVER_TIME_ONCE)))
      .takeUntil(action$.ofType(ActionTypes.LOGOUT)),
  )

export const loginWithTokenEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_WITH_TOKEN).switchMap(({ payload: { token } }) =>
    Rx.Observable.fromPromise(authenticateWithToken(token))
      .map(({ token }) => {
        /* eslint-disable-line no-shadow */
        setToken(token)
        const userInfo = getUserInfo()
        Raven.setUserContext({
          id: userInfo.user_id,
          email: userInfo.email,
          username: userInfo.username,
        })
        window.location.href = '/'
        return buildAction(ActionTypes.LOG_IN_WITH_TOKEN_SUCCESS, { token })
      })
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOG_IN_WITH_TOKEN_FAILURE))),
  )

export const loginWithAuthTokenEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.LOG_IN_WITH_AUTH_TOKEN)
    .switchMap(({ payload: { token, id = '', profile = '' } }) => {
      setToken(token)

      // Build querystring params for article path if id or profile is set
      const qs = new URLSearchParams()

      if (!!profile) {
        qs.append('profile', profile)
      }

      if (!!id) {
        qs.append('id', id)
      }

      const location = !!id || !!profile ? `/article?${qs}` : '/'

      return Rx.Observable.concat(
        Rx.Observable.of(buildAction(ActionTypes.LOG_IN_SUCCESS, getUserInfo())),
        pushLocation(location),
      )
    })
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.LOG_IN_ERROR)))

export const userAuthChangedInAnotherTab = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.USER_TOKEN_CHANGED).switchMap(({ payload: { oldUser, newUser } }) => {
    const state = getState()
    if (state.auth.user) {
      // when changing this, please test IE11 properly
      if (oldUser !== null && (oldUser && oldUser.username) !== (newUser && newUser.username)) {
        return Rx.Observable.of(buildAction(ActionTypes.USER_LOGGED_OUT_IN_ANOTHER_TAB))
      }
    } else {
      window.location.href = '/'
    }
    return Rx.Observable.of()
  })

export default [
  checkIfLoggedInOnBootstrap,
  clearLoginError,
  fetchServerTimeOnce,
  loginEpic,
  loginByIpEpic,
  usersLoginInfoEpic,
  loginWithTokenEpic,
  loginWithAuthTokenEpic,
  logoutEpic,
  redirectToDefaultPageAfterLoginEpic,
  redirectToDefaultMobilePageAfterLoginEpic,
  redirectToFirstProfileIfNoDefaultHomePage,
  refreshTokenEpic,
  updateServerTimeEpic,
  userAuthChangedInAnotherTab,
]
