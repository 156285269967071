// @ts-nocheck

import R from 'ramda'
import dayjs from 'dayjs'

import * as ActionTypes from '../constants/actionTypes'
import { TAG_TYPES } from '../opoint/tags'
import { SPECIAL_ASPECTS_IDS, DEFAULT_ASPECT_GROUPS_TO_COMPUTE } from '../opoint/statistics/aspects'
import { EXACT_MODE, FUZZY_MODE } from '../opoint/common/constants'
import type { Action, StatisticAspect } from '../opoint/flow'

type StatisticsState = {
  aspects: Array<StatisticAspect>
  defaultAspects: Array<StatisticAspect>
  documents: Array<any>
  filters: any | {}
  countBy: 'count' | 'circulation' | 'reach' | 'eac'
  loading: boolean
  computedAspectGroup: number
  aspectsRequested: any
  list: Array<any>
  activeStatView: any | null
  showFilteredArticles: boolean
  filteredArticles: Array<any>
  changedAspectsType: any
  changedAspectsCountBy: any
  extendedStartDate: any | void
  extendedCount: any | void
  exportPDFtrigger: Boolean
  statisticsComparisonOpen: Boolean
  comparedDocuments: Array<any>
  previousPeriod: { prevRangeEnd: any; prevRangeStart: any }
  previousAspects: Array<any>
  previousPeriodDatePicker: boolean
  previousPeriodDates: {
    startDate: any
    endDate: any
  }
  comparePeriod: string
  //Initial states to compare for save actionbar button to show
  initialAspects: Array<StatisticAspect>
  initialAspectsType: { [key: string]: string }
  initialAspectsCountBy: { [key: string]: string }
  initialValuesSet: boolean
  correctStatisticsDates: any
  openRenamingPopup: boolean
}

export const initialState: StatisticsState = {
  aspects: [],
  defaultAspects: [],
  documents: [],
  filters: {},
  countBy: 'count',
  loading: false,

  comparedDocuments: [],
  previousPeriod: { prevRangeEnd: {}, prevRangeStart: {} },
  previousAspects: [],
  previousPeriodDatePicker: false,
  previousPeriodDates: null,
  comparePeriod: 'Previous period',
  compareStatsLoading: false,

  computedAspectGroup: DEFAULT_ASPECT_GROUPS_TO_COMPUTE,
  aspectsRequested: [],
  list: [],
  activeStatView: null,
  showFilteredArticles: false,
  filteredArticles: [],
  changedAspectsType: {},
  changedAspectsCountBy: {},
  extendedStartDate: undefined,
  extendedCount: undefined,

  exportPDFtrigger: false,
  statisticsComparisonOpen: false,
  initialAspects: [],
  initialAspectsCountBy: {},
  initialAspectsType: {},
  //Used for the save button to not flicker
  initialValuesSet: false,
  correctStatisticsDates: {},
  openRenamingPopup: false,
}

// Used to store current period aspects.
let currentAspects = null

/**
 * convert array of aspectId-partId pairs from api format to object
 * example: [[111, 1], [222, 2], [111, 4]] -> {111: [1,4], 222: [2]}
 * @param docsAspects
 * @param aspects
 */
const aspectsById = (docsAspects, aspects, compareMode) => {
  const aspectsById = {}
  aspects.forEach(({ id }) => {
    // initiate empty arrays first
    aspectsById[id] = []
  })
  docsAspects.forEach(([aspId, partId]) => {
    if (!aspectsById[aspId]) {
      /* eslint-disable-next-line no-console */
      console.error('Bad API response. Aspect %i not in aspectset', aspId)
      aspectsById[aspId] = []
    }
    aspectsById[aspId].push(
      compareMode
        ? aspects.filter((a) => a.id === aspId)[0]?.aspectpart.filter((p) => p.id === partId)[0]?.name[0]
        : partId,
    ) // add to array
  })
  return aspectsById
}

/**
 * Return all names of aspectPart with given id in given aspect
 * @param aspect
 * @param partId
 */
const namesOfPartById = (aspect, partId, compareMode) => {
  const aspectPart = compareMode
    ? aspect.aspectpart.find(({ name }) => name[0] === partId)
    : aspect.aspectpart.find(({ id }) => +id === +partId)

  if (!aspectPart) {
    /* eslint-disable-next-line no-console */
    console.error('Bad API response. Part %i of aspect %i not found.', partId, aspect.id)
    return []
  }
  return aspectPart.name
}

/**
 * Replace multiple aspect part with one aspect parts with same name set
 * eg. if doc has aspect 13 with parts [802, 803] and
 * those parts have names ["B:279626"] and ["B:279625"]
 * and there also exists part 804 which has names ["B:279626", "B:279625"]
 * then replace parts with [804]
 * @param aspectsById
 * @param aspects
 * @returns {*}
 */

const mergeMultipleAspectParts = (aspectsById, aspects, compareMode) => {
  R.toPairs(aspectsById).forEach(([aspId, aspectPartIds]) => {
    if (aspectPartIds.length > 1) {
      const aspect = aspects.find(({ id }) => +id === +aspId)
      const isSameSet = (namesA, namesB) =>
        namesA.length === namesB.length && namesA.every((name) => namesB.includes(name))
      const currentAspectPartNames = R.flatten(
        aspectPartIds.map((partId) => {
          const names = namesOfPartById(aspect, partId, compareMode)
          if (names.length > 1) {
            throw Error('Cannot merge Aspect with multiple names')
          }
          return names
        }),
      )

      // if some unused aspectPart containing all of aspectParts names exist, use it instead
      const equivalentPart = aspect.aspectpart.find(({ name }) => isSameSet(name, currentAspectPartNames))
      if (equivalentPart) {
        aspectsById[aspId] = [equivalentPart.id]
      }
    }
  })
  return aspectsById
}

/**
 * Removing redundant data from documents.
 * @param documents
 * @returns Returns an array of documents with a reduced amount of data.
 */
const reduceDocumentsData = (documents) => {
  return documents.map((d) => ({
    adprice: d.adprice,
    aspects: d.aspects,
    id_article: d.id_article,
    id_site: d.id_site,
    reach: d.reach,
    circulation: d.circulation,
    unix_timestamp: d.unix_timestamp,
  }))
}

/**
 * Changing the names of the Time widgets (TW) aspectparts, so that we can compare the two periods better.
 * Barbing off the day and month from the name.
 * @param aspects
 * @param compareMode Should only trigger in compare mode
 * @returns Returns aspects, but with changed names of TW's aspectparts
 */
const timeWidgetAspectpartsNameChange = (aspects, compareMode) => {
  if (compareMode && aspects) {
    return aspects
      .filter((aspect) => aspect.id === 39)[0]
      ?.aspectpart.map((part) => {
        part.name = [part.name[0].split(' ')[0]]
      }, aspects)
  }
}

/**
 * This reducer controls how we retrieve statistic results from Opoint's backend.
 */
const statisticsReducer = (state: StatisticsState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionTypes.FETCH_STATISTICS: {
      return R.compose(
        R.assoc('loading', true),
        R.assoc('compareStatsLoading', true),
        R.assoc('documents', []),
        R.assoc('comparedDocuments', []),
      )(state)
    }

    case ActionTypes.FETCH_STATISTICS_COMPARE: {
      return R.compose(R.assoc('compareStatsLoading', true))(state)
    }

    case ActionTypes.CLEAR_ARTICLES: {
      return R.assoc('activeStatView', null)(state)
    }

    case ActionTypes.STATISTICS_SHOW_FILTERED: {
      return R.assoc('showFilteredArticles', true)(state)
    }

    case ActionTypes.STATISTICS_HIDE_FILTERED: {
      return R.assoc('showFilteredArticles', false)(state)
    }

    case ActionTypes.FETCH_STATISTICS_COMPARE_SUCCESS: {
      let {
        response: {
          searchresult: {
            document: documents,
            aspectset: { aspect: previousAspects },
          },
        },
        preserveAspects,
        compareMode,
      } = payload
      const { isStatView } = payload

      documents?.sort((a, b) => {
        const diff = b.unix_timestamp - a.unix_timestamp
        if (diff < 86200 && diff > -3600) {
          return b.unix_timestamp - a.unix_timestamp
        } else {
          a.remove = 'remove'
        }
      })

      let aspects = previousAspects

      // In rare cases, the amount of aspects from current and previous period, will be different from each other.
      // This will cause the app to crash completely or make it load indefinite.
      // If current aspects has a higher amount, we're filtering the missing aspect from the current period, and adding it to the previous period.
      // This will prevent the app from crashing.
      if (currentAspects) {
        if (currentAspects.length > previousAspects.length) {
          const missingAspect = currentAspects.filter(
            (cAspect) => !previousAspects.find((pAspect) => cAspect.id === pAspect.id),
          )
          missingAspect.map((aspect) => {
            aspects.push(aspect)
          })
        }
      }

      const filteredDocs = documents?.filter((document) => document.remove !== 'remove')

      documents = filteredDocs

      documents = reduceDocumentsData(documents)

      // process aspectparts for Time widget
      timeWidgetAspectpartsNameChange(aspects, compareMode)

      // process documents:
      documents = (documents || []).map((doc) => ({
        ...doc,
        // convert timestamp to Date
        date: new Date(doc.unix_timestamp * 1000),
        // normalize fields by which can articles be summed
        count: 1,
        reach: doc.reach || 0,
        eac: doc.adprice || 0,
        circulation: doc.circulation || 0,
        // append aspect directly to doc under its id
        ...mergeMultipleAspectParts(aspectsById(doc.aspects, aspects, compareMode), aspects, compareMode),
      }))

      // process aspects:
      const toObjById = R.indexBy(R.prop('id'))
      const renameProp = (from, to) => (a) => {
        a[to] = a[from]
        delete a[from]
        return a
      }

      const modifyAspects = R.map(
        R.compose(
          (a) => (isStatView ? a : R.assoc('selected', isAspectComputed(a) && isSepEnough(a))(a)),
          (a) => R.assoc('overlap', Math.abs(a.combo || 0))(a),
          (a) => R.assoc('overlapMode', Math.sign(a.combo) || FUZZY_MODE)(a),
          R.assoc('dirty', false),
          R.evolve({
            // modify aspectpart
            aspectpart: R.compose(toObjById, R.map(renameProp('name', 'names'))),
          }),
        ),
      )

      const sortAspects = R.sortWith([
        R.descend(R.prop('sep')), // ...Separation Coefficient (greater is better)
        R.ascend(R.prop('group')), // ...by group (lower is better)
        R.ascend(R.prop('label')), // ...by label alphabetically
      ])

      // this is here only to prevent historically wrongly saved charts from throwing error

      const deselectUncomputedSavedAspects = (aspects) =>
        isStatView ? R.map((a) => R.assoc('selected', isAspectComputed(a) && a.selected)(a))(aspects) : aspects

      aspects = R.compose(deselectUncomputedSavedAspects, sortAspects, modifyAspects)(aspects)

      const preservePreviousProps = (newAspects) => (oldAspects) =>
        newAspects.map((aspect) => {
          const oldAspect = oldAspects.find(({ id }) => id === aspect.id)
          return oldAspect
            ? {
                ...aspect,
                tagLikeEntities: oldAspect.tagLikeEntities,
                selected: oldAspect.selected || wasRequested(state, oldAspect),
              }
            : aspect
        })

      const realStartRange = filteredDocs[filteredDocs.length - 1]?.unix_timestamp * 1000
      const realEndDate = filteredDocs[0]?.unix_timestamp * 1000

      return R.evolve({
        comparedDocuments: R.always(documents),
        previousAspects: preserveAspects ? preservePreviousProps(aspects) : R.always(aspects),
        compareStatsLoading: R.always(false),
        previousPeriod: R.always({
          prevRangeEnd: realEndDate,
          prevRangeStart: realStartRange,
        }),
      })(state)
    }

    case ActionTypes.FETCH_STATISTICS_SUCCESS: {
      let {
        response: {
          searchresult: {
            document: documents,
            aspectset: { aspect: aspects },
          },
        },
        preserveAspects /* eslint-disable-line prefer-const */,
        compareMode,
        aspectsRequestedFromState,
        timePeriod,
      } = payload

      const { isStatView, changedAspectsType, changedAspectsCountBy } = payload

      // Storing aspects, in case previous periods needs it.
      currentAspects = aspects

      const filtered = documents?.filter((document) => document.remove !== 'remove')

      documents = filtered

      documents = reduceDocumentsData(documents)

      // process aspectparts for Time widget
      timeWidgetAspectpartsNameChange(aspects, compareMode)

      // process documents:
      documents = (documents || []).map((doc) => ({
        ...doc,
        // convert timestamp to Date
        date: new Date(doc.unix_timestamp * 1000),
        // normalize fields by which can articles be summed
        count: 1,
        reach: doc.reach || 0,
        eac: doc.adprice || 0, // TODO: We might receive this prop, renamed from "adprice" to "eac". When that's done, change this.
        circulation: doc.circulation || 0,
        // append aspect directly to doc under its id
        ...mergeMultipleAspectParts(aspectsById(doc.aspects, aspects, compareMode), aspects, compareMode),
      }))

      // process aspects:
      const toObjById = R.indexBy(R.prop('id'))
      const renameProp = (from, to) => (a) => {
        a[to] = a[from]
        delete a[from]
        return a
      }

      const modifyAspects = R.map(
        R.compose(
          (a) => (isStatView ? a : R.assoc('selected', isAspectComputed(a) && isSepEnough(a))(a)),
          (a) => R.assoc('overlap', Math.abs(a.combo || 0))(a),
          (a) => R.assoc('overlapMode', Math.sign(a.combo) || FUZZY_MODE)(a),
          R.assoc('dirty', false),
          R.evolve({
            // modify aspectpart
            aspectpart: R.compose(toObjById, R.map(renameProp('name', 'names'))),
          }),
        ),
      )

      const sortAspects = R.sortWith([
        R.descend(R.prop('sep')), // ...Separation Coefficient (greater is better)
        R.ascend(R.prop('group')), // ...by group (lower is better)
        R.ascend(R.prop('label')), // ...by label alphabetically
      ])

      const computedAspectGroup = aspects.reduce(
        (group, aspect) =>
          /* eslint-disable-next-line no-bitwise */
          group | (isAspectComputed(aspect) ? aspect.group : 0),
        0,
      )

      // this is here only to prevent historically wrongly saved charts from throwing error

      const deselectUncomputedSavedAspects = (aspects) =>
        isStatView ? R.map((a) => R.assoc('selected', isAspectComputed(a) && a.selected)(a))(aspects) : aspects

      aspects = R.compose(deselectUncomputedSavedAspects, sortAspects, modifyAspects)(aspects)

      aspects.forEach((aspect) => {
        if (aspectsRequestedFromState?.includes(aspect.id)) {
          aspect.selected = true
        }
      })

      const preservePreviousProps = (newAspects) => (oldAspects) =>
        newAspects.map((aspect) => {
          const oldAspect = oldAspects.find(({ id }) => id === aspect.id)
          return oldAspect
            ? {
                ...aspect,
                tagLikeEntities: oldAspect.tagLikeEntities,
                selected: oldAspect.selected || wasRequested(state, oldAspect),
              }
            : aspect
        })

      const rangeStart =
        Object.keys(timePeriod).length > 0 ? timePeriod.oldest : payload.response.searchresult.rangeStart / 1000
      const rangeEnd =
        Object.keys(timePeriod).length > 0 ? timePeriod.newest : payload.response.searchresult.rangeEnd / 1000
      // Sorting documents by unix and Filtering away articles that's not within range.
      let filteredDocs = []
      if (documents) {
        filteredDocs = documents
          .sort((a, b) => b.unix_timestamp - a.unix_timestamp)
          .filter((document) => document.unix_timestamp >= rangeStart && document.unix_timestamp <= rangeEnd)
      }

      const correctStartDate = filteredDocs[filteredDocs?.length - 1]?.unix_timestamp * 1000
      const correctEndDate = filteredDocs[0]?.unix_timestamp * 1000

      // Default active widgets = Speaker, Media channel, Site name
      if (!isStatView) {
        aspects = aspects.map((aspect) => {
          if ([4, 19, 10].includes(aspect.id)) {
            return { ...aspect, selected: true }
          } else {
            return { ...aspect, selected: false }
          }
        })
      }

      return R.evolve({
        aspects: preserveAspects ? preservePreviousProps(aspects) : R.always(aspects),
        defaultAspects: R.always(aspects),
        documents: R.always(filteredDocs),
        changedAspectsType: R.always(changedAspectsType || {}),
        changedAspectsCountBy: R.always(changedAspectsCountBy || {}),
        /* eslint-disable-next-line no-bitwise */
        computedAspectGroup: (group) => group | computedAspectGroup,
        correctStatisticsDates: R.always({ correctStartDate, correctEndDate }),
        loading: R.always(false),
      })(state)
    }

    case ActionTypes.FETCH_STATISTICS_FAILURE: {
      return R.assoc('loading', false)(state)
    }

    case ActionTypes.STATISTICS_EXTEND_TIME_RANGE: {
      const { count, startDate } = payload
      return R.compose(R.assoc('extendedStartDate', startDate), R.assoc('extendedCount', count))(state)
    }

    case ActionTypes.STATISTICS_ASPECT_OVERLAP_CHANGE: {
      const { aspectId, overlap } = payload
      const aspectPos = state.aspects.indexOf(state.aspects.find((asp) => asp.id === aspectId))
      return R.compose(
        R.assocPath(['aspects', aspectPos, 'dirty'], true),
        R.assocPath(['aspects', aspectPos, 'overlap'], overlap),
      )(state)
    }

    case ActionTypes.STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE: {
      const { aspectId } = payload
      const aspectPos = state.aspects.indexOf(state.aspects.find((asp) => asp.id === aspectId))
      // toggle between exact and fuzzy mode
      const toggleMode = (mode) => (mode === EXACT_MODE ? FUZZY_MODE : EXACT_MODE)
      return R.compose(
        R.assocPath(['aspects', aspectPos, 'dirty'], true),
        R.over(R.lensPath(['aspects', aspectPos, 'overlapMode']), toggleMode),
      )(state)
    }

    case ActionTypes.STATISTICS_ASPECT_TOGGLE: {
      const { aspectId, selected } = payload
      return R.evolve({
        aspectsRequested: R.ifElse(() => selected, R.append(aspectId), R.without([aspectId])),
        aspects: R.map(R.when(R.propEq('id', aspectId), R.assoc('selected', selected))),
        previousAspects: R.map(R.when(R.propEq('id', aspectId), R.assoc('selected', selected))),
      })(state)
    }

    case ActionTypes.STATISTICS_CLEAR_REQUESTED_ASPECTS: {
      return R.evolve({
        aspectsRequested: R.always([]),
      })(state)
    }

    case ActionTypes.STATISTICS_CLEAR_EXTENDED_TIME_RANGE:
    case ActionTypes.SEARCH_CHANGE_DATERANGE: {
      return R.compose(R.dissoc('extendedStartDate'), R.dissoc('extendedCount'))(state)
    }

    // filter changed directly by some chart
    case ActionTypes.STATISTICS_FILTER_CHANGED: {
      const { id, filter } = payload

      return (R.isEmpty(filter) ? R.dissocPath(['filters', `${id}`]) : R.assocPath(['filters', id], R.clone(filter)))(
        state,
      )
    }

    case ActionTypes.STATISTICS_CLEAR_FILTERS: {
      return R.evolve({
        filters: R.always({}),
      })(state)
    }

    // filter resetting by reset link in FilterReset
    case ActionTypes.STATISTICS_FILTER_RESET: {
      const { id } = payload
      return R.dissocPath(['filters', `${id}`])(state)
    }

    // all filters resetting by reset filters button in command line
    case ActionTypes.STATISTICS_FILTER_RESET_ALL: {
      return R.evolve({
        filters: R.always({}),
      })(state)
    }

    case ActionTypes.STATISTICS_COUNT_BY_CHANGED: {
      const { by } = payload

      return R.assoc('countBy', by)(state)
    }

    /* case for special aspects (tags, sentiments, profiles and analytics) */
    case ActionTypes.STATISTICS_TAG_LISTS: {
      const { aspects } = state
      let { allTags, profiles, analysis, subqueries } = payload /* eslint-disable-line prefer-const */

      const tags = allTags.filter(({ type }) => type === TAG_TYPES.KEYWORD).map(toAspectTag)

      const sentiments = allTags.filter(({ type }) => type === TAG_TYPES.MENTOMETER).map(toAspectTag)

      profiles = profiles.map(toAspectTag)
      analysis = analysis.map(toAspectTag)

      // Preselect entities based on subqueries from fetch
      const checkIfSelected = (list) => {
        return list.map((item) => ({
          ...item,
          selected: subqueries?.some((sub) => sub.id === item.id),
        }))
      }

      const mergeListsById = (newList) => (oldList) =>
        R.values(
          R.merge(
            R.indexBy(R.prop('id'))(newList),
            // old should replace new, because previously selected
            // tags should remain selected
            R.indexBy(R.prop('id'))(oldList),
          ),
        )

      const getTagLikeEntitiesByAspectId = (aspectId) =>
        ({
          [SPECIAL_ASPECTS_IDS.TAG]: tags,
          [SPECIAL_ASPECTS_IDS.PROFILE]: profiles,
          [SPECIAL_ASPECTS_IDS.SENTIMENT]: sentiments,
          [SPECIAL_ASPECTS_IDS.ANALYSIS]: analysis,
        }[aspectId])

      const filterAspects = R.filter((aspect) => {
        switch (aspect.id) {
          case SPECIAL_ASPECTS_IDS.TAG:
            return tags.length > 0
          case SPECIAL_ASPECTS_IDS.SENTIMENT:
            return sentiments.length > 0
          case SPECIAL_ASPECTS_IDS.PROFILE:
            return profiles.length > 0
          case SPECIAL_ASPECTS_IDS.ANALYSIS:
            return analysis.length > 0
          default:
            return true
        }
      })

      aspects.forEach((aspect, i) => {
        // TODO refactor
        if (R.values(SPECIAL_ASPECTS_IDS).includes(aspect.id)) {
          if (aspect.tagLikeEntities) {
            aspects[i] = R.evolve({
              tagLikeEntities: R.compose(mergeListsById, checkIfSelected, getTagLikeEntitiesByAspectId)(aspect.id),
            })(aspect)
          } else {
            aspect.tagLikeEntities = R.compose(checkIfSelected, getTagLikeEntitiesByAspectId)(aspect.id)
          }
        }
      })
      return R.evolve({
        // TODO only clone four special aspect not all of them
        aspects: R.always(R.clone(filterAspects(aspects))),
      })(state)
    }

    case ActionTypes.STATISTICS_SET_INITIAL_VALUES: {
      return R.evolve({
        initialAspects: R.ifElse(R.length(), R.always(state.initialAspects), R.always(state.aspects)),
        initialAspectsType: R.ifElse(
          R.isEmpty(),
          R.always(state.changedAspectsType),
          R.always(state.initialAspectsType),
        ),
        initialAspectsCountBy: R.ifElse(
          R.isEmpty(),
          R.always(state.changedAspectsCountBy),
          R.always(state.initialAspectsCountBy),
        ),
        initialValuesSet: R.always(true),
      })(state)
    }

    case ActionTypes.STATISTICS_ASPECT_TAG_TOGGLED: {
      const { aspectId, tagId } = payload
      const { aspects } = state

      const aspect = aspects.find(({ id }) => id === aspectId)
      const tag = aspect.tagLikeEntities.find(({ id }) => id === tagId)

      tag.selected = !tag.selected

      const aspectIndex = R.indexOf(aspect)(aspects)

      return R.compose(
        R.assocPath(['aspects', aspectIndex, 'tagLikeEntities'], R.clone(aspect.tagLikeEntities)),
        R.assocPath(['aspects', aspectIndex, 'dirty'], true),
      )(state)
    }

    case ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS:
      return R.assoc('list', payload, state)

    case ActionTypes.STATISTICS_VIEWS_SET_ACTIVE: {
      const { id } = payload
      return R.evolve({
        activeStatView: R.always(id),
        initialAspects: R.always([]),
        initialAspectsType: R.always({}),
        initialAspectsCountBy: R.always({}),
        initialValuesSet: R.always(false),
      })(state)
    }

    case ActionTypes.STATISTICS_VIEWS_OPEN:
      return R.evolve({
        loading: R.always(true),
      })(state)

    case ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS: {
      return R.evolve({
        list: R.always(state.list.filter((item) => item.id !== payload.id)),
      })(state)
    }

    case ActionTypes.STATISTICS_UPDATE_FILTERED_ARTICLES: {
      const { articles } = payload
      return R.evolve({
        filteredArticles: R.always(articles),
      })(state)
    }

    case ActionTypes.STATISTICS_CLEAR_FILTERED_ARTICLES: {
      return R.evolve({
        filteredArticles: R.always([]),
      })(state)
    }

    case ActionTypes.STATISTICS_UPDATE_FILTER_TYPE: {
      const { type, name } = payload
      return R.assocPath(['changedAspectsType', name], type)(state)
    }

    case ActionTypes.STATISTICS_DEFAULT_ASPECT_TYPE: {
      const { type, name } = payload
      return R.assocPath(['defaultAspectsType', name], type)(state)
    }

    case ActionTypes.STATISTICS_UPDATE_FILTER_COUNTBY: {
      const { countBy, graphName } = payload
      return R.assocPath(['changedAspectsCountBy', graphName], countBy)(state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_TRIGGER: {
      return R.compose(R.assoc('exportPDFtrigger', true), R.assoc('exportPDFloading', true))(state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS:
    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE: {
      return R.assoc('exportPDFloading', false, state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_RESET_TRIGGER: {
      return R.assoc('exportPDFtrigger', false, state)
    }

    case ActionTypes.STATISTICS_VIEW_RENAMING: {
      const isRenaming = payload === 'rename'
      return R.assoc('openRenamingPopup', isRenaming, state)
    }

    case ActionTypes.GO_TO_STATISTICS_COMPARISON: {
      return R.assoc('statisticsComparisonOpen', true, state)
    }

    case ActionTypes.CLOSE_STATISTICS_COMPARISON: {
      return R.assoc('statisticsComparisonOpen', false, state)
    }

    case ActionTypes.TOGGLE_PREVIOUS_PERIOD_DATE_PICKER: {
      const {
        previousPeriod: { prevRangeEnd, prevRangeStart },
      } = state

      if (payload && !isNaN(prevRangeEnd) && !isNaN(prevRangeStart)) {
        return R.compose(
          R.assocPath(['previousPeriodDates', 'endDate'], dayjs(prevRangeEnd, 'x').toISOString()),
          R.assocPath(['previousPeriodDates', 'startDate'], dayjs(prevRangeStart, 'x').toISOString()),
          R.assoc('previousPeriodDatePicker', payload),
        )(state)
      } else {
        return R.assoc('previousPeriodDatePicker', payload, state)
      }
    }

    case ActionTypes.PREVIOUS_PERIOD_DATE_PICKER_START_DATE: {
      return R.assocPath(['previousPeriodDates', 'startDate'], payload, state)
    }

    case ActionTypes.PREVIOUS_PERIOD_DATE_PICKER_END_DATE: {
      return R.assocPath(['previousPeriodDates', 'endDate'], payload, state)
    }

    case ActionTypes.CHANGE_COMPARE_PERIOD: {
      return R.assoc('comparePeriod', payload, state)
    }

    default:
      return state
  }

  function toAspectTag(aspect) {
    return R.pick(['id', 'name', 'selected'])(aspect)
  }

  function isAspectComputed(aspect) {
    return aspect.comp_part > -1
  }
  function isSepEnough(aspect) {
    return aspect.sep > 30
  }

  function wasRequested(state, aspect) {
    return state.aspectsRequested.includes(aspect.id)
  }
}

export default statisticsReducer
