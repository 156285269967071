import R from 'ramda'

import { filterIsChart } from './index'
import type { Filter, Searchline, routingQuery } from '../flow'

export function filtersToURLParam(filters: Array<Filter>): string {
  return filters.map(({ id, type }) => `${type}${id ? `:${id}` : ''}`).join(';')
}

/**
 * Translates searchdata into URL params string
 * @param searchterm
 * @param filters
 * @param timestamp
 * @returns {string}
 */
export function searchDataToURLParam({ searchterm, filters }: Searchline, timestamp: number) {
  const paramsArray = [
    searchterm ? `expression=${encodeURIComponent(searchterm)}` : '',
    filters && filters.length ? `filters=${filtersToURLParam(filters)}` : '',
    `timestamp=${timestamp}`,
  ].filter((x) => x !== '')
  return `?${paramsArray.join('&')}`
}

export function parseFiltersFromURLParam(filtersString?: string): Array<Filter> {
  return filtersString
    ? filtersString
        .split(';')
        .map((x) => x.split(':'))
        .map(([type, id]) => ({ id, type }))
    : []
}

export function profileIdFromLocationQuery(locationQueryFiltersParam: string): number {
  if (!locationQueryFiltersParam) {
    return null
  }
  const [filter] = parseFiltersFromURLParam(locationQueryFiltersParam)
  return +filter.id
}

export const chartAndNonChartFiltersFromURL = (
  filtersParam: string,
): { nonChartFilters: Array<Filter>; chartFilter: Filter | void } => {
  const parsedFilters: Array<Filter> = parseFiltersFromURLParam(filtersParam)
  const chartFilter = parsedFilters.find(filterIsChart)
  return { nonChartFilters: R.without([chartFilter], parsedFilters), chartFilter }
}

export const paramsDictToUrl: (query: routingQuery) => string = R.compose(
  R.join(''),
  R.intersperse('&'),
  R.map(R.join('=')),
  R.toPairs,
)
