import Rx from 'rx-dom'
import R from 'ramda'

import config from '../common/config'
import type { ProfileGroup } from '../flow'

/**
 * Retrieves groups from API and transforms them into a Promise.
 * @returns {*}
 */
export function getProfileGroups(): Promise<Array<ProfileGroup>> {
  // Query on how to fetch specific type and if the groups are owned or not
  // /profile-groups/?type=0&owner=true

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/profile-groups/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function createNewGroup(newGroup) {
  const method = 'POST'
  // Handle shares
  const shares = []

  const body = {
    name: newGroup.name,
    type: newGroup.type,
    shares: [],
  }

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    method,
    url: config.url.api('/profile-groups/'),
    body: JSON.stringify(body),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function updateGroup(existingGroup, updates) {
  const group = existingGroup.group
  const type = existingGroup.type
  const owner = existingGroup.owner
  const method = 'PATCH'

  const body = updates

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    method,
    url: config.url.api(`/profile-groups/${group}/${type}/${owner}/`),
    body: JSON.stringify(body),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getSpecificGroup(existingGroup) {
  const group = existingGroup.group
  const type = existingGroup.type
  const owner = existingGroup.owner

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/profile-groups/${group}/${type}/${owner}/`),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
