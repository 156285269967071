import Rx from 'rx-dom'
import R from 'ramda'

import { SUPPORTED_LOCALES } from '../common/constants'
import config from '../common/config'
import type { Setting } from '../flow'
import type { MODULES, MODULE_OPTIONS } from '../../new-components/types/permissions'
import i18n from '../../i18nextInit'

export const LISTING_STYLES = {
  MODERN: 0,
  SEARCH_ENGINE: 1,
  EMAIL: 2,
  ARCHIVE_RIGHT: 3,
  ARCHIVE_LEFT: 4,
  INTRO: 5,
}

// TIme offset for relative times in seconds
// BEWARN - IF YOU MODIFY THIS, YOU ALSO NEED TO MODIFY STRING IN TRANSLATIONS.JS
export const TIME_OFFSET = {
  NEVER: {
    sec: 0,
    label: i18n.t('Never'),
  },
  LESS_THAN_15_MINUTES: {
    sec: 900,
    label: i18n.t('Age of article is less than 15 minutes'),
  },
  LESS_THAN_1_HOURS: {
    sec: 3600,
    label: i18n.t('Age of article is less than 1 hour'),
  },
  LESS_THAN_6_HOURS: {
    sec: 21600,
    label: i18n.t('Age of article is less than 6 hours'),
  },
  LESS_THAN_24_HOURS: {
    sec: 86400,
    label: i18n.t('Age of article is less than 24 hours'),
  },
  LESS_THAN_A_WEEK: {
    sec: 604800,
    label: i18n.t('Age of article is less than a week'),
  },
}

export const MODULE_OPTION: MODULE_OPTIONS = {
  OFF: 'A',
  OFF_FOR_USER: 'B',
  READ_ONLY: 'C',
  ON: 'D',
}

export const ATTRIBUTE_OPTION = {
  OFF: 0,
  ON: 1,
}

export const MODULE: MODULES = {
  SOURCE_LIST: 'SOURCE_LIST_MODULE',
  FEED: 'FEED_MODULE',
  ALERT: 'ALERT_MODULE',
  SETTING: 'SETTING_MODULE',
  REPORT: 'REPORT_MODULE',
  STATISTICS: 'STATISTICS_MODULE',
  TRASH: 'TRASH_MODULE',
  CREATE_ARTICLE: 'CREATE_ARTICLE_MODULE',
  PROFILE: 'PROFILE_MODULE',
  SIMILAR_SETTING_VISIBLE: 'SIMILAR_SETTING_VISIBLE',
  TAG: 'TAG_MODULE',
  CONTACT: 'CONTACT_MODULE',
  SHOW_COPYRIGHT_DISCLAIMER: 'SHOW_COPYRIGHT_DISCLAIMER',
  STATISTICS_ENABLE_EAC_AND_CIRCULATION: 'STATISTICS_ENABLE_EAC_AND_CIRCULATION',
  ENABLE_TEXT_SMS_ALERTS: 'ENABLE_TEXT_SMS_ALERTS',
  LIMITED_ADD_CONTENT: 'LIMITED_ADD_CONTENT',
  ENABLE_REACH_ON_ARTICLES: 'ENABLE_REACH_ON_ARTICLES',
}

const normalizeSettingValues = R.map(({ name, value, choices }) => {
  let settingValue = value
  if (name === 'LANGUAGE' && !SUPPORTED_LOCALES.includes(settingValue)) {
    settingValue = 'en-GB' /* eslint-disable-line no-param-reassign */
  }

  // due to original mistake in Chinese locale
  if (settingValue === 'zh_CN') {
    settingValue = 'zh-CN'
  }

  if (name === 'MAX_GT_ARTICLE_LENGTH') {
    settingValue = Number(settingValue) /* eslint-disable-line no-param-reassign */
  }
  return { name, value: settingValue, choices }
})

export function getSettings() {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/settings/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => normalizeSettingValues(response))
}

export function saveSettings(settings: Array<Setting>) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/settings/'),
    method: 'POST',
    body: JSON.stringify(normalizeSettingValues(settings)),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
