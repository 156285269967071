import { changeImageUrlBase } from './common'
import { useTranslation } from '../hooks/useTranslation'
import { SCREENSHOT_TYPES } from '../../opoint/common/constants'
import type { Article, ArticleScreenshot, ColorName } from '../types/article'
import type { IconName } from '../types/theme'

export const articleWithCorrectedImagePaths = (article: Article) => ({
  ...article,
  screenshots: article.screenshots?.map((screenshot: ArticleScreenshot) => ({
    ...screenshot,
    text: changeImageUrlBase(screenshot.text),
  })),
})

export const getMediatypeName = (mediatype: string, sitename?: string): string => {
  const { i18n } = useTranslation()

  switch (mediatype) {
    case 'SOCIAL':
      return sitename
    case 'RADIO':
    case 'WEB_RADIO':
      return i18n.t('Radio')
    case 'TV':
    case 'WEB_TV':
      return i18n.t('TV')
    case 'PRINT':
      return i18n.t('Print')
    default:
      return i18n.t('Web')
  }
}

export const getMediaIconName = (mediatype: string, sitename?: string): IconName => {
  const getSocialType = (site: string) => {
    switch (site) {
      case 'Twitter':
        return 'twitter_small'
      case 'Facebook':
        return 'facebook_small'
      case 'Instagram':
        return 'instagram_small'
      default:
        return 'browser_small'
    }
  }
  switch (mediatype) {
    case 'SOCIAL':
      return getSocialType(sitename)
    case 'RADIO':
    case 'WEB_RADIO':
      return 'radio_small'
    case 'TV':
    case 'WEB_TV':
      return 'tv_small'
    case 'PRINT':
      return 'print_small'
    case 'SUMMARY':
      return 'web_small'
    default:
      return 'browser_small'
  }
}

export const giveTagsColor = (tagType: number, color: ColorName) => {
  switch (tagType) {
    case 3:
      return 'blue'
    case 0:
      return 'red'
    case 4:
      return 'red'
    default:
      return color
  }
}

export const getScreenshotThumbnail = (screenshots) =>
  screenshots.find(({ id_type: idType }) => idType === SCREENSHOT_TYPES.THUMBNAIL)
