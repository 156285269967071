// @ts-nocheck
import Rx from 'rxjs'
import * as R from 'ramda'

import buildAction from '../helpers/buildAction'
import OpointDate from '../opoint/common/time'
import { getSettings, saveSettings } from '../opoint/settings/index'
import { getGroupingEnabled, getUISettings } from '../selectors/settingsSelectors'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import * as ActionTypes from '../constants/actionTypes'
import { hasNumberOfProfilesAndTagsReachedThreshold } from '../selectors/profilesSelectors'

export const fetchSettingsEpic = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).switchMap(() =>
    Rx.Observable.fromPromise(getSettings()).map((settings) => {
      const language = settings.find((setting) => setting.name === 'LANGUAGE').value
      OpointDate.setLanguage(language)
      return buildAction(ActionTypes.SETTINGS_FETCH_SUCCESS, settings)
    }),
  )

const onToggleUIPrepEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.TOGGLE_UI_SETTING_PREP).switchMap(({ payload }) => {
    const hasReachedThreshold = hasNumberOfProfilesAndTagsReachedThreshold(getState())

    return Rx.Observable.of(buildAction(ActionTypes.TOGGLE_UI_SETTING, { setting: payload, hasReachedThreshold }))
  })

export const toggleUIEpic = (action$: any, { getState }: any) =>
  action$
    .filter(({ type }) => [ActionTypes.TOGGLE_UI_SETTING, ActionTypes.TRASH_TOGGLE_VISIBILITY].includes(type))
    .mergeMap(() => {
      const rawSettings = getUISettings(getState())
      const settings = {}
      settings.APP_UI_SETTINGS = rawSettings
      return Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings, toggleSetting: true }))
    })

export const settingsSaveEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SETTINGS_SAVE).switchMap(({ payload }) => {
    const { settings, toggleSetting } = payload

    if (settings.COLORBAR_COLORS) {
      settings.COLORBAR_COLORS = +settings.COLORBAR_COLORS
    }

    // since we are storing one settings value in APP_UI_SETTINGS we have to merge it with old
    // APP_UI_SETTINGS in order to avoid overriding them and thus deleting by accident
    const uiSettings = getUISettings(getState())
    const fullSettings = R.mergeDeepRight({ APP_UI_SETTINGS: uiSettings }, settings)

    const settingsEntries = Object.entries(fullSettings).map(([name, value]) => ({ name, value }))

    if (!settingsEntries.length) {
      return Rx.Observable.of()
    }

    return Rx.Observable.fromPromise(saveSettings(settingsEntries))
      .map((savedSettings) =>
        buildAction(ActionTypes.SETTINGS_SAVE_SUCCESS, {
          // This needs to be done here, because in saving case, backend returns us same format
          // used for saving which results in stringified data in store => crashes application
          settings: R.map((savedSetting) => {
            if (savedSetting.name === 'APP_DEFAULT_HOME') {
              return {
                name: savedSetting.name,
                value: JSON.parse(savedSetting.value),
              }
            }
            return savedSetting
          })(savedSettings),
          toggleSetting,
        }),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => buildAction(ActionTypes.SETTINGS_SAVE_FAILURE))
  })

export const filtersOrderEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.FILTERS_REORDER).mergeMap(() => {
    const rawSettings = getUISettings(getState())
    const settings = {}
    settings.APP_UI_SETTINGS = rawSettings
    return Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings }))
  })

const onGroupingToggleEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SETTINGS_TOGGLE_GROUPING).switchMap(() => {
    const enableGrouping = !getGroupingEnabled(getState())

    return Rx.Observable.concat(
      Rx.Observable.of(buildAction(ActionTypes.SETTINGS_SAVE, { settings: { IDENTICAL: enableGrouping } })),
    )
  })

export default [
  fetchSettingsEpic,
  filtersOrderEpic,
  onGroupingToggleEpic,
  settingsSaveEpic,
  toggleUIEpic,
  onToggleUIPrepEpic,
]
