import R from 'ramda'
import { createSelector, Selector } from 'reselect'

import { getAlerts, getForm, getProfiles, getTags } from './rootSelectors'
import { toAPITimeFormat } from '../opoint/alerts/apiTime'
import { getOpointLocale } from './settingsSelectors'
import { locales } from '../opoint/common/constants'
import { Alert } from '../opoint/flow'

export const getAlertsList = createSelector(getAlerts, (alertsState) => alertsState.list)

export const getAlertsDetails = createSelector(getAlerts, (alertsState) => alertsState.details)

export const getAlertsNext = createSelector(getAlerts, (alertsState) => alertsState.next)

export const getAlertDetail = (id: number) => createSelector(getAlertsDetails, (alerts) => alerts[id])

export const getActiveAlerts: Selector<any, Alert[]> = createSelector<any, Alert[], any>(
  getAlertsList,
  // @ts-ignore
  R.filter((alert) => alert.active),
)

export const getInactiveAlerts: Selector<any, Alert[]> = createSelector<any, Alert[], any>(
  getAlertsList,
  // @ts-ignore
  R.filter((alert) => !alert.active),
)

export const getAlertsCount = createSelector(getAlertsList, R.length)

export const getSaveFormData = createSelector(getForm, (alertsState) =>
  alertsState.alert.add ? alertsState.alert.add.values : alertsState.alert.edit.values,
)

export const getAddFormData = createSelector(getForm, (alertsState) => alertsState.alert && alertsState.alert.add)

export const getAddFormTranslate = createSelector(getAddFormData, (formAdd) => formAdd && formAdd.values.translate)

export const getEditFormData = createSelector(getForm, (alertsState) => alertsState.alert && alertsState.alert.edit)

export const getSpecialFormData = createSelector(getAlerts, (alertsState) => alertsState.editedAlert)

export const getAlertTimeConfiguration = createSelector(
  getSpecialFormData,
  (editedAlert) => editedAlert && editedAlert.schedule && editedAlert.schedule.timeConfiguration,
)

export const getNewAlert = createSelector(
  getSpecialFormData,
  getSaveFormData,
  getOpointLocale,
  (specialFormData, formData, locale) => {
    const apiTimeConfiguration = toAPITimeFormat(specialFormData.schedule.timeConfiguration)
    const localeValues = Object.values(locales)
    const languageLocale = localeValues.find(R.propEq('englishName', formData.language))
    const language = languageLocale ? languageLocale.alertsLocale : undefined

    const scheduleData = {
      schedule: {
        timeZone: formData.timeZone || formData.schedule.timeZone,
        country: 'GB_EN', // https://projects.m-brain.com/issues/13320
        timeConfiguration: apiTimeConfiguration,
      },
      locale: language,
      type: 4,
      templateId: formData.template.id,
    }
    const correctFormData = R.omit(['timezone', 'time', 'template', 'schedule', 'locale'], formData)
    const finalData = R.merge(scheduleData, correctFormData)
    return finalData
  },
)

export const getSelectedDays = createSelector(
  getAlerts,
  (alertsState) => alertsState.editedAlert.schedule.timeConfiguration,
)

export const getAlertRecipients = (alertId: number) =>
  createSelector(getAlertDetail(alertId), (alert) => alert.recipients)

export const getAlertRecipientsNames = (alertId: number) =>
  createSelector(
    getAlertDetail(alertId),
    R.compose(
      R.filter(R.compose(R.not, R.isNil)),
      R.map((recipient) =>
        // @ts-ignore
        R.propEq('type', 'person', recipient) || R.propEq('type', 'group', recipient)
          ? // @ts-ignore
            recipient.name
          : // @ts-ignore
            recipient.value,
      ),
      (alert) => R.union(alert.smsRecipients, alert.recipients),
    ),
  )

export const getEditedAlert = createSelector(getAlerts, (alerts) => alerts.activeAlert)

// String in shape "Profiles: Profile1, Profile2; Tags: Tag1, Tag2"
export const getEditedAlertContentNamesString = createSelector(
  getEditedAlert,
  getProfiles,
  getTags,
  (editedAlert, profiles, tags) => {
    const pickedProfiles = []
    const pickedTags = []

    profiles &&
      profiles.list.forEach((profile) => {
        if ((editedAlert && editedAlert.mifluzIdLists.indexOf(profile.id)) !== -1) {
          pickedProfiles.push(profile.name)
          // May be remove item from editedAlert.mifluzIdLists
        }
      })
    tags &&
      tags.list.forEach((tag) => {
        if ((editedAlert && editedAlert.mifluzIdLists.indexOf(tag.id)) !== -1) {
          pickedTags.push(tag.name)
        }
      })

    const profilesString = pickedProfiles.length > 0 ? `Profiles: ${pickedProfiles.join(', ')}` : ''
    const tagString = pickedTags.length > 0 ? `Tags: ${pickedTags.join(', ')}` : ''
    return profilesString + (profilesString !== '' && tagString !== '' ? '; ' : '') + tagString
  },
)

export const getAlertHistoryItems = createSelector(getAlerts, (alerts) => alerts.historyItems)

export const getAlertHistoryLoading = createSelector(getAlerts, (alerts) => alerts.historyLoading)

export const getCurrentAlertHistoryItem = createSelector(getAlerts, (alerts) => alerts.currentHistoryItem)

export const getAlertHistoryById = (alertId: number) => createSelector(getAlerts, (alerts) => alerts.history[alertId])

export const getAlertsRequestPending = createSelector(getAlerts, (alerts) => alerts.requestPending)

export const getAlertsNextPreview = (id: number) => createSelector(getAlerts, (alerts) => alerts.nextPreview[id])

export const getAlertArticles = createSelector(getAlerts, (alerts) => alerts.articlesList)

export const getTrashedArticles = createSelector(getAlerts, (alerts) => alerts.trashedArticles)

export const getAlertTags = createSelector(getAlerts, (alerts) => alerts.tags)

export const getAllAlertTags = createSelector(getAlertTags, (alerts) =>
  // @ts-ignore
  R.flatten(R.concat(alerts, R.pluck('children', alerts))),
)

export const getDeleteAlertInProgress = createSelector(getAlerts, (alerts) => alerts.deleteInProgress)

// for each alert pick all alert's contact info and
// put together in prop allRecipientsContacts for usable serching
export const getByRecipientsFilterableAlerts = createSelector(getAlerts, (alerts) =>
  alerts.alertsRecipients.map((alert) =>
    R.assoc('allRecipientsContacts', R.uniq(R.flatten(R.map(R.values, R.flatten(R.values(alert.recipients))))))(alert),
  ),
)

export const getAlertTemplatePreview = createSelector(getAlerts, (alerts) => alerts.template && alerts.template.preview)

export const isAutoTranslateReport = createSelector(
  getForm,
  (formState) => !!(formState.alert && formState.alert.edit.values.translate),
)

export const getAlertFormAddValueTime = createSelector(getAddFormData, (formAlertAddState) =>
  formAlertAddState ? formAlertAddState.values.time : null,
)

export const getTotalArticlesCount = (alertId) =>
  createSelector(getAlerts, (alertsState) => R.path(['next', alertId, 'count'], alertsState))

export const isLoading = createSelector(getAlerts, (alertsState) => alertsState.loading)
