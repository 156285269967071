import { ArticleMetadataType } from '../types/article'
import { FilterType, FilterItem } from '../types/settings'
import { ARTICLE_METADATA_CONFIG } from '../articles/Article/common/ArticleMetadata/ArticleMetadataBox'
import i18n from '../../i18nextInit'
import config from '../../opoint/common/config'

export const IMAGE_URL_BASE = {
  old: 'http://pictures.opoint.com/',
  new: config.url.app('/pictures/'),
}

export const PAPER_URL = 'paper.opoint.com'

export const allMetadataOptions: Array<{
  name: string
  value: ArticleMetadataType
}> = Object.entries(ARTICLE_METADATA_CONFIG).map(([value, { title }]) => ({
  value: value as ArticleMetadataType,
  name: title(),
}))

export const allFilterOptions: FilterItem[] = [
  { value: 'timeperiod', name: i18n.t('Time Period') },
  { value: 'site', name: i18n.t('Site') },
  { value: 'rank', name: i18n.t('Global Rank') },
  { value: 'geo', name: i18n.t('Geo') },
  { value: 'lang', name: i18n.t('Lang') },
  { value: 'cov', name: i18n.t('Coverage') },
  { value: 'media', name: i18n.t('Media') },
  { value: 'content', name: i18n.t('Content') },
  { value: 'profiles', name: i18n.t('My Briefcase') },
  { value: 'tags', name: i18n.t('My Tags') },
  { value: 'ent', name: i18n.t('Entities') },
  { value: 'topic', name: i18n.t('Topic') },
  { value: 'ecb_rank', name: i18n.t('ECB Rank') },
  { value: 'ecb_voice', name: i18n.t('ECB Voices') },
  { value: 'ecb_topic', name: i18n.t('ECB Topics') },
]

export const FILTERS_ORDER_DEFAULT: Array<FilterType> = [
  'timeperiod',
  'geo',
  'lang',
  'media',
  'content',
  'site',
  'rank',
  'cov',
  'profiles',
  'tags',
  'ent',
  'topic',
]

export const autoTranslateLanguages = [
  { en: i18n.t('English') },
  { de: i18n.t('German') },
  { fr: i18n.t('French') },
  { es: i18n.t('Spanish') },
  { it: i18n.t('Italian') },
  { no: i18n.t('Norwegian') },
  { sv: i18n.t('Swedish') },
  { da: i18n.t('Danish') },
  { fi: i18n.t('Finnish') },
  { zh: i18n.t('Chinese') },
  { ru: i18n.t('Russian') },
  { tr: i18n.t('Turkish') },
  { ko: i18n.t('Korean') },
  { ar: i18n.t('Arabic') },
  { pt: i18n.t('Portuguese') },
  { el: i18n.t('Greek') },
  { ja: i18n.t('Japanese') },
  { vi: i18n.t('Vietnamese') },
  { id: i18n.t('Indonesian') },
  { pl: i18n.t('Polish') },
  { nl: i18n.t('Dutch') },
  { hi: i18n.t('Hindi') },
  { ro: i18n.t('Romanian') },
  { bs: i18n.t('Bosnian') },
  { fa: i18n.t('Persian') },
  { th: i18n.t('Thai') },
  { sq: i18n.t('Albanian') },
  { hr: i18n.t('Croatian') },
  { cs: i18n.t('Czech') },
  { hu: i18n.t('Hungarian') },
  { bg: i18n.t('Bulgarian') },
  { uk: i18n.t('Ukrainian') },
  { sk: i18n.t('Slovak') },
  { mk: i18n.t('Macedonian') },
  { sl: i18n.t('Slovene') },
  { bn: i18n.t('Bengali') },
  { te: i18n.t('Telugu') },
  { lt: i18n.t('Lithuanian') },
  { ms: i18n.t('Malay') },
  { ca: i18n.t('Catalan') },
  { ml: i18n.t('Malayalam') },
  { ta: i18n.t('Tamil') },
  { lv: i18n.t('Latvian') },
  { az: i18n.t('Azerbaijani') },
  { et: i18n.t('Estonian') },
  { pa: i18n.t('Punjabi') },
  { ur: i18n.t('Urdu') },
  { ka: i18n.t('Georgian') },
  { gu: i18n.t('Gujarati') },
  { kn: i18n.t('Kannada') },
  { am: i18n.t('Amharic') },
  { gl: i18n.t('Galician') },
  { hy: i18n.t('Armenian') },
  { sr: i18n.t('Serbian') },
  { is: i18n.t('Icelandic') },
  { kk: i18n.t('Kazakh') },
  { eu: i18n.t('Basque') },
  { af: i18n.t('Afrikaans') },
  { so: i18n.t('Somali') },
  { mt: i18n.t('Maltese') },
  { sw: i18n.t('Swahili') },
  { mr: i18n.t('Marathi') },
  { uz: i18n.t('Uzbek') },
  { si: i18n.t('Sinhalese') },
  { tg: i18n.t('Tajik') },
  { cy: i18n.t('Welsh') },
  { my: i18n.t('Burmese') },
  { km: i18n.t('Khmer') },
  { tl: i18n.t('Tagalog') },
  { fy: i18n.t('Western Frisian') },
  { sm: i18n.t('Samisk') },
  { eo: i18n.t('Esperanto') },
  { mn: i18n.t('Mongolian') },
  { ha: i18n.t('Hausa') },
  { ga: i18n.t('Irish') },
  { be: i18n.t('Belarusian') },
  { ny: i18n.t('Chichewa') },
  { ps: i18n.t('Pashto') },
  { st: i18n.t('Southern Sotho') },
  { ht: i18n.t('Haitian') },
  { ky: i18n.t('Kirghiz') },
  { lb: i18n.t('Luxembourgish') },
  { lo: i18n.t('Lao') },
]
