import R from 'ramda'
import Rx from 'rx-dom'
import { saveAs } from 'file-saver'

import config from '../common/config'

export const apiToLocaleTypes = {
  pie: { type: 'pieChart', apiType: 'pie' },
  row: { type: 'rowChart', apiType: 'row' },
  widepie: { type: 'pieChart', apiType: 'widepie', isWide: true },
  freqtable: { type: 'customDataTable', apiType: 'freqtable' },
  seriesline: {
    type: 'lineChart',
    apiType: 'seriesline',
    series: 'overlapped',
    isWide: true,
  },
  seriesarea: {
    type: 'lineChart',
    apiType: 'seriesarea',
    series: 'stacked',
    isWide: true,
  },
  seriesbar: {
    type: 'barChart',
    apiType: 'seriesbar',
    series: 'stacked',
    isWide: true,
  },
  timetable: {
    type: 'customDataTable',
    apiType: 'timetable',
    series: 'overlapped',
    isWide: true,
  },
}

export const defaultWidgetTypes = (aspectId) => {
  switch (aspectId) {
    // Speaker
    case 4:
    // Site rank
    case 15:
    // Day of week
    case 38:
    // Person name
    case 44:
      return apiToLocaleTypes['row']
    // Site name
    case 10:
    // Time
    case 39:
    // County
    case 3:
    // Story
    case 40:
      return apiToLocaleTypes['freqtable']
    default:
      return apiToLocaleTypes['pie']
  }
}

export const reduceSumName = {
  count: 'n. of articles',
  circulation: 'circulation',
  reach: 'reach',
  eac: 'eac',
}

export function getStatisticViews() {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/statistics/'),
    method: 'GET',
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getStatisticViewData(id: number | string) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/statistics/${id}/`),
    method: 'GET',
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function deleteStatisticsView(statViewId: string) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/statistics/${statViewId}/`),
    method: 'DELETE',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function saveStatisticView(statView, id) {
  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: id ? config.url.api(`/statistics/${id}/`) : config.url.api('/statistics/'),
    method: id ? 'PATCH' : 'POST',
    body: JSON.stringify(statView),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const exportPDF = ({
  activeStatisticsViewName,
  metaString,
  description,
  title,
  fileName,
  SVGs,
  type,
  periodChosen,
}) => {
  //TODO: Changed to '/export' in the pdf microservice
  const url = config.url.app('/export-statistics/')

  // dev url:
  // const url = 'http://localhost:3442/export/'

  return fetch(url, {
    headers: {
      accept: 'application/pdf',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
      'accept-charset': 'utf-8',
    },
    // @ts-ignore
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      type: type,
      SVGsArray: SVGs,
      docTitle: title,
      docDescription: description,
      exportInfo: {
        metaString,
        statisticsViewName: activeStatisticsViewName || '',
        periodChosen,
        // TODO
        // statisticsProfiles: 'USA + Trump',
      },
    }),
  }).then((res) => {
    if (res.ok) {
      res.blob().then((PDFdocument) => saveAs(PDFdocument, fileName || 'statistics-export-pdf.pdf'))
    }

    return activeStatisticsViewName
  })
}
