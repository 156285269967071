type Instance = {
  timestamp?: number
  setTime: (timestamp: number) => void
  getTime?: () => number
}

class SecreServerTime {
  static instance: Object
  timestamp?: number
  constructor() {
    if (!SecreServerTime.instance) {
      this.timestamp = undefined
      SecreServerTime.instance = this
    }

    // @ts-ignore
    return SecreServerTime.instance
  }

  setTime = (timestamp) => {
    this.timestamp = timestamp
  }

  getTime = () => this.timestamp
}

// @ts-ignore
const secretServerTime: Instance = new SecreServerTime()

export default secretServerTime
