// @ts-nocheck

import R from 'ramda'

import { indexArray } from '../opoint/common'
import { toggleTagVisibility } from '../opoint/tags'
import * as Actions from '../constants/actionTypes'

export const initialState = {
  list: [],
  lastUsedTagId: null,
}

const tagsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.TAGS_FETCH_SUCCESS: {
      return R.assoc('list', indexArray(payload), state)
    }

    case Actions.TAG_VISIBILITY_TOGGLE: {
      const { tagId } = payload
      const lens = R.lensPath(['list', R.findIndex(R.propEq('id', tagId), state.list)])

      return R.over(lens, toggleTagVisibility, state)
    }

    // TODO write tests for this
    case Actions.SET_LAST_USED_TAG_ID:
    case Actions.TAG_SINGLE_ARTICLE:
    case Actions.TAG_ARTICLES: {
      const {
        tag: { id },
      } = payload
      return R.assoc('lastUsedTagId', id, state)
    }

    case Actions.RESET_LAST_USED_TAG_ID: {
      return R.assoc('lastUsedTagId', null, state)
    }

    case Actions.EDIT_TAG_SUCCESS: {
      const { tag } = payload

      return R.evolve({
        list: R.converge(R.update, [R.findIndex(R.propEq('id', tag.id)), R.always(tag), R.identity]),
      })(state)
    }

    case Actions.ADD_TAG_SUCCESS: {
      const { tag } = payload

      return R.evolve(
        {
          list: R.compose(indexArray, R.append(tag)),
        },
        state,
      )
    }

    case Actions.DELETE_TAG_SUCCESS: {
      const { tagId } = payload

      return R.evolve({
        list: R.compose(
          indexArray,
          R.converge(R.remove, [R.findIndex(R.propEq('id', tagId)), R.always(1), R.identity]),
        ),
      })(state)
    }

    case Actions.TAGS_REORDER: {
      const { newIndex, oldIndex, direction } = payload

      // TODO: logic behind sorting (and visuall hint about position) is not
      // very intuitive and hard to grasp, it would be good to came up with
      // better solution

      const swap = (oldI, newI, direction, arr) => {
        const el = arr[oldI]

        const getCorrection = (oldI, newI, dir) => {
          if (oldI < newI && !direction) {
            return 1
          }
          if (oldI > newI && direction) {
            return -1
          }
          return 0
        }

        const corr = getCorrection(oldI, newI, direction)

        return R.insert(newI - corr, el, R.remove(oldI, 1, arr))
      }

      return R.evolve({
        list: R.compose(indexArray, R.curry(swap)(oldIndex, newIndex, direction)),
      })(state)
    }

    default:
      return state
  }
}

export default tagsReducer
