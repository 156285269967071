import Rx from 'rxjs'
import dayjs from 'dayjs'

import buildAction from '../../helpers/buildAction'
import { logOutOnExpiredToken, serverIsDown, pushLocation } from '../epicsHelper'
import { searchDataToURLParam } from '../../opoint/search/url'
import * as ActionTypes from '../../constants/actionTypes'
import { getDefaultHome } from '../../selectors/settingsSelectors'
import type { Searchline } from '../../opoint/flow'
import { history } from '../../store'

type searchAction = {
  payload: {
    searchline: Searchline
    pathname: string
    timestamp: number
  }
}

const onSearch = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH).map(({ payload: { searchline, pathname, timestamp } }: searchAction) => {
    const unixTimestamp = timestamp || dayjs().format('x')
    return pushLocation(`${pathname}${searchDataToURLParam(searchline, parseInt(`${unixTimestamp}`))}`)
  })

const searchGoToStatisticsEpic = (action$: any) =>
  action$.ofType(ActionTypes.SEARCH_GO_TO_STATISTICS).map(() => pushLocation(`/statistics/${history.location.search}`))

const onSearchDataClear = (action$: any) =>
  action$.ofType(ActionTypes.SEARCHDATA_CLEAR).map(() => pushLocation(history.location.pathname))

const searchGoToEditProfileEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.SEARCH_GO_TO_EDIT_PROFILE)
    .map(() => pushLocation(`/search/edit/${history.location.search}`))

const searchGoToStatisticsComparisonEpic = (action$: any) =>
  action$.ofType(ActionTypes.GO_TO_STATISTICS_COMPARISON).map(() => {
    return pushLocation(`/statistics/compare/${history.location.search}`)
  })

const searchGoToNewsEpic = (action$: any) =>
  action$.ofType(ActionTypes.SEARCH_GO_TO_NEWS).map(() => pushLocation(`/search/${history.location.search}`))

const goToSearchProfileFromArticleView = (action$: any) =>
  action$
    .ofType(ActionTypes.GO_TO_SEARCH_PROFILE_FROM_ARTICLE_VIEW)
    .map(({ payload }) => pushLocation(!!payload ? `/search/?filters=profile:${payload}` : '/'))

const searchReplaceFiltersEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.SEARCH_REPLACE_FILTERS)
    .map(({ payload: { pathname, params } }) => pushLocation(`${pathname}${params}`))

const addAlertCancelEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.ALERT_ADD_CANCEL).map(() => {
    const state = getState()
    const {
      alerts: { list },
    } = state
    // If there are any alerts, redirect to the first one of them
    if (list && list[0]) {
      return pushLocation(`/alerts/${list[0].id}`)
    }

    const defaultHome = getDefaultHome(getState())
    // If not, redirect to the default profile
    if (defaultHome && defaultHome.type && defaultHome.id) {
      return pushLocation(`/search/?filters=${defaultHome.type}:${defaultHome.id}`)
    }

    // If there is no default profile, redirect to the empty route
    return pushLocation('/')
  })

const editAlertCancelEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.ALERT_EDIT_CANCEL).map(() => {
    const state = getState()
    const {
      form: {
        alert: {
          edit: {
            values: { id },
          },
        },
      },
    } = state
    return pushLocation(`/alerts/${id}`)
  })

export const goToNewFeedEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.GO_TO_NEW_FEED)
    .map(() => pushLocation('/feeds'))
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.FEED_NEW_ERROR)))

export default [
  addAlertCancelEpic,
  editAlertCancelEpic,
  goToNewFeedEpic,
  onSearch,
  searchGoToEditProfileEpic,
  searchGoToNewsEpic,
  searchGoToStatisticsEpic,
  searchReplaceFiltersEpic,
  goToSearchProfileFromArticleView,
  searchGoToStatisticsComparisonEpic,
  onSearchDataClear,
]
